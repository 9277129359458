<template>
	<div>
		<div v-for="(balances, name) in balancesKeyedByLineItems" class="mb-8">
			<h3 class="px-4 py-1 text-grey-dark text-sm mb-2 uppercase">{{ name }}</h3>
			<table class="text-grey-darkest lg:w-full">
				<thead>
					<tr class="hover:bg-grey-lighter">
						<th class="sticky-column"></th>
						<th v-for="period in periods" class="text-right px-4 py-1">{{ month(period) }}<span class="invisible-bracket"></span></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(lineItem, name) in balances">
						<td class="px-4 py-1 sticky-column">{{ name }}</td>
						<td v-for="(period, index) in periods" class="text-right px-4 py-1">
							<span v-if="lineItem[index] < 0" class="negative">
								<span class="bracket">(</span>{{ Math.abs(lineItem[index]) }}<span class="bracket">)</span>
							</span>
							<span v-else>
								<span class="bracket">(</span>{{ Math.abs(lineItem[index]) }}<span class="bracket">)</span>
							</span>
						</td>
					</tr>
					<!-- Totals -->
					<tr class="bg-grey-lighter">
						<td class="px-4 py-1 sticky-column">Total</td>
						<td class="text-right px-4 py-1" v-for="total in merge(balances)">
							<span v-if="total < 0" class="negative">
								<span class="bracket">(</span>{{ Math.abs(total) }}<span class="bracket">)</span>
							</span>
							<span v-else>
								<span class="bracket">(</span>{{ Math.abs(total) }}<span class="bracket">)</span>
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!--<div v-for="(balance, name) in balancesNotKeyedByLineItems" class="mt-8">-->
			<!--<h3 class="px-4 py-1 text-grey-dark text-sm mb-2 uppercase">{{ name }}</h3>-->
			<!--<table class="text-grey-darkest lg:w-full"-->
				   <!--&gt;-->
				<!--<thead>-->
					<!--<tr class="hover:bg-grey-lighter">-->
						<!--&lt;!&ndash;<th class="px-4 py-1 text-left text-grey-dark text-sm uppercase">{{ name }}</th>&ndash;&gt;-->
						<!--<th class="font-normal">-->
							<!--<span class="px-4 py-1">-->
								<!--<span class="invisible">-->
								<!--{{ biggestHeader }}-->
							<!--</span>-->
							<!--</span>-->
						<!--</th>-->
						<!--<th v-for="period in periods" class="text-right px-4 py-1">{{ period }}<span class="invisible-bracket"></span></th>-->
					<!--</tr>-->
				<!--</thead>-->
				<!--<tbody>-->
					<!--<tr class="bg-grey-lighter">-->
						<!--<td></td>-->
						<!--&lt;!&ndash;<td class="text-right px-4 py-1" v-for="(period, index) in projection.periods">{{ balance[index] }}</td>&ndash;&gt;-->
						<!--<td class="text-right px-4 py-1" v-for="(period, index) in periods">-->
							<!--<span v-if="balance[index] < 0" class="negative">-->
								<!--<span class="bracket">(</span>{{ Math.abs(balance[index]) }}<span class="bracket">)</span>-->
							<!--</span>-->
							<!--<span v-else>-->
								<!--<span class="bracket">(</span>{{ Math.abs(balance[index]) }}<span class="bracket">)</span>-->
							<!--</span>-->
						<!--</td>-->
					<!--</tr>-->
				<!--</tbody>-->
			<!--</table>-->
		<!--</div>-->
	</div>
</template>
<script>
  import _each from 'lodash/each';
  import _first from 'lodash/first';
  import _findIndex from 'lodash/findIndex';
  import report from './simpleBalances.mixin';
  import _maxBy from 'lodash/maxBy';
  import {month} from '@/app/filters';

  export default {
    mixins: [report],

    data() {
      return {
        biggestHeader: null,
	  };
    },

	props: {
      section: {
        type: Object
	  },
	  projection: {
        type: Object,
	  },
	  year: {
        type: String,
	  },
	  periods: {
        type: Object,
	  }
	},

	methods: {
      indexName(balances) {
        return _findIndex(this.keyedBalances, balances);
      },
    month,
	},

    computed: {
      keyedBalances() {
        return this.section;
	  },
      balancesKeyedByLineItemsKeys() {
        let results = {};

        _each(this.keyedBalances, (balances, key) => {
          let isSplitByLineItem = _first(balances, (itemOrTotal) => {
            return Number.isInteger(itemOrTotal);
          }) == null;

          if (isSplitByLineItem) {
            results[key] = true;
          }
        });

        return results;
      },
      balancesKeyedByLineItems() {
        let results = {};

        _each(this.keyedBalances, (balances, key) => {
          if (this.balancesKeyedByLineItemsKeys[key]) {
            results[key] = balances;
          }
        });

        return results;
      },
      balancesNotKeyedByLineItems() {
        let results = {};

        _each(this.keyedBalances, (balances, key) => {
          if (!this.balancesKeyedByLineItemsKeys[key]) {
            results[key] = balances;
          }
        });

        return results;
      },
	},

	mounted() {
      var widths = [];
      document.querySelectorAll('table td').forEach(td => {
        widths.push({
          width: td.offsetWidth,
          content: td.textContent
        })
      })

      this.biggestHeader = (_maxBy(widths, element => {
        return element.width
      })).content;
	}
  };

</script>
<style scoped>
	tr:not(.no-bg):hover {
		background-color: rgba(249, 249, 249, 1);
	}

	td.underline {
		text-underline-position: under;
	}
</style>