<template>
  <button
      v-if="temporalValuesAvailableForTweaking.length > 0 || tweaks.length > 0"
      @click="tweaking = !tweaking"
      @mouseover="focused = true" @mouseleave="focused = false"
      @focusin="focused = true" @focusout="focused = false"
  >
    <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        v-if="modelValue.length > 0"
    >
      <circle :class="['text-blue-200 transition-all duration-100 ease-in-out']" cx="10" cy="10" :r="focused ? 10 : 8"/>
      <circle :class="[
            focused ? 'text-blue-800' : 'text-blue-600 ',
           'transition-all duration-100 ease-in-out'
           ]"
              cx="10"
              cy="10"
              :r="focused ? 6 : 4"
      />
    </svg>
    <transition
        enter-active-class="transition-all duration-600 ease-in-out"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        leave-active-class="transition-all duration-200 ease-in-out"
    >
<!--      <svg-->
<!--          v-if="modelValue.length === 0 && focused"-->
<!--          viewBox="0 0 24 24"-->
<!--      >-->
<!--        <circle cx="12" cy="12" r="10" class="stroke-2 stroke-current text-blue-400" fill-opacity="0"/>-->
<!--        <path class="text-blue-600 fill-current"-->
<!--              d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"/>-->
<!--      </svg>-->
      <svg
          fill="currentColor"
          viewBox="0 0 20 20"
          v-if="modelValue.length === 0 && focused"
      >
        <circle :class="['text-gray-200 transition-all duration-100 ease-in-out']" cx="10" cy="10" :r="focused ? 10 : 8"/>
        <circle :class="[
            'text-gray-400',
           'transition-all duration-100 ease-in-out'
           ]"
                cx="10"
                cy="10"
                :r="focused ? 6 : 4"
        />
      </svg>
    </transition>
    <teleport to="body">
      <modal class="z-50" v-cloak v-model="tweaking" :middle="false">
        <div @click.stop
             @submit.stop
             class="p-8 bg-white w-full sm:w-3/4 md:w-2/3 lg:w-5/12 xl:max-w-7xl rounded-sm shadow-lg mt-24"
             v-if="tweaking">
          <PeriodTweaksConfigurationForm
                                         :date="date"
                                         :line-item="lineItem"
                                         :period="period"
                                         :periodIndex="periodIndex"
                                         @cancel="cancel"
                                         @saved="emitSaved"
          />
        </div>
      </modal>
    </teleport>
  </button>
</template>

<script>
import Modal from '@/components/Modal.vue';
import PeriodTweaksConfigurationForm from '@/components/lineitems/PeriodTweaksConfigurationForm.vue';
import {ref, toRefs} from 'vue';
import useTemporalValues from '@/app/temporal_values';

export default {
  props: {
    modelValue: {
      type: Array,
    },
    lineItem: {
      type: Object,
    },
    date: {
      type: [Date, String],
    },
    period: {
      type: Object,
    },
    periodIndex: {
      type: Number,
    }
  },
  setup(props, {emit}) {
    const focused = ref(false);
    const tweaking = ref(false);
    const {period, date, modelValue, lineItem, periodIndex} = toRefs(props);
    const {useTweaks} = useTemporalValues(lineItem.value);
    const {temporalValuesAvailableForTweaking, tweaks} = useTweaks(period.value);

    const emitSaved = (...args) => {
      tweaking.value = false;
      emit('saved', ...args)
    };

    const cancel = () => {
      tweaking.value = false;
    }

    return {
      focused,
      tweaking,
      period,
      date,
      emitSaved,
      cancel,
      tweaks,
      temporalValuesAvailableForTweaking,
    };
  },
  components: {
    Modal,
    PeriodTweaksConfigurationForm,
  },
};
</script>

<style>

</style>