import fetchExtension from '@/components/lineitems/utils/fetch-extension';
import yearTotaler from '@/app/lineitems/totals/year_totaler';
import _map from 'lodash/map';

export default function (item) {
  return yearTotaler(item, (periods, year) => _map(periods, (period, periodIndex) => {
    const index = periodIndex + (year * 12);
    if ((item.end_period !== null && index > item.end_period) || item.start_period === null) {
      return 0;
    }

    if (index < item.start_period) {
      return 0;
    }

    return Math.ceil(fetchExtension(item, 'salary').salary.computed_values[period.startDate] / 12);
  }));
}
