<template>
<!--  <div @click.stop="duplicate">-->
<!--    <slot/>-->
<!--  </div>-->
<!--  <div>-->
    <PopupForm v-model="popup" @submit.stop="duplicate">
      <slot/>
      <template v-slot:form>
        <div class="mb-4 w-80" v-bind="$attrs">
          <h2 class="text-indigo">
            Create a duplicate of {{ projection.name }}
          </h2>
        </div>
<!--        <p class="text-lg">Are you sure you want to duplicate <span class="font-semibold">{{ projection.name }}</span>?</p>-->
        <TextInput v-model="form.data.name" label="Name" :autofocus="true" placeholder="New Name" class="mt-6" :errors="form.errors('name')"/>
        <div class="flex justify-between mt-6">
<!--          <button @click="close" class="btn border border-gray text-gray-900 uppercase">Cancel</button>-->
          <SecondaryAction type="button" @click="close">Cancel</SecondaryAction>
<!--          <button type="submit" class="btn bg-green-600 text-white uppercase">Duplicate</button>-->
          <PrimaryAction :disabled="disabled" type="submit">Duplicate</PrimaryAction>
        </div>
      </template>
    </PopupForm>
<!--  </div>-->
</template>

<script setup>
import {defineEmits, ref} from 'vue';
import {useRouter} from 'vue-router';
import PopupForm from '@/components/forms/PopupForm.vue';
import TextInput from '@/components/forms/TextInput.vue';
import useForm from '@/app/form.js';
import PrimaryAction from '../../buttons/PrimaryAction.vue';
import SecondaryAction from '../../buttons/SecondaryAction.vue';

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  projection: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['notify', 'clear']);

const form = useForm({
  name: props.projection.name + ' (Copy)',
  copy_from_id: props.projection.id,
});

const router = useRouter();

const disabled = ref(false);

const duplicate = (event) => {
  form.post(route('api.projections.store', {projection: props.projection.id})).then(
    async (success) => {
      // GO TO THE NEW PROJECTION
      await router.push({name: 'projections.build', params: {id: success.data.id}});
      disabled.value = false;
      // TODO - these notification emissions don't do anything as it doesn't bubble up to the layout. Need new API.
      emit('clear')
      emit('notify', {
        body: 'Projection duplicated',
        type: 'success',
      })
    },
    error => {
      console.error(error);
      disabled.value = false;
      // alert('Something went wrong. Please refresh the page and try again.');
    },
  );

  event.preventDefault();
};

const popup = ref(false);

const close = () => {
  popup.value = false;
};
</script>

<style>
</style>
