<template>
    <input :value="currency + '' + round(modelValue)"
           class="bg-gray-100 border-0 p-0 border-b border-gray-400 focus:border-indigo text-center w-full placeholder-gray-200"
           disabled
           placeholder="0"
           style="min-width: 60px;"
    >
</template>

<script>
import _ceil from 'lodash/ceil';

export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: Number,
        required: true,
      },
      currency: {
        type: String,
        required: true,
      }
    },
    methods: {
      round(value) {
        return _ceil(value);
      },
    },
  };
</script>

<style>

</style>