import { mapGetters } from 'vuex';
import _first from 'lodash/first';
import extensions from '../extensions.mixin';
import {getVat} from '@/app/vat.js';

export default {
  setup(props) {
    const vat = getVat(props.item);

    return {
      vat,
    }
  },
  mixins: [extensions],
  // computed: {
  //   ...mapGetters({
  //     vatRates: 'vatRates',
  //   }),
  //   defaultVatRate() {
  //     return _first(Object.keys(this.vatRates));
  //   },
  //   vat() {
  //     return this.fetchExtension('vat', {
  //       rate: this.defaultVatRate,
  //     });
  //   },
  // },
};
