<template>
<!--    <div>-->
        <div class="relative flex flex-col" v-click-away="hide">
            <div class="relative flex-1">
                <textarea :placeholder="'Assumptions'"
                          @focus="active = true" class="py-1 px-2 rounded bg-white text-gray-dark resize-none shadow text-gray border-none" rows="1"></textarea>
                <!--                <svg v-if="body && body.length > 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-inbox-full absolute top-0 right-0 h-6 w-6 pr-2 text-gray fill-current"><path class="primary" d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 2v10h2a2 2 0 0 1 2 2c0 1.1.9 2 2 2h2a2 2 0 0 0 2-2c0-1.1.9-2 2-2h2V5H5z"/><path class="secondary" d="M8 7h8a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2zm0 4h8a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2z"/></svg>-->
                <svg v-if="body && body.length > 0" class="absolute top-0 right-0 h-8 w-8 pr-2 text-gray fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <rect class="secondary text-gray-400" height="13" rx="2" width="16" x="2" y="2"/>
                    <path class="primary text-gray-500"
                          d="M6 16V8c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2v13a1 1 0 0 1-1.7.7L16.58 18H8a2 2 0 0 1-2-2z"/>
                </svg>
            </div>
            <div :class="{
              'bg-white text-gray-dark right-0 rounded-lg': true,
              'shadow-lg absolute top-0 right-0 w-128 h-96 z-30 flex flex-col flex-grow': this.active,
            }" @click.stop v-show="active">
                <textarea :class="{
                  'py-4 px-4 resize-none flex-1 w-full border-none focus:ring-0 focus:border-gray-200 focus:border': true,
                  'shadow': !this.active,
                }" @focus="isTextAreaFocused = true" placeholder="Start typing..." ref="editor"
                          v-model="body"></textarea>
                <div class="px-4 pt-4 pb-2 flex w-full justify-between">
                    <button @click.stop="cancel" class="btn bg-gray-100 hover:bg-gray-700 hover:text-gray-200">Cancel</button>
                    <primary-action class="ml-2" v-show="active" @click.stop="commit">Save</primary-action>
                </div>
            </div>
        </div>
<!--    </div>-->
</template>

<script>
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
  import { directive as ClickAway } from 'vue3-click-away';

  export default {
    directives: {
      ClickAway,
    },
    data() {
      return {
        active: false,
        body: this.modelValue,
        isInsideFocused: false,
        isTextAreaFocused: false,
      };
    },
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: String,
        required: false,
      },
      onCommit: {
        type: Function,
        default: () => {
          return Promise.resolve();
        },
      },
    },
    computed: {
      isFocused() {
        return this.isTextAreaFocused || this.isInsideFocused;
      },
    },

    components: {
      PrimaryAction,
    },

    methods: {
      commit() {
        this.$emit('update:modelValue', this.body);
        this.onCommit().then(() => {
          this.active = false;
        }).catch(() => {
          alert('Something went wrong. Please try again.');
        });
      },
      cancel() {
        this.active = false;
        this.body = this.modelValue ? this.modelValue : '';
      },
      hide() {
        this.active = false;
      },
    },
    watch: {
      active() {
        if (this.active) {
          this.$nextTick(() => {
            this.$refs.editor.focus();
          });
        }
      },
      modelValue() {
        this.body = this.modelValue;
      },
      isFocused(newValue) {
        if (!newValue) {
          this.hide();
        }
      },
    },
  };
</script>

<style>

</style>