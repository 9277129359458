import Reports from '@/components/reports';
import Setup from '@/pages/projections/Setup.vue';
import SalesPlan from '@/pages/projections/SalesPlan.vue';
import Income from '@/pages/projections/Income.vue';
import Overheads from '@/pages/projections/Overheads.vue';
import Employees from '@/pages/projections/Employees.vue';
import Report from '@/pages/projections/ProjectionReports.vue';

export default [
  {
    label: 'Setup',
    active: true,
    complete: false,
    route: {
      path: 'setup',
      name: 'projection.steps.setup',
      component: Setup,
      meta: {
        title: 'Projection - Setup',
      },
    },
  },
  {
    label: 'Sales',
    active: false,
    complete: false,
    route: {
      path: 'sales',
      name: 'projection.steps.sales',
      component: SalesPlan,
      meta: {
        title: 'Projection - Sales',
      },
    },
  },
  {
    label: 'Other Income',
    active: false,
    complete: false,
    route: {
      path: 'income',
      name: 'projection.steps.income',
      component: Income,
      meta: {
        title: 'Projection - Income',
      },
    },
  },
  {
    label: 'Overheads',
    active: false,
    complete: false,
    route: {
      path: 'overheads',
      name: 'projection.steps.overheads',
      component: Overheads,
      meta: {
        title: 'Projection - Overheads',
      },
    },
  },
  {
    label: 'Employees',
    active: false,
    complete: false,
    route: {
      path: 'employees',
      name: 'projection.steps.employees',
      component: Employees,
      meta: {
        title: 'Projection - Employees',
      },
    },
  },
  {
    label: 'Projections',
    active: false,
    complete: false,
    inactiveIcon: 'lock',
    route: {
      path: 'report',
      name: 'projection.report.index',
      redirect: {name: 'projection.report.sales'},
      components: {
        default: Report,
      },
      children: [
        {
          path: 'sales',
          name: 'projection.report.sales',
          component: Reports.SalesPlan,
          meta: {
            title: 'Projection - Sales Plan',
          },
        },
        {
          path: 'cashflow',
          name: 'projection.report.cashflow',
          component: Reports.CashFlow,
          meta: {
            title: 'Projection - Cash Flow',
          },
        },
        {
          path: 'profitloss',
          name: 'projection.report.profitloss',
          component: Reports.ProfitLoss,
          meta: {
            title: 'Projection - Profit & Loss',
          },
        },
        {
          path: 'balancesheet',
          name: 'projection.report.balancesheet',
          component: Reports.BalanceSheet,
          meta: {
            title: 'Projection - Balance Sheet',
          },
        },
      ],
    },
  },
];
