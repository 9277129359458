<template>
    <div class="container mx-auto h-full flex justify-center items-center">
        <h1>404 Not found.</h1>
    </div>
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>

<style>

</style>