<template>
<!--  <div class="flex items-center">-->
<!--    <text-input class="w-full flex-grow-1" v-model="expression" @caret="caretPosition = $event">-->
<!--      <template v-slot:left>-->
<!--        <Badge class="bg-blue-100 text-blue-800">{{ modelValue.label }}</Badge> =-->
<!--      </template>-->
<!--    </text-input>-->
<!--  </div>-->
  <div class="flex items-center gap-x-2">
    <Badge class="bg-blue-100 text-blue-800">{{ modelValue.label }}</Badge> =
    <CqlEditor v-model="expression"/>
  </div>
</template>

<script>
import {ref, toRefs, watch} from 'vue';
import UiLabel from '@/components/ui/Label.vue';
import TextInput from '@/components/forms/TextInput.vue';
import Badge from '@/components/ui/Badge.vue';
import _cloneDeep from 'lodash/cloneDeep';

// import {CqlLexer} from '../../../../Cql/CqlLexer.ts';
// import {CqlParser} from '../../../../Cql/CqlParser.ts';
//
// import autosuggest from 'antlr4-autosuggest';
// import {InputStream, Lexer, Parser, error} from 'antlr4';
// import * as antlr4 from 'antlr4ts';
// const CharStreams = antlr4.CharStreams;
// const Lexer = antlr4.Lexer;
// const Parser = antlr4.Parser;
// const error = antlr4.error;
// const CommonTokenStream = antlr4.CommonTokenStream;
//
// import * as c3 from 'antlr4-c3';
import CqlEditor from '../CqlEditor.vue';

export default {
  props: {
    modelValue: {
      type: Object,
    }
  },
  setup(props, {emit}) {
    const {modelValue} = toRefs(props);
    const expression = ref(modelValue.value?.value.expression);
    const caretPosition = ref(0);

    // const autosuggester = autosuggest.autosuggester(lexerModule, parserModule);
    const autosuggest = () => {
      // const inputStream = CharStreams.fromString(expression.value);//console.log(inputStream);
      // const lexer = new CqlLexer(inputStream);
      // const tokenStream = new CommonTokenStream(lexer);
      // const parser = new CqlParser(tokenStream);
      // const tree = parser.expression();
      //
      // const preferredRules = new Set([CqlParser.RULE_variable]);
      //
      // const core = new c3.CodeCompletionCore(parser);
      // core.preferredRules = preferredRules;
      // const candidates = core.collectCandidates(tokenStream.index, tree);
      //
      // let keywords = [];
      //
      // for (let candidate of candidates.tokens) {
      //   keywords.push(parser.vocabulary.getDisplayName(candidate[0]));
      // }
      //
      // let rules = [];
      // console.log(candidates.rules);
      // for (let candidate of candidates.rules) {
      //   keywords.push(CqlParser.ruleNames[candidate[0]]);
      // }
      //
      // console.log(keywords);


      // INT, DOUBLE, VARIABLE_IDENTIFIER
      // => ignore if INT, DOUBLE
      // => if VARIABLE_IDENTIFIER, offer suggestions on available variables
      // => if it suggests VARIABLE_IDENTIFIER & previous token is a dot, offer suggestions on available properties of that variable
    }

    watch(expression, () => {
      const copy = _cloneDeep(modelValue.value);
      copy.value = {
        expression: expression.value
      }

      autosuggest();

      // console.log('suggestion', autosuggester.autosuggest(expression.value));
      emit('update:modelValue', copy);
    })

    return {
      expression,
      caretPosition,
    };
  },
  components: {
    CqlEditor,
    UiLabel,
    TextInput,
    Badge,
  },
};
</script>

<style>

</style>