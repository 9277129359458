<template>
    <div>
        <text-input class="mb-4" label="Name" name="name" v-model="modelValue.name" :autofocus="focus"></text-input>
        <text-input class="mb-4" label="Description" name="description" v-model="modelValue.description" ref="description"></text-input>
    </div>
</template>
<script>
import item from '../../item';
import extensions from '@/components/lineitems/extensions.mixin';
import UiLabel from '@/components/ui/Label.vue';
import InputLabel from '@/components/ui/Label.vue';
import MonthPicker from '@/components/forms/MonthYearPicker.vue';

export default {
    mixins: [item, extensions],

    computed: {
      date() {
        return new Date();
      }
    },

    created() {
      if(!this.modelValue.name) {
        this.modelValue.name = 'Equity Investment';
      }
    },

    components: {
      UiLabel,
      MonthPicker,
      InputLabel,
    }
  };

</script>
<style>

</style>