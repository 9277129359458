<template>
    <div class="flex w-full h-full items-center justify-center bg-gray-lightest">
        <div class="w-1/4 flex justify-center bg-white shadow-md rounded">
            <form @submit.prevent="attempt" class="w-full mb-4 p-8" method="POST" v-if="!submitted">
                <p class="text-2xl text-gray-darkest">Password Reset</p>
                <div class="mt-6">
                    <ui-label>Email</ui-label>
                    <ui-text-input
                            autocomplete="email"
                            name="email" placeholder="Email" type="text" v-model="email"/>
                </div>
                <div class="flex items-center justify-between mt-4">
                    <primary-action :disabled="loading">
                        Request password reset
                    </primary-action>
                </div>
            </form>
            <div class="p-8" v-else>
                <p class="text-2xl text-gray-darkest">Check your inbox</p>
                <p class="text-gray-dark mt-4">If you have entered an email address that exists in the system you will
                    receive a link to reset your password shortly.</p>
            </div>
        </div>
    </div>
</template>

<script>
  import UiLabel from '@/components/ui/Label.vue';
  import UiTextInput from '@/components/forms/TextInput.vue';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

  export default {
    data() {
      return {
        loading: false,
        submitted: false,
        email: "",
      };
    },
    methods: {
      attempt() {
        const callbackRoute = this.$router.resolve({name: 'auth.password_reset:complete'});
        this.loading = true;

        axios.post(route('api.passwordresets.store'), {
          email: this.email,
          callback: window.location.origin + callbackRoute.href,
        })
          .then(success => {
            this.submitted = true
          })
          .catch(error => {
            this.submitted = false
            alert('Something went wrong. Please try again.');
          })
          .finally(() => {
            this.loading = false
          })
        ;
      },
    },
    components: {
      UiLabel,
      UiTextInput,
      PrimaryAction,
    },
  };
</script>

<style>

</style>