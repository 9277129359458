import _reduce from 'lodash/reduce';
import periodEvents from '@/components/lineitems/utils/period-events';
import yearTotaler from '@/app/lineitems/totals/year_totaler';
import _map from 'lodash/map';

export default function (item) {
  return yearTotaler(item, (periods, year) => _map(periods, (period, periodIndex) => {
    const transactions = periodEvents(period, ['subscription']);
    return _reduce(transactions, (carry, transaction) => carry + (transaction.quantity), 0);
  }));
}
