import _first from 'lodash/first';

import DefaultHeader from '@/layouts/headers/Default.vue';
import ProjectionsIndex from '@/pages/projections/Index.vue';
import ProjectionsEdit from '@/pages/projections/Edit.vue';
import ProjectionBuilder from '@/components/projections/Builder.vue';
import SettingsLayout from '@/pages/settings/Layout.vue';
import SettingsIndex from '@/pages/settings/Index.vue';
import SettingsUsersIndex from '@/pages/settings/users/Index.vue';

import PaymentDetailsUpdate from '@/pages/settings/payment_details/Update.vue';

import ClientsIndex from '@/pages/clients/Index.vue';
import ClientsCreate from '@/pages/clients/Create.vue';
import steps from '@/pages/projections/steps';

import {defaultRedirect, ensure, loggedInUser, prefix} from '@/routes/utils';
import DefaultLayout from '@/layouts/Default.vue';

import {autoClearNotifications, ensurePaymentDetails} from '@/app/middleware';
import store from '@/store';
import analytics from '@/app/analytics.js';

const stepRoutes = steps.map(step => ({ ...step.route, path: step.route.path }));

const routes = [
  {
    path: '/o/:tenant',
    component: DefaultLayout,
    meta: {
      protected: true,
      middleware: [
        (to, from, next) => {
          if (to.params.tenant === from.params.tenant || to.params.tenant === null) {
            return next();
          }

          return store.dispatch('organisations/fetch', to.params.tenant)
            .then(() => {
              next();
            }).catch((e) => {
              next(false);
              throw e;
            });
        },
        autoClearNotifications,
      ],
    },
    name: 'tenant.base',
    children: [
      {
        path: '/o/:tenant',
        name: 'tenant.home',
        beforeEnter(to, from, next) {
          const newTenant = store.getters['organisations/get'](to.params.tenant);

          if (newTenant) {
            analytics.track('Switch Organisation', {
              organisation_id: newTenant.id,
              organisation_name: newTenant.name
            })
          }

          // always redirect to appropriate place
          loggedInUser() ? next(defaultRedirect(loggedInUser())) : { name: 'auth.login' };
        },
      },
      {
        path: 'payment-details/update',
        name: 'tenant.settings.billing.payment_details.update',
        component: PaymentDetailsUpdate,
      },
      // {
      //   path: 'payment-details/confirm',
      //   name: 'tenant.settings.billing.payment_details.confirm',
      //   component: PaymentDetailsUpdate,
      // },
      {
        path: '/:tenant/settings',
        components: {
          header: DefaultHeader,
          default: SettingsLayout,
        },
        children: [
          {
            path: '/:tenant/settings',
            name: 'tenant.settings.index',
            redirect: { name: 'tenant.settings.organisation.index' },
          },
          {
            path: 'organisation',
            name: 'tenant.settings.organisation.index',
            component: SettingsIndex,
            meta: {
              title: 'Settings - Organisation',
            },
          },
          {
            path: 'users',
            name: 'tenant.settings.users.index',
            component: SettingsUsersIndex,
            meta: {
              title: 'Settings - Users',
            },
          },
          // {
          //   path: 'billing',
          //   name: 'tenant.settings.billing.index',
          //   component: '',
          // },
        ],
      },
      {
        path: 'projections',
        name: 'projections.index',
        components: {
          header: DefaultHeader,
          default: ProjectionsIndex,
        },
        children: [],
        meta: {
          middleware: [ensurePaymentDetails],
          title: 'Projections',
        },
      },
      {
        path: 'projections/:id/edit',
        name: 'projections.edit',
        components: {
          header: DefaultHeader,
          default: ProjectionsIndex,
          slide: ProjectionsEdit,
        },
        meta: {
          title: 'Edit Projection',
        },
        props: {
          slide: () => {
            return {
              open: true,
              projection: store.getters['projections/current'],
            }
          }
        }
      },
      {
        path: 'projections/:id/build',
        name: 'projections.build',
        components: {
          header: DefaultHeader,
          default: ProjectionBuilder,
        },
        meta: {
          title: 'Projection Builder',
        },
        props: {
          default: route => {
            return {
              configuring: 'configuring' in route.query
            }
          }
        },
        redirect: { name: _first(stepRoutes).name },
        children: [
          ...stepRoutes,
        ],
      },
      ...prefix('clients', [
        {
          path: '/',
          name: 'clients.index',
          components: {
            header: DefaultHeader,
            default: ClientsIndex,
          },
          beforeEnter(to, from, next) {
            ensure(loggedInUser().can('list', 'clients'), next);

            next();
          },
          meta: {
            middleware: [ensurePaymentDetails],
          },
        },
        {
          path: 'create',
          name: 'clients.create',
          components: {
            header: DefaultHeader,
            default: ClientsCreate,
          },
          beforeEnter(to, from, next) {
            ensure(loggedInUser().can('list', 'clients'), next);

            next();
          },
        },
      ]),
    ],
  },
];

export default routes;
