<template>
	<div>
		<!--<text-input class="mb-4" label="Unique Reference" name="reference" v-model="item.reference"></text-input>-->
		<text-input class="mb-4" label="Name" name="name" v-model="modelValue.name" :autofocus="focus"></text-input>
		<text-input class="mb-4" label="Description" name="description" v-model="modelValue.description"></text-input>
		<div class="mb-4">
      <vat-rate-dropdown v-model="vat.rate"/>
		</div>
	</div>
</template>
<script>
  import item from '../../item'
  import UiLabel from '@/components/ui/Label.vue'
  import VatRateDropdown from '@/components/VatRateDropdown.vue';
  import {getVat} from '@/app/vat.js';
  import {toRefs} from 'vue';

  export default {
    mixins: [item],

    setup(props, {emit}) {
      const {modelValue} = toRefs(props);
      const vat = getVat(modelValue.value);

      return {
        vat,
      }
    },

    components: {
      UiLabel,
      VatRateDropdown,
    },
  };

</script>
<style>

</style>