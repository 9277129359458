import axios from 'axios';

export default function (router) {
  axios.interceptors.response.use(success => success, (error) => {
    if (error.response && error.response.status === 402) {
      console.error(error);
      const { data } = error.response.data;
      const { errors } = error.response.data.meta;
      // if subscription.expired ->
      if (errors['subscription.expired']) {
        window.alert(
          'Your payment required confirmation but has now expired. Please contact support at support@contingens.com to resolve this billing issue.',
        );
      }

      // if payment.incomplete ->
      if (errors['payment.incomplete']) {
        router.replace({
          name: 'confirm_payment',
          params: {
            id: data.id,
          },
          query: {
            client_secret: data.client_secret,
          },
        });
      }
    }

    return Promise.reject(error);
  });
}
