<template>
    <div class="px-2 sm:px-0 w-full" v-if="user">
        <div class="mt-3 mb-3 text-grey flex justify-between items-center container mx-auto">
<!--            <h2 class="uppercase">-->
<!--                <router-link :to="{path: '/'}" class="no-underline visited:text-original">-->
<!--                    <img alt="Contingens Logo" class="w-16" src="/images/logo_small.png">-->
<!--                    &lt;!&ndash;Contingens&ndash;&gt;-->
<!--                </router-link>-->
<!--            </h2>-->
            <!-- Dropdown -->
            <div @mouseleave="showMenu = false" class="relative">
                <div ref="parent">
                    <div @click="showMenu = !showMenu" class="cursor-pointer flex justify-between px-4 py-2 rounded"
                         v-if="user.organisation.name">
                        <button
                                class="text-grey-dark no-underline cursor-pointer text-sm parent-hover:text-indigo">
                            {{ organisation ? organisation.name : user.organisation.name }}
                        </button>
                        <div class="ml-4">
                            <icon icon="angle-down"></icon>
                        </div>
                    </div>
                </div>
                <div class="pt-2 z-50 absolute sm:w-64 text-sm" v-show="showMenu">
                    <div class="bg-grey-lightest rounded-t shadow">
                        <div class="px-4 pt-4 pb-1">
                            <a @click.prevent="clickLogout" class="text-grey hover:text-grey-darker no-underline cursor-pointer"
                               href="#">
                                <div class="">
                                    Log out
                                </div>
                            </a>
                        </div>
                        <div class="px-4 pt-4 pb-1 text-xs uppercase text-grey-light">
                            <p>{{ user.organisation.name }}</p>
                        </div>
                        <div class="p-4 pb-1 flex flex-col flex-wrap">
                            <router-link class="text-grey no-underline hover:text-blue-dark" :to="{name: 'tenant.settings.index', params: {tenant: user.organisation.id}}">Settings</router-link>
                        </div>
<!--                        <div class="px-4 py-4 text-xs uppercase text-grey-light flex justify-between">-->
<!--                            <p>Me</p>-->
<!--                            <a @click.prevent="clickLogout" class="text-grey hover:text-grey-darker no-underline cursor-pointer"-->
<!--                               href="#">-->
<!--                                <div class="text-right">-->
<!--                                    Log out-->
<!--                                </div>-->
<!--                            </a>-->
<!--                        </div>-->

                        <div class="px-4 pt-4 pb-1 text-xs uppercase text-grey-light">
                            <p>Accounts</p>
                        </div>
                        <ul class="list-reset">
                            <!--<li class="cursor-not-allowed text-grey-light px-4 py-4">-->
                            <!--{{ user.organisation.name }}-->
                            <!--</li>-->
                            <router-link :key="organisation.id"
                                         :to="{name: 'tenant.home', params: {tenant: organisation.id}}"
                                         class="no-underline text-grey"
                                         v-for="organisation in user.organisations.data"
                            >
                                <li class="hover:bg-blue-dark px-4 py-4 hover:text-blue-lightest cursor-pointer flex items-center justify-between">
                                    {{ organisation.name }}
<!--                                    <router-link-->
<!--                                            :to="{name: 'tenant.settings.index', params: {tenant: organisation.id}}">-->
<!--                                        <svg class="w-6 opacity-25 hover:opacity-75 text-blue-darker hover:text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--                                            <path class="fill-current" d="m6.8 3.45c.87-.52 1.82-.92 2.83-1.17a2.5 2.5 0 0 0 4.74 0c1.01.25 1.96.65 2.82 1.17a2.5 2.5 0 0 0 3.36 3.36c.52.86.92 1.8 1.17 2.82a2.5 2.5 0 0 0 0 4.74c-.25 1.01-.65 1.96-1.17 2.82a2.5 2.5 0 0 0 -3.36 3.36c-.86.52-1.8.92-2.82 1.17a2.5 2.5 0 0 0 -4.74 0c-1.01-.25-1.96-.65-2.82-1.17a2.5 2.5 0 0 0 -3.36-3.36 9.94 9.94 0 0 1 -1.17-2.82 2.5 2.5 0 0 0 0-4.74c.25-1.01.65-1.96 1.17-2.82a2.5 2.5 0 0 0 3.36-3.36zm5.2 12.55a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>-->
<!--                                            <circle class="fill-current" cx="12" cy="12" r="2"/>-->
<!--                                        </svg>-->
<!--                                    </router-link>-->
                                </li>
                            </router-link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import Dropdown from '@/components/Dropdown.vue';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data() {
      return {
        showMenu: false,
      };
    },

    props: {},

    computed: {
      ...mapGetters({
        user: 'auth/user',
        organisation: 'organisations/current',
      }),
    },

    methods: {
      ...mapActions({
        logout: 'auth/logout',
      }),
      clickLogout() {
        this.logout().then(() => {
          this.$router.push({name: 'auth.login'});
        });
      },
    },

    components: {
      Dropdown,
    },

    mounted() {

    },
  };

</script>
<style>

</style>