<template>
  <div class="ml-8 text-gray-400 mr-4">
    Status: {{visibleStatus}}
  </div>
</template>

<script>
import {ref, toRefs, watch} from 'vue';
import {useCache} from './cache/cache.js';

export default {
  props: {
    projection: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {projection} = toRefs(props);
    const visibleStatus = ref(projection.value.status);
    const {status, ready} = useCache(projection.value);

    // If the status changes, we only want to show it if the component is ready.
    watch(status, (status) => {
      if(ready.value) {
        visibleStatus.value = status;
      }
    });

    // If the component was not ready and becomes ready, we want to make sure we have the latest status
    watch(ready, (ready) => {
      if (ready) {
        visibleStatus.value = status.value;
      }
    })

    return {
      visibleStatus,
    }
  },
  components: {},
};
</script>

<style>

</style>