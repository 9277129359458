<template>
  <form @submit.prevent>
    <div class="pb-6">
      <h2>Tweaks in {{ formattedDate }}</h2>
      <p>You can adjust properties over time by setting them here. All changes are effective from the start of this month.</p>
    </div>
    <div class="pt-4 pb-6 text-uppercase" v-for="tweak in possibleTweaks">
      <div class="flex gap-x-2 gap-y-2">
        <button type="button" :title="`Add tweak for ${tweak.label}`"><Badge class="bg-blue-100 text-blue-800" @click="add(tweak)">{{ tweak.label }}<PlusIcon class="ml-2 w-4 h-4"/></Badge></button>
      </div>
    </div>
    <div v-if="tweaks.length > 0">
      <template v-for="(tweak, i) in tweaks">
        <div class="flex gap-x-2 items-center">
          <TweakInput class="w-full" v-model="tweaks[i]"/>
          <button type="button" title="Remove" class="text-gray-300 hover:text-red-500 focus:text-red-400 focus-visible:ring-2 focus-visible:ring-red-500">
            <CloseIcon @click="removeTweak(tweak)" class="h-full w-6"/>
          </button>
        </div>
      </template>
    </div>
    <div class="flex justify-between mt-8">
      <CancelButton @click="cancel"/>
      <PrimaryAction @click.stop="commit">Save</PrimaryAction>
    </div>
  </form>
</template>

<script>
import {toRefs, ref, computed} from 'vue';
import dayjs from 'dayjs';
import TweakInput from '@/components/lineitems/TweakInput.vue';
import Badge from '@/components/ui/Badge.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue';
import CloseIcon from '@/components/icons/CloseIcon.vue';
import * as types from '@/store/projections/line-items/mutation-types.js';
import CancelButton from '@/components/CancelButton.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import copy from 'fast-copy';
import store from '@/store';
import useTemporalValues from '@/app/temporal_values.js';

export default {
  props: {
    lineItem: {
      type: Object,
    },
    date: {
      type: [Date, String],
    },
    period: {
      type: Object,
    },
    periodIndex: {
      type: Number,
    }
  },
  setup(props, {emit}) {
    const {date, lineItem, period, periodIndex} = toRefs(props);
    const {useTweaks} = useTemporalValues(lineItem);
    const {tweaks: initialTweaks, temporalValuesAvailableForTweaking} = useTweaks(period);
    const tweaks = ref(copy(initialTweaks.value));

    const possibleTweaks = computed(() => {
      return temporalValuesAvailableForTweaking.value?.map((temporalValue) => {
        return {
          date: date.value,
          label: temporalValue.label,
          temporal_value_id: temporalValue.id,
          value: {
            expression: null,
          }
        };
      }).filter(value => tweaks.value?.filter(tweak => tweak.temporal_value_id === value.temporal_value_id).length === 0);
    });

    const formattedDate = dayjs(date.value).format('MMMM YYYY');

    const cancel = () => {
      tweaks.value = copy(initialTweaks.value);
      emit('cancel');
    };

    const removeTweak = (tweak) => {
      tweaks.value = tweaks.value.filter(t => t !== tweak);
    };

    const commit = () => {
      emit('commit', tweaks.value);

      const params =  {lineitem: lineItem.value.id, period: periodIndex.value};
      axios.put(route('api.lineitems.periods.tweaks.update', params), {
        tweaks: tweaks.value,
      }).then((response) => {
        emit('saved', response.data);
      });
    };

    const add = (tweak) => {
      tweaks.value.push(copy(tweak));
    };

    return {
      formattedDate,
      tweaks,
      cancel,
      removeTweak,
      commit,
      possibleTweaks,
      add,
    };
  },
  components: {
    PrimaryAction,
    CancelButton,
    CloseIcon,
    TweakInput,
    Badge,
    PlusIcon,
  },
};
</script>

<style>

</style>