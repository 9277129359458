import axios from 'axios';
import _mapKeys from 'lodash/mapKeys';
import statements from './statements';
import comments from './comments';
import lineItems from './line-items';
import * as types from './mutation-types';

export default {
  namespaced: true,

  modules: {
    lineItems,
    comments,
    statements,
  },

  state: {
    pagination: {},
    projections: [],
    year: null,
  },

  mutations: {
    [types.SET_CURRENT_YEAR](state, year) {
      state.year = year;
    },
    [types.SET_PROJECTIONS](state, projections) {
      state.projections = _mapKeys(projections, projection => projection.id)
    },
    [types.SET_PROJECTIONS_PAGINATION](state, pagination) {
      state.pagination = pagination;
    },
    [types.SET_PROJECTION](state, projection) {
      state.projections[projection.id] = projection;
    },
  },

  actions: {
    setCurrentStatus(context, status) {
      return context.dispatch('setStatus', { projection: context.getters.current, status });
    },
    setStatus(context, { projection, status }) {
      context.commit(types.SET_PROJECTION, { ...projection, status });
    },
    setYear(context, year) {
      context.commit(types.SET_CURRENT_YEAR, year);
    },
    setProjection(context, projection) {
      context.commit(types.SET_PROJECTION, projection);
    },
    setProjections(context, { data, meta }) {
      context.commit(types.SET_PROJECTIONS, data);
      context.commit(types.SET_PROJECTIONS_PAGINATION, meta.pagination);

      context.dispatch('statements/clear');
    },
    fetchAll(context, params) {
      return axios.get(route('api.projections.index', params)).then((response) => {
        this.dispatch('projections/setProjections', response.data);
      });
    },
    fetchOne(context, id) {
      return axios.get(route('api.projections.show', { projection: id })).then(({ data }) => {
        context.commit(types.SET_PROJECTION, data);

        return data;
      });
    },
    upsert(context, projection) {
      const isCreating = projection.id == null;
      const isUpdating = !isCreating;

      if (isUpdating) {
        return axios.patch(route('api.projections.update', { projection: projection.id }), projection).then(
          (success) => {
            context.commit(types.SET_PROJECTION, success.data);
          },
        );
      }

      return axios.post(route('api.projections.store'), projection).then(
        (success) => {
          context.commit(types.SET_PROJECTION, success.data);
        },
      );
    },
  },

  getters: {
    all(state) {
      return state.projections;
    },
    current(state, getters, rootState) {
      if (!rootState.route) {
        return null;
      }

      return state.projections[rootState.route.params.id];
    },
    pagination: state => state.pagination,
    years(state, getters) {
      if (!getters.current) {
        return {};
      }

      const years = {};
      getters.current.reporting_periods.data.forEach(year => years[year.label] = year.reporting_periods.data);
      return years;
    },
    year: state => state.year,
  },
};
