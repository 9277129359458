<template>
	<div class="container mx-auto flex flex-col lg:flex-row w-full flex-grow px-2">
		<div class="w-full lg:w-1/3 flex justify-end">
            <div class="lg:hidden mb-4">
                <commentary-input v-model="commentary" :on-commit="saveCommentary"></commentary-input>
            </div>
        </div>
		<div class="w-full lg:w-1/3 shadow p-4 bg-white">
			<h1 class="text-grey-darker mb-8 text-2xl">Opening Balances</h1>
			<div class="mb-4">
				<text-input label="Bank Balance" placeholder="" v-model.number.lazy="balances.bank" @change="updateBalances"
							css-override="text-right">
					<template v-slot:left>
						<span class="mx-3 text-grey">£</span>
					</template>
				</text-input>
			</div>
			<div class="mb-4">
				<text-input label="Debtors" placeholder="" v-model.number.lazy="balances.debtors" @change="updateBalances" css-override="text-right">
					<template v-slot:left>
						<span class="mx-3 text-grey">£</span>
					</template>
				</text-input>
			</div>
			<div class="mb-4">
				<text-input label="Creditors" placeholder="" v-model.number.lazy="balances.creditors" @change="updateBalances"
							css-override="text-right">
					<template v-slot:left>
						<span class="mx-3 text-grey">£</span>
					</template>
				</text-input>
			</div>
            <div class="mb-4">
                <text-input label="Share Capital" placeholder="" v-model.number.lazy="balances.share_capital" @change="updateBalances"
                            css-override="text-right">
                    <template v-slot:left>
                        <span class="mx-3 text-grey">£</span>
                    </template>
                </text-input>
            </div>
            <div class="mb-4">
                <text-input label="Profit / Loss Reserves" placeholder="" v-model.number.lazy="balances.profit_loss_reserve" @change="updateBalances"
                            css-override="text-right">
                    <template v-slot:left>
                        <span class="mx-3 text-grey">£</span>
                    </template>
                </text-input>
            </div>
			<step-links class="pt-4 pb-4" :current="step" :previous="linkToPreviousStep" :next="linkToNextStep"></step-links>
		</div>
		<div class="w-full lg:w-1/3 flex justify-end">
			<div class="hidden lg:block">
				<commentary-input v-model="commentary" :on-commit="saveCommentary"></commentary-input>
			</div>
		</div>
	</div>
</template>
<script>
import step from './step.mixin';
import MonthPicker from '@/components/forms/MonthYearPicker.vue';
import {mapActions, mapGetters} from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import CommentaryInput from '@/components/forms/CommentaryInput.vue';

export default {
    mixins: [step],

    data() {
      return {
        balances: {},
        date: new Date(),
	  	commentary: this.initialCommentary,
        label: 'opening_balances',
      };
    },

    props: {},

    computed: {
      ...mapGetters({
        comments: 'projections/comments/sections',
      }),
      initialBalances() {
        if(!this.projection) {
          return {}
        }

        return this.projection.opening_balances;
      },
      initialCommentary() {
        if (this.comments[this.label]) {
          return this.comments[this.label].body
        }

        return ""
      },
    },

    methods: {
      ...mapActions({
        'updateProjection': 'projections/upsert',
        upsertComment: 'projections/comments/upsert',
	  }),
	  updateBalances: function(event) {
        console.log('calling updateBalances', event);
        let projection = _cloneDeep(this.projection);
        projection.opening_balances = this.balances

        this.updateProjection(projection);
	  },
      saveCommentary() {
        return this.upsertComment({
          section: this.label,
          body: this.commentary,
          type: 'section',
        });
      },
    },

    components: {
      MonthPicker,
	    CommentaryInput,
    },

    watch: {
      initialBalances: {
        deep: true,
        handler() {
          for (var key in this.initialBalances) {
            this.balances[key] = this.initialBalances[key]
          }
        }
      },
      initialCommentary() {
        this.commentary = this.initialCommentary
      },
    },

    created() {
      for(var key in this.initialBalances) {
        this.balances[key] = this.initialBalances[key]
      }
    },

    mounted() {
      this.commentary = this.initialCommentary
    }
  };

</script>
<style>

</style>