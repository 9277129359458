<template>
  <InputCell
    :disabled="true"
    :modelValue="salary"
    :grayed-out="salary === 0"
    prefix="£"/>
</template>

<script setup>
import moment from 'moment';
import InputCell from './InputCell.vue';
import {useExtensions} from '@/components/lineitems/extensions.mixin';
import {computed, toRefs} from 'vue';

const props = defineProps({
  period: {
    type: Object,
    required: true,
  },
  lineItem: {
    type: Object,
    required: true,
  },
});

const {period, lineItem} = toRefs(props);
const {fetchExtension} = useExtensions(lineItem);
const startDate = computed(() => moment(lineItem.value?.start_date));
const endDate = computed(() => moment(lineItem.value?.end_date));
const salary = computed(() => {
  if(endDate.value && endDate.value.endOf('month').isBefore(moment(period.value.endDate).startOf('month'))) {
    return 0;
  }

  if(startDate.value.startOf('month').isSameOrBefore(moment(period.value.startDate).startOf('month'))) {
    return Math.ceil(fetchExtension('salary').salary.computed_values[period.value.startDate] / 12);
  }

  return 0
});
</script>

<style>

</style>