import {computed, ref, isRef} from 'vue';
import _groupBy from 'lodash/groupBy.js';

const useTemporalValues = function(item) {
  if (!isRef(item)) {
    item = ref(item);
  }

  const temporalValues = computed(() => {
    return item.value?.extensions?.data.map((extension) => {
      return extension.temporal_values?.data;
    }).filter(t => !!t).flat() ?? [];
  });

  const tweaksByPeriodStart = computed(() => {
    const allTweaks = temporalValues.value?.map((temporalValue) => {
      const allTweaks = temporalValue.tweaks?.data.filter(t => !!t).flat();
      return allTweaks.map(tweak => {
        return {
          ...tweak,
          label: temporalValue.label,
        };
      }).filter(tweak => !!tweak).flat()
    }).filter(value => !!value).flat();

    return _groupBy(allTweaks, (tweak) => tweak.date);
  });

  const useTweaks = (period) => {
    if(!isRef(period)) {
      period = ref(period);
    }

    const startDate = period.value?.startDate;

    const tweaks = computed(() => tweaksByPeriodStart.value?.[startDate] ?? []);

    const tweakedTemporalValueIds = computed(() => {
      return tweaks.value.map((tweak) => {
        return tweak.temporal_value_id;
      });
    });

    const temporalValuesAvailableForTweaking = computed(() => {
      return temporalValues.value?.filter((temporalValue) => {
        return !tweakedTemporalValueIds.value?.includes(temporalValue.id);
      }) ?? [];
    });

    return {
      tweaks,
      tweakedTemporalValueIds,
      temporalValuesAvailableForTweaking,
    }
  };

  return {
    temporalValues,
    tweaksByPeriodStart,
    useTweaks,
  }
};

export default useTemporalValues;