<template>
	<div>
		<popup-form v-model="popup">
<!--			<icon class="text-grey-dark hover:text-blue-700 cursor-pointer" icon="times"></icon>-->
            <div class="w-6 h-auto cursor-pointer group">
                <svg class="icon-close" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path class="fill-current group-hover:text-red-600" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                          fill-rule="evenodd"/>
                </svg>
            </div>
			<template v-slot:form>
				<p class="font-lg text-red-light">Are you sure you want to revoke access for {{ userName }}?</p>
				<div class="flex justify-between mt-4">
                    <button class="btn border border-gray text-gray-900 uppercase" @click="close">Cancel</button>
					<button class="btn bg-red-600 text-white uppercase" @click="destroy">Revoke access</button>
				</div>
			</template>
		</popup-form>
	</div>
</template>
<script>
  import PopupForm from '@/components/forms/PopupForm.vue'
  import CreateForm from '@/components/projections/forms/CreateForm.vue'

  export default {
    data() {
      return {
        // name: this.projection.name,
        // originalTitle: this.projection.name,
        popup: false,
      };
    },

    props: {
      circleId: {
        required: true,
      },
      userName: {
        required: true,
	  },
      userId: {
        required: true
      }
    },

    computed: {
      title() {
        if(this.originalTitle) {
          return "Duplicate '" + this.originalTitle + "'"
        }

        return null
      }
    },

    methods: {
      close() {
        this.popup = false
      },
      destroy() {
        axios.delete(route('api.circles.users.destroy', {circle: this.circleId, user: this.userId})).then(
          success => {
            this.popup = false
            window.location.reload();
          },
          error => {
            console.error(error)
            alert("Something went wrong. Please refresh the page and try again.")
          }
        )
      }
    },

    components: {
      PopupForm,
      CreateForm,
    },

    mounted() {

    },
  };
</script>
<style>

</style>