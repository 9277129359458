import Login from '@/pages/Login.vue';
import PasswordReset from '@/pages/PasswordReset.vue';
import CompletePasswordReset from '@/pages/CompletePasswordReset.vue';
import InviteRedemptionSplash from '@/pages/invites/InviteRedemptionSplash.vue';
import NotFound from '@/pages/404.vue';

import Forbidden from '@/pages/403.vue';
import appRoutes from '@/routes/app';
import {
  loggedInUser, defaultRedirect, ensure, prefix,
} from '@/routes/utils';
import Payment from '@/pages/Payment.vue';
import Signup from '@/pages/Signup.vue';

const routes = [
  {
    path: '/',
    redirect: to => (loggedInUser()
      ? defaultRedirect(loggedInUser())
      : { name: 'auth.login' }),
    name: 'home',
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/login',
    name: 'auth.login',
    component: Login,
    beforeEnter(to, from, next) { // todo - clean up session from laravel when logging out before re-enabling this
      loggedInUser() ? next(defaultRedirect(loggedInUser())) : next();
    },
  },
  {
    path: '/password-reset',
    name: 'auth.password_reset',
    component: PasswordReset,
  },
  {
    path: '/password-reset/complete',
    name: 'auth.password_reset:complete',
    component: CompletePasswordReset,
  },
  {
    path: '/invites/:id/redeem',
    component: InviteRedemptionSplash,
  },
  ...appRoutes,
  // {
  //   path: '/admin',
  //   redirect: {name: 'admin.organisations.index'},
  //   components: {
  //     default: AdminLayout,
  //     navbar: Default,
  //     sidebar: AdminSidebar,
  //   },
  //   children: [
  //     {
  //       path: 'organisations',
  //       name: 'admin.organisations.index',
  //       components: {
  //         navbar: Default,
  //         sidebar: AdminSidebar,
  //         admin: AdminOrganisationIndex,
  //       },
  //     },
  //     {
  //       path: 'invites',
  //       name: 'admin.invites.index',
  //       components: {
  //         admin: AdminInvitesIndex,
  //         sidebar: AdminSidebar,
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/resubscribe',
  //   name: 'resubscribe',
  //   component: Subscribe,
  // },
  {
    path: '/payments/:id/confirm',
    name: 'confirm_payment',
    component: Payment,
  },
  {
    path: '/403',
    name: 'forbidden',
    component: Forbidden,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

export default routes;
