import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/browser';

export default import.meta.env.PROD ?{
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    Sentry.init({ dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN });
  },
  watch: {
    user(user) {
      if (user) {
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.id,
            email: user.email,
          });

          scope.setExtra('environment', import.meta.env.MODE);
        });
      }
    },
  },
} : {}; // Do not load if not in production.
