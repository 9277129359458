import jws from 'jws';
import Gate from '@/app/auth/gate';
import axios from 'axios';
import * as types from './mutation-types';

export const TOKEN_KEY = 'jwt';

export default {
  namespaced: true,
  state: {
    user: null,
    token: null,
  },
  mutations: {
    [types.SET_TOKEN](state, token) {
      state.token = token;
    },
    [types.SET_USER](state, user) {
      state.user = user;
      state.user.$gate = new Gate(state.user);
      state.user.can = function (action, type, model = null) {
        return this.$gate.allow(action, type, model);
      }.bind(state.user);
    },
    [types.CLEAR_TOKEN](state) {
      state.token = null;
    },
    [types.CLEAR_USER](state) {
      state.user = null;
    },
  },
  actions: {
    refreshUser({ dispatch }, tenant = null) {
      const payload = {};

      if (tenant) {
        payload.tenant = tenant;
      }

      return axios.get(route('api.user', payload)).then((success) => {
        dispatch('setToken', success.data.data);
      });
    },
    load(context) {
      // check if there is a local storage token
      // if so, set token
      const token = localStorage.getItem(TOKEN_KEY) || window.userToken;
      if (token) {
        context.dispatch('setToken', token);
      }
    },
    setToken(context, token) {
      const user = jws.decode(token).payload;
      context.commit(types.SET_TOKEN, token);
      context.commit(types.SET_USER, user);

      localStorage.setItem(TOKEN_KEY, token);
    },
    clearToken(context) {
      return new Promise((resolve, reject) => {
        context.commit(types.CLEAR_TOKEN);
        context.commit(types.CLEAR_USER);

        localStorage.removeItem(TOKEN_KEY);
        resolve();
      });
    },
    logout({ dispatch }) {
      return Promise.all([
        axios.delete(route('auth.logout')),
        dispatch('clearToken'),
      ]);
    },
  },
  getters: {
    token: state => state.token,
    user: (state) => {
      if (!state.user || (new Date(state.user.exp) > new Date())) {
        return null;
      }

      return state.user;
    },
  },
};
