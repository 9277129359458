<template>
    <div class="sm:w-20 h-16 sm:h-full flex-shrink-0 print:hidden">
        <div class="bg-gray-900 flex-shrink-0 flex sm:flex-col sm:px-2 fixed w-full h-16 sm:h-full sm:w-20">
            <div class="flex items-center justify-center sm:mt-4 sm:h-32">
                <account-switcher>
                    <template v-slot="{active}">
                        <div class="flex justify-center items-center" >
                            <div :style="dropdownStyle" :class="{'w-10 h-10 rounded-full bg-white bg-cover bg-center border-2 hover:shadow-xl hover:border-2 hover:border-white shadow-inner' : true, 'border-gray-900': !active, 'border-white': active}">
                            </div>
                        </div>
                    </template>
                </account-switcher>
            </div>
            <ul class="flex flex-col justify-center sm:justify-start items-center flex-1 hidden sm:flex gap-y-2">
                <nav-item :route="{'name': 'clients.index'}" v-if="can('list', 'clients')">
                    <button title="Clients">
                        <svg class="w-8 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect class="primary" height="12" rx="2" width="20" x="2" y="10"/>
                            <path class="secondary"
                                  d="M20 8H4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2zm-2-4H6c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2z"/>
                        </svg>
                    </button>
                </nav-item>
                <!-- Copy of above clients list to navigate back when viewing a client -->
                <nav-item v-if="user.advisor && !can('list', 'clients')" :route="{'name': 'clients.index', params: {tenant: user.advisor.id}}">
                  <button title="Clients">
                    <svg class="w-8 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <rect class="primary" height="12" rx="2" width="20" x="2" y="10"/>
                      <path class="secondary"
                            d="M20 8H4c0-1.1.9-2 2-2h12a2 2 0 0 1 2 2zm-2-4H6c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2z"/>
                    </svg>
                  </button>
                </nav-item>
                <nav-item :route="{'name': 'projections.index'}" v-if="can('list', 'projections')">
                    <button title="Projections">
                        <svg class="w-8 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path class="primary"
                                  d="M3.7 20.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 0l3.3 3.29 4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0L9 15.4l-5.3 5.3z"/>
                            <path class="secondary"
                                  d="M16.59 8l-2.3-2.3A1 1 0 0 1 15 4h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1.7.7L18 9.42l-4.3 4.3a1 1 0 0 1-1.4 0L9 10.4l-5.3 5.3a1 1 0 1 1-1.4-1.42l6-6a1 1 0 0 1 1.4 0l3.3 3.3L16.59 8z"/>
                        </svg>
                    </button>
                </nav-item>
            </ul>
            <ul class="flex flex-col justify-center sm:justify-start items-center sm:py-8 hidden sm:flex">
                <nav-item :route="{'name': 'tenant.settings.index'}">
                    <CogIcon/>
                </nav-item>
            </ul>
        </div>
    </div>
</template>

<script>
import NavItem from './NavItem.vue';
import {loggedInUser} from '@/routes/utils';
import AccountSwitcher from './AccountSwitcher.vue';
import {mapGetters} from 'vuex';
import CogIcon from '../icons/CogIcon.vue';

export default {
    data() {
      return {
        expanded: false,
      };
    },
    components: {
      CogIcon,
      NavItem,
      AccountSwitcher,
    },
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      dropdownStyle() {
        return {
          backgroundImage: 'url(\'/images/logo_small.png\')',
        };
      },
    },
    methods: {
      can(action, resource) {
        return loggedInUser() ? loggedInUser().can(action, resource) : false;
      }
    },
  };
</script>

<style>

</style>