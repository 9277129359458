<template>
    <tr class="input-center">
        <td class="sticky left-0 bg-white z-10"></td>
        <template v-for="(year, key) in overall">
            <td class="overflow-visible" v-for="period in year.periods">
                <total :currency="currency" :modelValue="period"></total>
            </td>
<!--            <td class="overflow-visible">-->
<!--                <total :currency="currency" :value="year.total"></total>-->
<!--            </td>-->
        </template>
    </tr>
</template>
<script>
  import Total from '@/components/lineitems/Total.vue';
  import _each from 'lodash/each';
  import _ceil from 'lodash/ceil';
  import RowContainerGrid from '@/components/RowContainerGrid.vue';

  export default {
    data() {
      return {};
    },

    props: {
      currency: {
        default: '',
      },
      totals: {
        type: Array,
        required: true,
      },
    },

    methods: {
      round(value) {
        return _ceil(value);
      },
    },

    computed: {
      overall() {
        let totals = [];

        _each(this.totals, (itemYearsTotals) => {
          _each(itemYearsTotals, (yearTotals, year) => {
            if (!totals[year]) {
              totals[year] = {
                periods: [],
                total: 0,
              };
            }

            let periods = totals[year].periods;
            totals[year].total += yearTotals.total;
            _each(yearTotals.periods, (totalForPeriod, index) => {
              if (!periods[index]) {
                periods[index] = 0;
              }

              periods[index] += totalForPeriod;
            });
          });
        });

        return totals;
      },
    },

    components: {
      RowContainerGrid,
      Total,
    },

    mounted() {
    },
  };

</script>
<style>

</style>