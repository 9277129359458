<template>
    <div>
        <!-- Any time line items in sales section changes, recalculate sales plan - but debounce to once every second -->
        <!-- Only if the step is active -->
        <!-- When subscription products are showing, show subscription-specific charts -->
        <!-- New subscribers -->
        <!-- Renewals -->
        <!-- Churn -->
        <!-- When any products are showing, show new sales -->
        <div ref="sticky" class="w-full year-selector py-3 z-20" v-show="salesPlan">
            <year-selector v-model="year" :include-summary="false"></year-selector>
        </div>
        <template v-if="year && salesPlan">
            <sales-plan-insights :sales-plan="salesPlan" :year="year"></sales-plan-insights>
        </template>
    </div>
</template>

<script>
  import SalesPlanInsights from './SalesPlanInsights.vue';
  import YearSelector from '@/components/ui/YearSelector.vue';
  import stickybits from 'stickybits';

  export default {
    props: {
      salesPlan: {
        type: Object,
      },
      reportingPeriods: {
        type: Array,
      }
    },
    data() {
      return {
        year: null,
        stickybits: null,
      };
    },

    mounted() {
      this.$nextTick(() => {
        this.stickybits = stickybits(this.$refs.sticky, {
          useStickyClasses: true,
          verticalPosition: 'top',
        });
        this.stickybits.update();
      })
    },

    unmounted() {
      this.stickybits.update();
      this.stickybits.cleanup();
      this.stickybits = null;
    },

    components : {
      SalesPlanInsights,
      YearSelector,
    },

  };
</script>

<style>
    .year-selector {
        transition: .25s;
        transition-timing-function: ease-in-out;
    }

    .year-selector.js-is-sticky {
        @apply bg-gray-100 shadow;
    }

    .year-selector.js-is-stuck {
        background: unset;
    }
</style>