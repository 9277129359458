<template>
    <tr class="line-item z-10" v-click-away="hide">
        <td class="sticky-column">
            <div class="bg-white px-1 py-2 flex justify-between overflow-x-hidden">
                <div class="flex items-center relative" @mouseleave="stopHovering" ref="nameColumn" :style="nameColumnStyle">
                    <transition name="slide" v-on:before-enter="nameColumnWidth = $refs.nameColumn.getBoundingClientRect().width" v-on:after-leave="nameColumnWidth = null">
                        <div v-show="hovering" class="cursor-pointer z-20 line-item-handle bg-white flex-grow-0" :style="handleVars" ref="handle" >
                            <svg class="w-3 h-4 fill-current text-gray-300 mr-2" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <circle cx="2" cy="2" r="2"></circle>
                                    <circle cx="2" cy="8" r="2"></circle>
                                    <circle cx="2" cy="14" r="2"></circle>
                                    <circle cx="8" cy="14" r="2"></circle>
                                    <circle cx="8" cy="8" r="2"></circle>
                                    <circle cx="8" cy="2" r="2"></circle>
                                </g>
                            </svg>
                        </div>
                    </transition>
                    <div class="absolute w-4 h-full -ml-2 z-10" @mouseover="startHovering">
                    </div>
                    <h5 class="flex-shrink-0 text-grey whitespace-nowrap text-sm md:text-base">{{ item.name }}</h5>
                </div>
                <div class="flex items-end justify-end w-auto" v-if="item.type">
                    <div class="flex content-center group" ref="parent">
                      <ThreeDotButton @click="showMenu = !showMenu"/>
                    </div>
                </div>
            </div>
        </td>
        <teleport to="body">
<!--            :to="`#item-config-${item.id}`">-->
            <dropdown :open="showMenu" :parent="$refs.parent" pin="right-start">
              <div @click.stop="showMenu = false" class="z-50 mx-2">
                <div class="bg-white z-50 border rounded shadow cursor-pointer">
                  <div @click="configure"
                       class="py-4 px-8 hover:bg-gray-100 hover:text-gray-800">
                    Configure
                  </div>
                  <div @click="duplicate"
                       class="py-4 px-8 hover:bg-gray-100 hover:text-gray-800">
                    Duplicate
                  </div>
                  <div @click="remove" class="py-4 px-8 hover:bg-gray-100 hover:text-gray-800">
                    Delete
                  </div>
                </div>
              </div>
            </dropdown>
        </teleport>
        <!--        <template v-for="(year, yearIndex) in years">-->
        <!-- PrimaryEventInput etc-->
        <td class="overflow-visible" v-for="(period, key) in item.periods.data">
            <div class="relative">
              <PeriodTweakBadge class="absolute mt-2.5 -ml-2 w-4 h-4 z-10"
                                :date="period.startDate"
                                :modelValue="tweaksByPeriod[key].tweaks"
                                :line-item="item"
                                :period="period"
                                :periodIndex="key"
                                @saved="refreshLineItem"
              />
              <component
                  :disabled="disabled || replicating"
                  :is="inputType"
                  :line-item="item"
                  :on-event="setPeriodPrimaryEvent"
                  :on-replicate="replicate"
                  :period="period"
                  @blur="removeHoveringPeriodIfSameAs(period)"
                  @focus="hoveringPeriod = period.startDate"
                  :key="item.id + ':' + key"
              ></component>
            </div>
        </td>
        <teleport to="body">
          <modal class="z-50" v-cloak v-model="configuring">
            <div @click.stop
                 @submit.stop
                 class="mx-auto my-auto p-8 bg-white w-full sm:w-3/4 md:w-2/3 lg:w-128 rounded-sm shadow-lg"
                 v-if="configuring">
              <line-item-configuration-form
                  :active="configuring"
                  :initial-line-item="item"
                  :on-cancel="stopConfiguring"
                  :on-save-success="stopConfiguring"
              ></line-item-configuration-form>
            </div>
          </modal>
        </teleport>
    </tr>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue';
import configFormMixin from '@/components/lineitems/forms/usesLineItemConfigForm.mixin';
import row from './rows/row.mixin';
import TransactionPeriod from './TransactionPeriod.vue';
import EmployeeMonthlySalary from './EmployeeMonthlySalary.vue';
import EquityInvestment from './EquityInvestment.vue';
import Subscription from './Subscription.vue';
import Total from '@/components/lineitems/Total.vue';

import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _groupBy from 'lodash/groupBy';

import extensions from '@/components/lineitems/extensions.mixin';

import totalCalculators from '@/app/lineitems/totals';
import {directive as ClickAway} from 'vue3-click-away';
import PeriodTweakBadge from '@/components/lineitems/PeriodTweakBadge.vue';
import copy from 'fast-copy';
import ThreeDotButton from '../ui/ThreeDotButton.vue';

export default {
    mixins: [configFormMixin, row, extensions],

    directives: {
      ClickAway,
    },

    data() {
      return {
        hoveringPeriod: null,
        showMenu: false,
        hovering: false,
        hoveringTimer: null,
        nameColumnWidth: null,
        handleWidth: null,
        replicating: false,
      };
    },

    props: {
      initialLineItem: {
        type: Object,
        default() {
          return {};
        },
      },
      headers: {
        type: Boolean,
        default: false,
      },
      duplicate: {
        type: Function,
        default() {
          return () => {
          };
        },
      },
      remove: {
        type: Function,
        default() {
          return () => {
          };
        },
      },
      disabled: {
        type: Boolean,
        default: null,
      },
      currency: {
        type: String,
        required: true,
      },
      displayCurrency: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        default: null,
      }
    },

    mounted() {

    },

    watch: {
      hovering: {
        handler(newValue) {
          if(!newValue) {
            this.$nextTick(() => {
              // this.nameColumnWidth = null;
            })
          }
        }
      },
    },

    computed: {
      handleVars() {
        return {
          '--handle-width': `${this.handleWidth}px`,
        }
      },
      nameColumnStyle() {
        if(this.nameColumnWidth) {
          return {
            width: `${this.nameColumnWidth}px`
          }
        }
      },
      totals() {
        return (totalCalculators[this.item.tag] || totalCalculators.default)(this.item);
      },
      periods() {
        var periods = {};

        if (!this.item.periods) {
          return periods;
        }

        return this.item.periods.data;
      },
      years() {
        var years = {};

        if (!this.item.years) {
          return years;
        }

        return this.item.years.data;
      },
      inputType() {
        let itemTypeMap = {
          'employee': 'employee-monthly-salary',
          'subscription': 'subscription',
          'equity': 'equity-investment',
        };

        return itemTypeMap[this.item.tag] || itemTypeMap[this.item.type] || 'transaction-period';
      },
      tweaks() {
        // for each 'temporal value' in each extension
        // show the tweaks for each property
        return _groupBy(this.extensions.map((extension) => {
          let tweaks = [];

          for(let property in extension) {
            if(extension[property] && typeof extension[property] === 'object' && extension[property].hasOwnProperty('tweaks')) {
              let temporalValue = extension[property];
              tweaks = temporalValue.tweaks.data.map(tweak => {
                return {
                  ...tweak,
                  label: temporalValue.label,
                }
              });
            }
          }

          return tweaks.length ? tweaks : null;
        }).filter(tweaks => !!tweaks).flat(),
            (tweak) => {
              return tweak.date;
            }
        );


      },

      tweaksByPeriod() {
        return this.periods.map(period => {
          return {
            startDate: period.startDate,
            endDate: period.endDate,
            tweaks: this.tweaks[period.startDate] ?? [],
          }
        })
      },
    },

    components: {
      ThreeDotButton,
      Dropdown,
      TransactionPeriod,
      EmployeeMonthlySalary,
      Subscription,
      EquityInvestment,
      Total,
      PeriodTweakBadge,
    },

    methods: {
      setPeriodPrimaryEvent(period, event) {
        const periodCopy = copy(period);
        const eventCopy = copy(event);
        let events = [
          ..._filter(period.events, event => {
            return event.type !== eventCopy.type;
          })];

        eventCopy.date = period.endDate;
        events.push(eventCopy);
        periodCopy.events = events;

        if (this.replicating) {
          return this.upsertingPromise;
        }

        return this.upsertPeriod(this.item, periodCopy);
      },
      async replicate(period, event) {
        this.replicating = true;
        await this.upsertingPromise;

        if (!event) {
          return;
        }

        let itemKey = _findIndex(this.periods, period);
        const itemCopy = copy(this.item);

        for (let i = itemKey; i < this.periods.length; i++) {
          const periodCopy = copy(this.periods[i]);
          const eventCopy = copy(event);
          delete eventCopy.id; // remove id so that it will be created as a new event

          const events = [
            ..._filter(periodCopy.events, event => {
              return event.type !== eventCopy.type;
            })];

          eventCopy.date = periodCopy.endDate;
          events.push(eventCopy);
          periodCopy.events = events;
          itemCopy.periods.data[i] = periodCopy;
        }

        return this.upsertLineItem(itemCopy).then(() => this.$track('Line Item: Replicate Period', this.item)).finally(() => {
          this.replicating = false;
        })
      },
      removeHoveringPeriodIfSameAs(period) {
        this.$nextTick(() => {
          if (this.hoveringPeriod === period.startDate) {
            this.hoveringPeriod = null;
          }
        });
      },
      hide() {
        this.showMenu = false;
      },
      startHovering() {
        this.hoveringTimer = setTimeout(() => {
            this.hovering = true;
        }, 100);
      },
      stopHovering() {
        if(this.hoveringTimer) {
          clearTimeout(this.hoveringTimer);
          this.hoveringTimer = null;
        }

        setTimeout(() => {
          if(!this.hoveringTimer) {
            this.hovering = false;
          }
        }, 50)
      },
    },
  };

</script>
<style scoped>
    .line-item.draggable-source--is-dragging {
        @apply opacity-25;
        @apply border-l-2 border-gray-400;
    }

    .line-item.draggable-mirror {
        @apply bg-white shadow-xl z-40 py-1 px-1 rounded max-w-6xl md:max-w-7xl overflow-x-hidden;
    }

    .line-item.draggable-mirror > .sticky-column {
        /*@apply static !important;*/
    }
</style>

<style>

    .slide-enter, .slide-leave-to {
        width: 0;
        opacity: 0;
    }

    .slide-enter-to, .slide-leave {
        width: 100%;
        opacity: 1;
    }

    .slide-enter-active {
        transition: all 0.5s ease;
    }

    .slide-leave-active {
        transition: all 0.35s ease;
    }
</style>