<template>
  <slot/>
</template>

<script>
  export default {
    inject: ['sortableItemClass'],
    data() {
      return {};
    },

    mounted() {
      this.$el.classList.add(this.sortableItemClass);
    },
  };
</script>