import {ref} from 'vue';

export default function() {
  // create a new ref to hold the errors
  // provide a handler for http requests

  const errors = ref({})

  const responseHandler = (response) => {
    if(response.data && response.data.errors) {
      errors.value = response.data.errors
    }
  }

  const errorHandler = (error) => {
    return responseHandler(error.response);
  }

  return {
    errors,
    responseHandler,
    errorHandler,
  }
}