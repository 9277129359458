import {createApp} from 'vue';
import '../css/main.css';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import interceptBilling from '@/app/interceptors/billing';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
// Layouts
import headers from '@/layouts/headers';

// Components
import Dropdown from '@/components/Dropdown.vue';
import TextInput from '@/components/forms/TextInput.vue';
import PopupForm from '@/components/forms/PopupForm.vue';

import ProjectionBuilder from '@/components/projections/Builder.vue';
import ProjectionCreateForm
  from '@/components/projections/forms/CreateForm.vue';

import PermissionCircleForm from '@/components/forms/PermissionCircleForm.vue';
import DuplicateProjectionButton
  from '@/components/projections/buttons/DuplicateButton.vue';
import DeleteProjectionButton
  from '@/components/projections/buttons/DeleteButton.vue';

import UserInviteForm from '@/pages/settings/UserInviteForm.vue';
import UserRevokeButton from '@/pages/settings/UserRevokeButton.vue';

import Error from '@/components/forms/Error.vue';
import SignupForm from '@/components/billing/SignupForm.vue';
import SetupAccount from '@/components/account/Setup.vue';

import DefaultProjectionExport
  from '@/components/exports/DefaultProjectionExport.vue';

import store from '@/store';
import auth from '@/app/auth';
import seed from '@/app/seed';
import helpers from '@/app/helpers';

import sentry from '@/app/sentry.mixin';
import moment from 'moment';

import loading from '@/app/loading';
import router from './router';

import {
  default as analytics,
  identifyUser,
  trackPageViews,
} from '@/app/analytics';
import './bootstrap.js';

identifyUser(store);
trackPageViews(router);

auth();
seed();
helpers();
interceptBilling(router);
moment.defaultFormatUtc = moment.ISO_8601;

loading();

// Vue.directive('click-away', {
//   bind: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // here I check that click was outside the el and his children
//       if (!(el == event.target || el.contains(event.target))) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('click', el.clickOutsideEvent)
//   },
// });

const app = createApp({
  components: {
    Dropdown,
    ProjectionBuilder,
    ProjectionCreateForm,
    PermissionCircleForm,
    DuplicateProjectionButton,
    DeleteProjectionButton,

    UserInviteForm,
    UserRevokeButton,

    Error,
    SignupForm,
    SetupAccount,
    ...headers,

    'projection-export': DefaultProjectionExport,
  },
  mixins: [sentry],
});

app.use(router);
app.use(store);

app.component('SignupForm', SignupForm)
app.component('TextInput', TextInput);
app.component('PopupForm', PopupForm);
app.component('Error', Error);

app.config.globalProperties.$track = analytics.track;
// app.config.compilerOptions.whitespace = 'condense'

app.mount('#app');

// if(window && window.__Cypress__) {
  Object.assign(app, {
    store,
    router
  })
  window.app = app;
  // console.log('Registered app for Cypress Testing');
// } else {
//   console.log('Not in Cypress environment');
// }
