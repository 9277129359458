<template>
  <button
      class="text-semibold text-gray-600 hover:underline text-sm hover:text-gray-900 focus:ring-1 px-2 py-1 rounded-sm ring-blue-500"
      @click="$emit('click')"
      type="button">Cancel
  </button>
</template>

<script>
export default {
  props: {},
  setup(props) {
    return {};
  },
  components: {},
};
</script>

<style>

</style>