<template>
    <PrimaryEventInput
            :event-type="eventType"
            :on-replicate="replicateRight"
            :period="period"
            v-model="value"
            v-bind="$attrs"
            :summer="aggregateTransactionAmounts"
    ></PrimaryEventInput>
</template>
<script setup>
import PrimaryEventInput from './PrimaryEventInput.vue';
import {props as inputProps, useEventInput} from './eventinput.mixin';
import {computed, ref, toRefs, watch} from 'vue';

const props = defineProps({
  ...inputProps,
});

const {period, onEvent, lineItem, onReplicate} = toRefs(props);

const isDiscrete = computed(() => {
  return lineItem.value.discrete === true;
});

const eventType = computed(() => {
  if (isDiscrete.value) {
    return 'discretetransaction';
  }

  return 'transaction';
});

const keyToTotalUp = computed(() => {
  if (isDiscrete.value) {
    return 'quantity';
  }

  return 'value';
});

const eventsOfSameType = computed(() => {
  return period.value.events.filter(event => {
    return event.type === eventType.value;
  });
});

const generateEvent = (value) => {
  // get the value
  // check if it should be discrete or not
  let transaction = {};

  if (isDiscrete.value) {
    transaction.quantity = value;
    transaction['type'] = 'discretetransaction';
  } else {
    transaction.value = value;
    transaction['type'] = 'transaction';
  }

  if (eventsOfSameType.value?.length > 0) {
    transaction['id'] = eventsOfSameType.value[0].id;
  }

  console.log('transaction event calculated', transaction);
  return transaction;
};

const eventTemplate = computed(() => generateEvent(value.value));

const {aggregateTransactionAmounts, replicateRight} = useEventInput(keyToTotalUp.value, period, onReplicate.value, generateEvent);
const value = ref(aggregateTransactionAmounts(period.value.events));

watch(value, () => {
  onEvent.value(period.value, eventTemplate.value);
});
</script>
<style>

</style>