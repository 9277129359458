<template>
  <div class="px-2 md:px-0">
    <line-item-form :allowed-categories="allowedCategories"
                    :line-items="lineItems"
                    :squash="true"
                    header="Overheads"
                    label="overheads"
    >
      <template v-slot:empty>
        <div class="flex justify-center items-center">
          <div class="flex justify-end"><img alt="Recurring or one-off sales of physical or digital products" class="h-64"
                                             src="/images/overheads.svg"></div>
          <h2 class="text-grey-dark w-2/5 ml-4">The costs of running and managing your business</h2>
        </div>
      </template>
    </line-item-form>
    <step-links :current="step" :next="linkToNextStep" :previous="linkToPreviousStep" class="pt-4 pb-4"></step-links>
  </div>
</template>
<script>
import LineItemForm from '@/components/lineitems/LineItemForm.vue';
import step from './step.mixin';
import _includes from 'lodash/includes';

export default {
  mixins: [step],

  data() {
    return {
      allowedCategories: {
        rent: {
          id: 'rent',
          label: 'Rent',
          description: 'Monthly rent charges incl business rates',
          template: {
            name: '',
            tag: 'rent',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-office"><path class="secondary" d="M5 9h15a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm12 2v3h3v-3h-3zm0 5v3h3v-3h-3zm-5 0v3h3v-3h-3zm0-5v3h3v-3h-3z"/><path class="primary" d="M9 4h1a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2H9v-6H5v6H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h1a2 2 0 1 1 4 0z"/></svg>`
        },
        utilities: {
          id: 'power',
          label: 'Utilities',
          description: 'Light, power and heating costs',
          template: {
            name: '',
            tag: 'utilities',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-bolt"><circle cx="12" cy="12" r="10" class="primary"/><path class="secondary" d="M14 10h2a1 1 0 0 1 .81 1.58l-5 7A1 1 0 0 1 10 18v-4H8a1 1 0 0 1-.81-1.58l5-7A1 1 0 0 1 14 6v4z"/></svg>`
        },
        telecommunications: {
          id: 'telecommunications',
          label: 'Telecoms',
          description: 'Telephone and Internet',
          template: {
            name: '',
            tag: 'telecommunications',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-phone-ring"><path class="primary" d="M4 2h4a1 1 0 0 1 .98.8l1 5a1 1 0 0 1-.27.9l-2.52 2.52a12.05 12.05 0 0 0 5.59 5.59l2.51-2.52a1 1 0 0 1 .9-.27l5 1c.47.1.81.5.81.98v4a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2z"/><path class="secondary" d="M22 10a1 1 0 0 1-2 0 6 6 0 0 0-6-6 1 1 0 0 1 0-2 8 8 0 0 1 8 8zm-4 0a1 1 0 0 1-2 0 2 2 0 0 0-2-2 1 1 0 0 1 0-2 4 4 0 0 1 4 4z"/></svg>`
        },
        marketing: {
          id: 'marketing',
          label: 'Marketing',
          description: 'Advertising and marketing',
          template: {
            name: '',
            tag: 'marketing',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-discount"><path class="primary" d="M10.75 21.19l-.85.28c-1.21.4-2.53-.15-3.1-1.29l-.4-.8a4 4 0 0 0-1.78-1.77l-.8-.4a2.57 2.57 0 0 1-1.29-3.1l.28-.86a4 4 0 0 0 0-2.5l-.28-.85c-.4-1.21.15-2.53 1.29-3.1l.8-.4a4 4 0 0 0 1.77-1.78l.4-.8a2.57 2.57 0 0 1 3.1-1.29l.86.28a4 4 0 0 0 2.5 0l.85-.28c1.21-.4 2.53.15 3.1 1.29l.4.8a4 4 0 0 0 1.78 1.77l.8.4a2.57 2.57 0 0 1 1.29 3.1l-.28.86a4 4 0 0 0 0 2.5l.28.85c.4 1.21-.15 2.53-1.29 3.1l-.8.4a4 4 0 0 0-1.77 1.78l-.4.8a2.57 2.57 0 0 1-3.1 1.29l-.86-.28a4 4 0 0 0-2.5 0z"/><path class="secondary" d="M8.7 16.7a1 1 0 1 1-1.4-1.4l8-8a1 1 0 0 1 1.4 1.4l-8 8zm6.8.3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-7-7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/></svg>`
        },
        recruitment: {
          id: 'recruitment',
          label: 'Recruitment',
          description: 'Recruitment fees and expenses',
          template: {
            name: '',
            tag: 'recruitment',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-user-check"><path class="primary" d="M9 11a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"/><path class="secondary" d="M16 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1zm1-8.41l3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 1 1 1.4-1.42l1.3 1.3z"/></svg>`
        },
        travel: {
          id: 'travel',
          label: 'Travel',
          description: 'Travel and accommodation costs',
          template: {
            name: '',
            tag: 'travel',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-airplane"><path class="secondary" d="M19 11.9l2.7 1.14c.18.08.3.26.3.46v2a.5.5 0 0 1-.6.49L12 14 2.6 16a.5.5 0 0 1-.6-.49v-2c0-.2.12-.38.3-.46L5 11.9V10a1 1 0 1 1 2 0v1.06l4.8-2.02a.5.5 0 0 1 .4 0l4.8 2.02V10a1 1 0 0 1 2 0v1.9z"/><path class="primary" d="M12 2a2 2 0 0 1 2 2v8l-1 8h-2l-1-8V4c0-1.1.9-2 2-2z"/><path class="secondary" d="M8.64 22.98c-.32.1-.64-.17-.64-.54v-1.1c0-.19.08-.35.2-.46l3.5-2.78c.18-.13.42-.13.6 0l3.5 2.78c.12.1.2.27.2.45v1.11c0 .37-.32.64-.64.54L12 21.9l-3.36 1.07z"/></svg>`
        },
        entertainment: {
          id: 'entertainment',
          label: 'Entertainment',
          description: 'Client entertaining (parties, gifts...)',
          template: {
            name: '',
            tag: 'entertainment',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-thumbs-up"><path class="primary" d="M13 4.8l2.92 6.8a1 1 0 0 1 .08.4v8a2 2 0 0 1-2 2H8a4.28 4.28 0 0 1-3.7-2.45L2.07 14.4A1 1 0 0 1 2 14v-2a3 3 0 0 1 3-3h4V5a3 3 0 0 1 3-3 1 1 0 0 1 1 1v1.8z"/><rect width="4" height="11" x="18" y="11" class="secondary" rx="1"/></svg>`
        },
        computing: {
          id: 'computing',
          label: 'Computing',
          description: 'IT costs incl web hosting and hardware',
          template: {
            name: '',
            tag: 'computing',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-server"><path class="primary" d="M5 3h14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 10h14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4c0-1.1.9-2 2-2zm2 3a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2H7z"/><rect width="5" height="2" x="6" y="6" class="secondary" rx="1"/></svg>`,
        },
        bank: {
          id: 'bank',
          label: 'Bank',
          description: 'Bank account and processing fees',
          template: {
            name: '',
            tag: 'bank',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-wallet"><path class="primary" d="M2 5c0 1.1.9 2 2 2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5zm16 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/><path class="secondary" d="M4 3h11a2 2 0 0 1 2 2v2H4a2 2 0 1 1 0-4z"/></svg>`,
        },
        accountancy: {
          id: 'accountancy',
          label: 'Audit & Accountancy',
          description: 'Accountancy fees',
          template: {
            name: '',
            tag: 'accountancy',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-calculator"><path class="primary" d="M6 2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm2 3a1 1 0 1 0 0 2h8a1 1 0 0 0 0-2H8zm0 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-8 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-4 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm4 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/><rect width="2" height="6" x="15" y="13" class="secondary" rx="1"/></svg>`,
        },
        legal: {
          id: 'legal',
          label: 'Legal',
          description: 'Legal advice and retainer fees',
          template: {
            name: '',
            tag: 'legal',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-library"><path class="primary" d="M3 8h18v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V8zm6 3l-1 1v4l1 1H4l1-1v-4l-1-1h5zm5.5 0l-1 1v4l1 1h-5l1-1v-4l-1-1h5zm5.5 0l-1 1v4l1 1h-5l1-1v-4l-1-1h5zM3 20h18a1 1 0 0 1 0 2H3a1 1 0 0 1 0-2z"/><path class="secondary" d="M4 18h16a1 1 0 0 1 1 1v1H3v-1a1 1 0 0 1 1-1zm8.4-15.91l9 4c1 .43.68 1.91-.4 1.91H3c-1.08 0-1.4-1.48-.4-1.91l9-4a1 1 0 0 1 .8 0z"/></svg>`,
        },
        insurance: {
          id: 'insurance',
          label: 'Insurance',
          description: 'General insurance',
          template: {
            name: '',
            tag: 'insurance',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-umbrella"><path class="primary" d="M11 3.05V2a1 1 0 0 1 2 0v1.05A10 10 0 0 1 22 13c0 1.33-2 1.33-2 0a2 2 0 1 0-4 0c0 1.33-2 1.33-2 0a2 2 0 1 0-4 0c0 1.33-2 1.33-2 0a2 2 0 1 0-4 0c0 1.33-2 1.33-2 0a10 10 0 0 1 9-9.95z"/><path class="secondary" d="M11 14a1 1 0 0 1 2 0v5a3 3 0 0 1-6 0 1 1 0 0 1 2 0 1 1 0 0 0 2 0v-5z"/></svg>`,
        },
        staff_training: {
          id: 'training',
          label: 'Staff Training',
          description: '',
          template: {
            name: '',
            tag: 'staff_training',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-certificate"><path class="primary" d="M4 3h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm16 12V7a2 2 0 0 1-2-2H6a2 2 0 0 1-2 2v8a2 2 0 0 1 2 2h12c0-1.1.9-2 2-2zM8 7h8a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2z"/><path class="secondary" d="M11.65 18.23a4 4 0 1 1 4.7 0l2.5 3.44-2.23-.18-1.48 1.68-.59-4.2a4.04 4.04 0 0 1-1.1 0l-.6 4.2-1.47-1.68-2.23.18 2.5-3.44zM14 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg>`,
        },
        other: {
          id: 'general',
          label: 'General / Other',
          description: 'Other sundry expenses',
          template: {
            name: '',
            tag: 'other',
            type: 'expense',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-folder"><path class="secondary" d="M4 4h7l2 2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2z"/><rect width="20" height="12" x="2" y="8" class="primary" rx="2"/></svg>`,
        },
      },
    };
  },

  props: {},

  computed: {
    lineItems() {
      if (this.allowedCategories) {
        return this.lineItemsWith((item) => {
          return _includes(Object.keys(this.allowedCategories), item.tag) && item.outgoing;
        });
      }

      if (this.allowedCategory) {
        return this.lineItemsWith((item) => {
          return this.allowedCategory.template.tag === item.tag && item.outgoing;
        });
      }
    },
  },

  methods: {},

  components: {
    LineItemForm,
  },

  mounted() {

  },
};

</script>
<style>
</style>