<template>
  <ui-button v-bind="{...$attrs, ...$props}"
             :class="classes"
  >
    <slot/>
  </ui-button>
</template>

<script>
import UiButton from '@/components/ui/Button.vue';
import {ref, computed} from 'vue';

export default {
  compatConfig: {
    INSTANCE_LISTENERS: false,
  },
  components: {
    UiButton,
  },

  props: {
    machine: {
      required: false,
    }
  },

  setup(props) {
    const styles = {
      ready: "bg-blue-700 hover:bg-blue-900 text-white hover:text-white focus:ring focus:ring-blue-500 rounded",
      loading: "bg-orange-700 hover:bg-orange-900 text-white hover:text-white focus:ring focus:ring-orange-500 rounded animate-spin",
      error: "bg-red-700 hover:bg-red-900 text-white hover:text-white focus:ring focus:ring-red-500 rounded",
      success: "bg-green-700 hover:bg-green-900 text-white hover:text-white focus:ring focus:ring-green-500 rounded"
    }

    const state = ref();
    if(props.machine) {
      state.value = props.machine.initialState.value;

      props.machine.onTransition(newState => {
        state.value = newState.value
        console.log(state.value);
      });
    }

    const classes = computed(() => styles[state.value] ?? styles.ready)

    return {
      classes,
      state,
    }
  }
};
</script>

<style>

</style>