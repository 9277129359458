<template>
    <div class="bg-grey-lighter h-screen text-grey-darker h-full">
        <div v-if="!invite">
            Loading...
        </div>
        <component v-else :is="invite.type" :invite="invite" :token="token"></component>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';

  import Advisor from './AdvisorInviteRedemption.vue';

  export default {
    data() {
      return {
        passwordHidden: true,
        invite: null,
        organisation_name: null,
        name: null,
        password: null,
        token: null,
      }
    },
    components: {
      Advisor,
    },
    methods: {
      fetchInvite() {
        let hash = this.$route.params.id;

        axios.get(route('api.invites.show', {
          id: hash,
          token: this.token,
        })).then(success => {
          this.invite = success.data;
        })
      },
    },
    mounted() {
      this.token = this.$route.query.token;
      this.fetchInvite()
    }
  }
</script>

<style>

</style>