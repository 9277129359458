import formMixin from '../forms/form.mixin';

export default {
  mixins: [formMixin],

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },
};
