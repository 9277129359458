<template>
  <div class="mt-4 projections container mx-auto pb-12">
    <div class="flex justify-between items-center flex-wrap">
      <ul class="px-4 list-reset flex flex-col md:flex-row md:flex-wrap gap-x-2">
        <li class="md:focus-within:ring md:ring-blue-500 rounded flex-1 flex" tabindex="-1">
          <router-link :class="salesPlanCss" :to="{name: 'projection.report.sales'}"
          >Sales Plan
          </router-link>
        </li>
        <li class="md:focus-within:ring md:ring-blue-500 rounded flex-1 flex">
          <router-link :class="cashFlowCss" :to="{name: 'projection.report.cashflow'}"
          >Cash Flow
          </router-link>
        </li>
        <li class="md:focus-within:ring md:ring-blue-500 rounded flex-1 flex">
          <router-link :class="profitLossCss" :to="{name: 'projection.report.profitloss'}">
            Profit & Loss
          </router-link>
        </li>
        <li class="md:focus-within:ring md:ring-blue-500 rounded flex-1 flex">
          <router-link :class="balanceSheetCss" :to="{name: 'projection.report.balancesheet'}">
            Balance Sheet
          </router-link>
        </li>
      </ul>
      <ul class="px-4 list-reset md:flex">
        <li class="focus-within:ring ring-blue-500 rounded-sm">
          <PrimaryAction @click.prevent="openExport">Export</PrimaryAction>
        </li>
      </ul>
    </div>
    <div class="mt-4 bg-white shadow">
      <div class="mb-4 flex justify-end border-b border-gray-300 sm:border-0 px-4 py-4">
        <year-selector/>
      </div>
      <div class="px-4 py-4">
        <router-view></router-view>
      </div>
    </div>
    <teleport to="body">
      <modal v-cloak v-model="showExport">
        <Export class="bg-white shadow-lg rounded w-full max-w-md"
                :projection="projection"
                @close="closeExport"
                v-if="showExport"
        />
      </modal>
    </teleport>
  </div>
</template>
<script>
import step from './step.mixin';
import YearSelector from '@/components/ui/YearSelector.vue';
import {mapGetters} from 'vuex';
import Modal from '@/components/Modal.vue';
import Export from '@/components/reports/Export.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

export default {
  mixins: [step],

  data() {
    return {
      showExport: false,
    };
  },

  computed: {
    salesPlanCss() {
      let css = {};

      if (this.$route.name == 'projection.report.sales') {
        css[this.activeCss] = true;
      } else {
        css[this.inactiveCss] = true;
      }

      return css;
    },
    cashFlowCss() {
      let css = {};

      if (this.$route.name == 'projection.report.cashflow') {
        css[this.activeCss] = true;
      } else {
        css[this.inactiveCss] = true;
      }

      return css;
    },

    profitLossCss() {
      let css = {};

      if (this.$route.name == 'projection.report.profitloss') { // todo clean up to active classes
        css[this.activeCss] = true;
      } else {
        css[this.inactiveCss] = true;
      }

      return css;
    },

    balanceSheetCss() {
      let css = {};

      if (this.$route.name == 'projection.report.balancesheet') { // todo clean up to active classes
        css[this.activeCss] = true;
      } else {
        css[this.inactiveCss] = true;
      }

      return css;
    },

    exportUrl() {
      return route('projections.export.generate', {projection: this.projection.id});
    },

    activeCss() {
      return 'flex-1 whitespace-nowrap py-1 px-3 inline-block border border-blue-700 rounded bg-blue-700 text-white no-underline';
    },
    inactiveCss() {
      return 'flex-1 whitespace-nowrap py-1 px-3 inline-block border border-gray-300 rounded bg-gray-50 text-grey no-underline hover:shadow hover:bg-blue-700 hover:text-white hover:border-blue-700';
    },
    ...mapGetters({
      'token': 'auth/token',
    }),
  },

  methods: {
    openExport() {
      this.showExport = true;
      // let url = new URL(this.exportUrl);
      // window.open(url.toString(), '_blank');
    },
    closeExport() {
      this.showExport = false;
    }
  },

  components: {
    YearSelector,
    Modal,
    Export,
    PrimaryAction,
  },
};

</script>
<style>
.projections table {
  white-space: nowrap;
}

.projections table th:first-of-type {
  /*width: 200px;*/
}

.projections table tr td:first-of-type {
}
</style>