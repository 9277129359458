<template>
    <div>
        <stripe @loaded="setup" :api-key="apiKey"></stripe>
        <form @submit.stop.prevent id="card-input" method="post">
            <div :class="cardElementWrapperCss" ref="card">
                <!-- A Stripe Element will be inserted here. -->
            </div>
            <!-- Used to display form errors. -->
<!--            <div class="text-sm text-red-600 mt-2" role="alert">-->
<!--                <p v-if="error">{{ error.message }}</p>-->
<!--            </div>-->
        </form>
    </div>
</template>

<script>
  import Stripe from '@/components/billing/Stripe.vue';

  export default {
    data() {
      return {
        apiKey: import.meta.env.VITE_STRIPE_KEY,
        error: null,
        stripe: null,
        elements: null,
        card: null,
        cardFocused: false,
        style: {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      };
    },
    props: {
      pendingSetupIntent: {
        type: String,
        required: false,
      },
    },
    computed: {
      cardElementWrapperCss() {
        return {
          'bg-white p-3 rounded border-gray-300 border': true,
          'border-transparent': !this.cardFocused,
          'border-blue-400': this.cardFocused,
        };
      },
    },
    methods: {
      updateErrorMessage(event) {
        this.error = event.error;
      },
      setup(stripe) {
        this.stripe = stripe;

        this.elements = this.stripe.elements();

        this.card = this.elements.create('card', {
          hidePostalCode: true,
          style: this.style,
        });

        this.card.mount(this.$refs.card);
        this.card.addEventListener('change', this.updateErrorMessage);
        this.card.addEventListener('focus', (event) => {
          this.cardFocused = true;
        });
        this.card.addEventListener('blur', (event) => {
          this.cardFocused = false;
        });

        this.$emit('loaded', this.stripe);
      },
      onInput() {
        this.$emit('update:modelValue', {card: this.card, error: this.error});
      }
    },
    watch: {
      card: {
        deep: true,
        handler() {
          this.onInput();
        },
      },
      error() {
        this.onInput();
      }
    },
    components: {
      Stripe,
    },
  };
</script>

<style>
</style>