<template>
	<div>
		<text-input class="mb-4"
					label="Unique Reference"
					name="reference"
					v-model="modelValue.reference"
                    :autofocus="focus"
					v-if="isVisible('reference')"
		></text-input>
		<text-input class="mb-4"
					label="Name"
					name="name"
					v-model="modelValue.name"
					v-if="isVisible('name')"
		></text-input>
		<text-input class="mb-4"
					label="Description"
					name="description"
					v-model="modelValue.description"
					v-if="isVisible('description')"
		></text-input>
	</div>
</template>
<script>
  import item from '../item'
  // available fields
  import visibleFieldMap from './visibleFields.mixin.js';

  export default {
    mixins: [item, visibleFieldMap],

    data() {
      return {};
    },

    props: {},

    computed: {},

    components: {},

    mounted() {

    },
  };

</script>
<style>

</style>