import StepLinks from '@/components/StepLinks.vue';
import { mapGetters } from 'vuex';
import filter from '@/components/lineitems/filter.mixin';

export default {
  mixins: [filter],
  props: {
    step: {
      type: Object,
      required: true,
    },
    nextStep: {
      type: Object,
      required: false,
    },
    previousStep: {
      type: Object,
      required: false,
    },
  },
  computed: {
    linkToNextStep() {
      if (!this.nextStep) {
        return null;
      }

      return { name: this.nextStepName };
    },
    linkToPreviousStep() {
      if (!this.previousStep) {
        return null;
      }

      return { name: this.previousStepName };
    },
    ...mapGetters({
      projection: 'projections/current',
    }),
    lineItems() {
      if ('allowedCategories' in this) {
        return this.lineItemsWithAnyTags(Object.keys(this.allowedCategories));
      }

      if (this.allowedCategory) {
        return this.lineItemsWithAnyTags([this.allowedCategory.template.tag]);
      }
    },
    nextStepName() {
      return this.name(this.nextStep);
    },
    previousStepName() {
      return this.name(this.previousStep);
    },
  },
  methods: {
    name(step) {
      if (!step.route.name) {
        return step.route.children[0].name;
      }

      return step.route.name;
    },
  },
  components: {
    StepLinks,
  },
};
