import {reactive, ref} from 'vue';
import axios from 'axios';

function useForm(initialFields) {
  const data = reactive(initialFields);
  const errors = ref({});

  async function submit(method, url) {
    try {
      let response;
      if (method === 'get' || method === 'delete') {
        response = await axios[method](url);
      } else {
        response = await axios[method](url, data);
      }

      errors.value = {};  // Clear errors upon successful request
      return response;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        errors.value = error.response.data.errors;
      }
      throw error;
    }
  }

  function get(url) {
    return submit('get', url);
  }

  function post(url) {
    return submit('post', url);
  }

  function put(url) {
    return submit('put', url);
  }

  function patch(url) {
    return submit('patch', url);
  }

  function del(url) {
    return submit('delete', url);
  }

  const getFieldErrors = (field) => {
      return errors.value[field] ?? null;
  };

  return { data, errors: getFieldErrors, get, post, put, patch, delete: del };
}

export default useForm;
