<template>
    <div class="h-full w-full flex">
        <div class="py-4 px-4 bg-gray-800">
            <ul class="list-reset m-0 text-grey-dark text-sm">
                <li class="parent">
                    <nav-item :route="{name: 'tenant.settings.organisation.index'}">
                        <svg class="w-8 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path class="primary"
                                  d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm11 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                            <path class="secondary"
                                  d="M9.73 14H17a1 1 0 0 1 0 2H9.73a2 2 0 0 0 0-2zm4.54-6a2 2 0 0 0 0 2H7a1 1 0 1 1 0-2h7.27z"/>
                        </svg>
                        <span class="ml-2 font-medium">Account</span>
                    </nav-item>
                </li>
                <li class="parent">
                    <nav-item :route="{name: 'tenant.settings.users.index'}">
                        <svg class="w-8 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path class="primary"
                                  d="M12 13a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1 1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3zM7 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm10 0a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            <path class="secondary"
                                  d="M12 13a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm-3 1h6a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3z"/>
                        </svg>
                        <span class="ml-2 font-medium">Users</span>
                    </nav-item>
                </li>
            </ul>
        </div>
        <div class="w-full flex flex-row mt-2">
            <div class="px-16 flex-grow">
                <router-view class="py-8"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
  import NavItem from '@/components/nav/NavItem.vue';

  export default {
    data() {
      return {};
    },
    components: {
      NavItem,
    }
  };
</script>

<style>

</style>