<template>
    <div v-if="clientSecret">
        <stripe @loaded="stripe = $event" :api-key="apiKey"/>
    </div>
    <not-found v-else/>
</template>

<script>
  import Stripe from '@/components/billing/Stripe.vue';
  import NotFound from '@/pages/404.vue';

  export default {
    compatConfig: {
      WATCH_ARRAY: false,
    },
    data() {
      return {
        clientSecret: this.$route.query.client_secret,
        stripe: null,
      };
    },
    computed: {
      apiKey() {
        return import.meta.env.VITE_STRIPE_KEY;
      }
    },
    components: {
      Stripe,
      NotFound,
    },
    watch: {
      stripe() {
        this.stripe.confirmCardPayment(this.clientSecret).then(result => {
          if(result.error) {
            // we need a new card
            this.error = result.error
            return
          }

          this.$router.replace({name: 'home'})
          // axios.post(route('api.cards.confirm')).then(() => this.confirmed = true)
          //   .catch(() => window.reload());
        })
      }
    }
  };
</script>

<style>

</style>