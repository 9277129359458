<template>
    <div ref="sticky" class="step-links">
        <div class="container mx-auto flex-grow flex content-bottom no-underline z-30">
            <div class="flex flex-grow justify-start" v-if="previous">
                <router-link :to="previous" class="self-end">
                    <button @click="$track('Projection: Click Back', {route: current.route.name})" class="btn bg-gray-100 hover:bg-gray-700 hover:text-gray-200">Back</button>
                </router-link>
            </div>
            <div class="flex flex-grow justify-end" v-if="next">
                <router-link :to="next" class="self-end">
                    <primary-action @click="$track('Projection: Click Continue', {route: current.route.name})">Continue</primary-action>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
  import stickybits from 'stickybits';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

  export default {
    data() {
      return {
        stickybits: null,
      };
    },

    props: {
      current: {
        type: Object,
        default: null,
      },
      previous: {
        type: Object,
      },
      next: {
        type: Object,
      }
    },

    computed: {},

    components: {
      PrimaryAction,
    },

    mounted() {
        this.$nextTick(() => {
          this.stickybits = stickybits(this.$refs.sticky, {
            useStickyClasses: true,
            verticalPosition: 'bottom',
          });
          this.stickybits.update();
        })
    },

    unmounted() {
      this.stickybits.update();
      this.stickybits.cleanup();
      this.stickybits = null;
    }
  };

</script>
<style>
    /*.sticky-links {*/
        /*position: sticky;*/
        /*bottom: 30px;*/
        /*@apply . bg-white*/
    /*}*/

    .step-links {
        transition: .25s;
        transition-timing-function: ease-in-out;
    }

    .step-links.js-is-sticky {
        @apply bg-white shadow-lg;
    }

    .step-links.js-is-stuck {
        background: unset;
    }
</style>