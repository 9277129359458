import _each from 'lodash/each';
import PrimaryEventInput from '@/components/lineitems/PrimaryEventInput.vue';
import {unref} from 'vue';

export const props = {
  period: {
    type: Object,
  },
  lineItem: {
    type: Object,
    required: true,
  },
  onEvent: {
    type: Function,
    default() {
      return () => {};
    },
  },
  onReplicate: {
    type: Function,
    default() {
      return () => {};
    },
  },
};

export const useEventInput = (keyToTotalUp, period, onReplicate, generateEvent) => {
  const aggregateTransactionAmounts = (events) => {
    let total = null;
    _each(events, (event) => {
      total += +event[keyToTotalUp];
    });

    return total;
  };

  const replicateRight = (newValue) => {
    const periodValue = unref(period);
    return onReplicate(periodValue, generateEvent(newValue));
  }

  return {
    aggregateTransactionAmounts,
    replicateRight,
  }
}

export default {
  props,

  methods: {
    aggregateTransactionAmounts(events) {
      let total = null;
      const { keyToTotalUp } = this;
      _each(events, (event) => {
        total += +event[keyToTotalUp];
      });

      return total;
    },
    replicateRight(event) {
      return this.onReplicate(this.period, this.event);
      // return this.onEvent(this.period, this.event).then(() => {
      //   this.$emit('replicateRight', event);
      // });
    },
  },

  components: {
    PrimaryEventInput,
  },
};
