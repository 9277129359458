<template>
    <div>
        <popup-form v-model="popup">
            <slot/>
            <template v-slot:form>
                <p class="text-lg">Are you sure you want to delete <span class="font-semibold">{{ projection.name }}</span>?</p>
                <div class="flex justify-between mt-6">
                    <button @click="close" class="btn border border-gray text-gray-900 uppercase">Cancel</button>
                    <button @click="destroy" class="btn bg-red-600 text-white uppercase">Delete</button>
                </div>
            </template>
        </popup-form>
    </div>
</template>
<script>
import PopupForm from '@/components/forms/PopupForm.vue';

export default {
    data() {
      return {
        name: this.projection.name,
        originalTitle: this.projection.name,
        popup: false,
      };
    },

    props: {
      projection: {
        type: Object,
        required: true,
      },
    },

    computed: {
      title() {
        if (this.originalTitle) {
          return 'Duplicate \'' + this.originalTitle + '\'';
        }

        return null;
      },
    },

    methods: {
      destroy() {
        axios.delete(route('api.projections.destroy', {projection: this.projection.id})).then(
          success => {
            this.popup = false;
            window.location.reload();
          },
          error => {
            console.error(error);
            alert('Something went wrong. Please refresh the page and try again.');
          },
        );
      },
      close() {
        this.popup = false;
      },
    },

    components: {
      PopupForm,
    },

    mounted() {

    },
  };
</script>
<style>

</style>