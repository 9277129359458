import TransactionPeriod from '@/components/lineitems/TransactionPeriod.vue';
import {mapActions} from 'vuex';
import _reduce from 'lodash/reduce';

export default {

  data() {
    return {
      item: this.initialLineItem,
      width: 50,
      upsertingPromise: new Promise(resolve => resolve()),
    };
  },

  props: {
    initialLineItem: {
      type: Object,
    },
    periodLabels: {
      // type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    total() {
      return _reduce(this.totals, (carry, total) => carry + total, 0);
    },
  },

  methods: {
    ...mapActions({
      requestUpsertLineItem: 'projections/lineItems/upsert',
      requestUpsertPeriod: 'projections/lineItems/upsertPeriod',
      setLineItemPeriodTotals: 'setLineItemPeriodTotals',
      refreshLineItem: 'projections/lineItems/refresh',
    }),
    async upsertLineItem(...args) {
      // ensure that no existing upsert is in action. If it is, wait then perform action.
      await this.upsertingPromise;
      return this.upsertingPromise = this.requestUpsertLineItem(...args);
    },
    async upsertPeriod(lineItem, period) {
      await this.upsertingPromise;
      return this.upsertingPromise = this.requestUpsertPeriod(
        {lineItem, period});
    },
    reportTotals() {
        this.setLineItemPeriodTotals({ lineItem: this.item, totals: this.totals });
    },
  },

  watch: {
    initialLineItem: {
      deep: true,
      handler() {
        this.item = this.initialLineItem;
      },
    },
    totals: {
      deep: true,
      handler() {
        if (this.item.id !== undefined) {
          this.reportTotals();
        }
      }
    },
  },

  components: {
    TransactionPeriod,
  },

  created() {
    // ignore any 'phantom' items
    if (this.item.id !== undefined) {
      this.reportTotals();
    }
  },

  mounted() {
    this.width = this.$el.clientWidth / 12;
  },
};
