import SalesPlan from './SalesPlan.vue';
import CashFlow from './CashFlow.vue';
import ProfitLoss from './ProfitLoss.vue';
import BalanceSheet from './BalanceSheet.vue';

export default {
  SalesPlan,
  CashFlow,
  ProfitLoss,
  BalanceSheet,
};
