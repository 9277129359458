<template>
  <div class="p-4 w-80">
    <div class="mb-4">
      <h2 class="text-indigo">
        {{ header }}
      </h2>
    </div>
    <div class="mb-4">
      <text-input
          :autofocus="true"
          v-model="name"
          name="name"
          label="Name"
      />
    </div>
    <div class="mb-4">
      <div class="mb-4">
        <ui-label>Start Date</ui-label>
        <month-picker
            v-model="date"
            name="start_date"
        />
      </div>
    </div>
    <div class="mb-4">
      <div class="mb-4">
        <ui-label>Duration</ui-label>
        <ui-select
            v-model="duration"
            :options="[12, 24, 36, 48, 60]"
        >
          <template v-slot:option="{option}">
            {{ durationLabels[option] }}
          </template>
        </ui-select>
      </div>
    </div>
    <div class="mt-8 flex justify-between">
      <SecondaryAction type="button" @click.stop="$emit('cancel')">
        Cancel
      </SecondaryAction>
      <primary-action @click.prevent="store">
        Save
      </primary-action>
    </div>
  </div>
</template>
<script>
import MonthPicker from '@/components/forms/MonthYearPicker.vue';
import moment from 'moment';
import UiLabel from '@/components/ui/Label.vue';
import UiSelect from '@/components/ui/Select.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import {computed, ref, toRefs, watch} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import analytics from '@/app/analytics.js';
import SecondaryAction from '../../buttons/SecondaryAction.vue';

export default {
  compatConfig: {COMPONENT_V_MODEL: false},
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    const {modelValue, title} = toRefs(props);
    const name = ref(modelValue.value);
    const date = ref(moment.utc().add('1', 'M').startOf('month').toDate());
    const duration = ref(36);

    const header = computed(() => title.value ?? 'New Projection');
    const durationLabels = computed(() => ({
      12: '1 Year',
      24: '2 Years',
      36: '3 Years',
      48: '4 Years',
      60: '5 Years',
    }));

    watch(modelValue, () => name.value = modelValue.value);
    watch(name, () => emit('update:modelValue', name.value));

    const payload = computed(() => {
      return {
        name: name.value,
        startDate: moment(date.value).format(),
        numberOfPeriods: duration.value,
      }
    });



    const router = useRouter();
    const vuex = useStore();
    const store = () => {

      axios.post(route('api.projections.store'), payload.value).then(
          (success) => { router.push({name: 'projections.build', params: {id: success.data.id}}); },
          (error) => { vuex.dispatch('setErrors', error.response.data.errors); },
      ).then(() => analytics.track('Create Projection', {...payload}));
    };

    return {
      name,
      date,
      duration,

      header,
      durationLabels,

      store,
    };
  },

  components: {
    SecondaryAction,
    MonthPicker,
    UiLabel,
    UiSelect,
    PrimaryAction,
  },
};

</script>
<style>

</style>
