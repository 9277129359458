import store from '@/store';

export default function () {
  if (!window.data) {
    window.data = {}; // recover
  }

  // import default projections into vuex store
  if (window.data.projections) {
    store.dispatch('projections/setProjections', window.data.projections);
  }

  if (window.data.userToken) {
    store.dispatch('auth/setToken', window.data.userToken);
  }
}
