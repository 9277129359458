<template>
  <div class="contents">
    <ui-label>VAT Rate</ui-label>
    <select-input v-model="selected" :options="rates">
      <template v-slot:label>VAT Rate</template>
      <template v-slot:option="{option}">{{ option.rate * 100 }}%</template>
    </select-input>
  </div>
</template>

<script>
import UiLabel from '@/components/ui/Label.vue';
import SelectInput from '@/components/forms/SelectInput.vue';
import {ref, watch} from 'vue';
import {rates, rateFromId} from '@/app/vat.js';
import {string} from 'vue-types';

export default {
  components: {
    UiLabel,
    SelectInput,
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: string().isRequired,
  },

  setup(props, {emit}) {
    const selected = ref(rateFromId(props.modelValue));

    watch(selected,() => {
      emit('update:modelValue', selected.value.id);
    })

    watch(() => props.modelValue, () => {
      if(props.modelValue.value !== selected.value){
        selected.value = rateFromId(props.modelValue)
      }
    })

    return {
      selected,
      rates,
    }
  },
}
</script>

<style>

</style>