import _sum from 'lodash/sum';
import _map from 'lodash/map';
import _ceil from 'lodash/ceil';
import _chunk from 'lodash/chunk';

export default function (item, periodTotaler) {
  const years = _chunk(item.periods.data, 12);

  return _map(years, (periods, yearIndex) => {
    const yearPeriodTotals = periodTotaler(periods, yearIndex);
    return {
      periods: yearPeriodTotals.map(val => _ceil(val)),
      total: _ceil(_sum(yearPeriodTotals)),
    };
  });
}
