<template>
  <div class="relative px-2 flex appearance-none border border-gray-300 rounded w-full py-2 bg-white items-center focus-within:ring-1 focus-within:ring-blue-500">
    <EditorContent class="w-full" :editor="editor" v-model="content"/>
  </div>
</template>

<script setup>
import {defineProps, toRefs, ref, watch} from 'vue';
import { useEditor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';

const props = defineProps({
  modelValue: {
    type: String,
  },
});

const emit = defineEmits(['update:modelValue']);

const { modelValue } = toRefs(props);
const content = ref(modelValue.value);

const editor = useEditor({
  content: content.value,
  editable: true,
  extensions: [
    StarterKit,
  ],
  onUpdate: ({ editor }) => {
    emit('update:modelValue', editor.getText());
  },
})

watch(modelValue, (value) => {
  content.value = value;
  const isSame = editor.value?.getText() === value;

  if(!isSame) {
    editor.value?.commands.setContent(value);
  }
});

</script>

<style>

</style>