<template>
    <div>
        <div class="px-16 py-4">
            <div class="flex mt-2 justify-between">
                <h1>Clients</h1>
                <div class="flex flex-col justify-center">
                    <router-link :to="{name: 'clients.create'}">
                        <primary-action>
                            Add a new client
                        </primary-action>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="px-4 md:px-8 rounded text-left table-bspace-0 text-grey-darker mt-4 mb-4 bg-white" v-cloak>
          <SimpleTable :headings="{name: 'Name', actions: ''}" :items="clients">
            <template v-slot:name="{item}">
              <router-link :to="{name: 'tenant.home', params: {tenant: item.id}}"
                           class="font-semibold py-3 no-underline appearance-none hover:text-blue-700">
                {{ item.name }}
              </router-link>
            </template>
            <template v-slot:actions="{item}">
              <div class="flex">
                <DeleteClientButton :client="item"
                                    class="mr-8 hover:text-red-700 cursor-pointer"/>
<!--                <router-link :to="{name: 'projections.index', params: {tenant: item.id}}"-->
<!--                             class="px-2 py-2 rounded border text-sm text-gray-darker no-underline hover:underline"-->
<!--                >-->
<!--                  View Account-->
<!--                </router-link>-->
              </div>
            </template>
          </SimpleTable>
          <Paginator class="mt-4 px-8" :modelValue="pagination"/>
        </div>
    </div>
</template>

<script>
import store from '@/store';
import {mapGetters} from 'vuex';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import DeleteClientButton from '@/components/clients/DeleteClientButton.vue';
import SimpleTable from '../../components/SimpleTable.vue';
import Paginator from '../../components/Paginator.vue';

export default {
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        clients: 'clients/all',
        pagination: 'clients/pagination',
      }),
    },
    components: {
      Paginator,
      SimpleTable,
      PrimaryAction,
      DeleteClientButton,
    },
    beforeRouteEnter(to, from, next) {
      store.dispatch('clients/fetchAll', to.query).then(next);
    },
    beforeRouteUpdate(to, from, next) {
      store.dispatch('clients/fetchAll', to.query).then(next);
    },
  };
</script>

<style>

</style>