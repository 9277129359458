<template>
    <div style="overflow-x: auto;" class="text-sm" v-show="loaded">
        <profit-loss-year-table :year="year" :years="years" :profit-loss="balances" v-if="year"
        ></profit-loss-year-table>
        <statement-annual-summary :years="years" :balances="balances.balances" :labels="labels" v-else/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import _mapValues from 'lodash/mapValues';
import moment from 'moment';

import ProfitLossYearTable from './ProfitLossYearTable.vue';
import StatementAnnualSummary from './StatementAnnualSummary.vue';
import labels from '@/components/reports/labels';
import _find from 'lodash/find';
import {useCache} from '../projections/cache/cache.js';
import {onBeforeUnmount} from 'vue';

export default {
    data() {
      return {
        sections: [],
        balances: {},
        yearIndex: 0,
      }
    },

    computed: {
      labels() {
        return labels.profitLoss;
      },
      periods() {
        return _mapValues(this.projection.periods[this.year], month => {
          return moment(month);
        });
      },
      ...mapGetters({
        projection: 'projections/current',
        currentYear: 'projections/year',
        user: 'auth/user',
      }),
      year() {
        return this.projection.reporting_periods.data.find(period => period.label === this.currentYear)
      },
      years() {
        return this.projection.reporting_periods.data;
      },
      loaded() {
        return Object.keys(this.balances).length > 0;
      },
      currentReportingPeriod() {
        return _find(this.projection.reporting_periods.data, period => period.label === this.year.label);
      },
    },

    methods: {
      refresh() {
        return axios.get(route('api.projections.profitloss', {
          projection: this.projection.id,
          tenant: this.user.organisation.id,
        }), {
          year: this.yearIndex,
        }).then(
            success => {
              this.balances = success.data;
            },
            error => {
              console.error(error);
            },
        );
      },
    },

    created() {
      const {subscribe, stop} = useCache(this.projection);
      const unsubscribe = subscribe((resolve, reject) => {
        this.refresh().then(resolve).catch(reject);
      });

      this.refresh();

      onBeforeUnmount(unsubscribe);
    },

    components: {
      ProfitLossYearTable,
      StatementAnnualSummary,
    }
  };

</script>
<style scoped>
    table {
        border-spacing: 0;
    }

    /*tr:not(.no-bg):hover {*/
    /*background-color: rgba(249, 249, 249, 1);*/
    /*}*/
</style>