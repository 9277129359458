<template>
	<div class="px-2 md:px-0">
		<line-item-form header="Employees" :allowed-category="allowedCategory"
						:line-items="lineItems"
						label="employees"
		>
            <template v-slot:empty>
                <div class="flex justify-center items-center">
                    <div class="flex justify-end"><img class="h-64" src="/images/employees.svg" alt="Recurring or one-off sales of physical or digital products"></div>
                    <h2 class="text-grey-dark w-2/5 ml-4">Staff salaries, pensions and tax</h2>
                </div>
            </template>
        </line-item-form>
		<step-links class="pt-4 pb-4" :current="step" :previous="linkToPreviousStep" :next="linkToNextStep"></step-links>
	</div>
</template>
<script>
  import LineItemForm from '@/components/lineitems/LineItemForm.vue';
  import step from './step.mixin';

  export default {
    mixins: [step],

    data() {
      return {
        allowedCategory: {
          id: "employee",
          disabled: false,
		  template: {
            name: "",
            tag: "employee",
            type: "employee"
		  }
        },
      };
    },

    props: {},

    computed: {},

    components: {
      LineItemForm,
    },

    mounted() {

    },
  };

</script>
<style>

</style>