<template>
    <div>
        <form method="post" id="payment-form" @submit.stop.prevent="attemptSubmit">
            <div class="form-row">
                <label class="block text-gray-darker text-sm mb-2">
                    Credit or debit card*
                </label>
                <div :class="cardElementWrapperCss" ref="card">
                    <!-- A Stripe Element will be inserted here. -->
                </div>
                <!-- Used to display form errors. -->
                <div class="text-sm text-red-500 mt-2" role="alert">
                    <p v-if="error">{{ error.message }}</p>
                </div>
                <p class="text-xs text-gray mt-2">Your card number is sent securely to our payment provider and charged based on your account usage each year. We do not hold your details on file and they are never sent to our servers.</p>
            </div>

            <div class="flex justify-center">
                <button :class="{'btn bg-blue-600 text-white mt-4': true, 'opacity-50 cursor-not-allowed': !ready}" :disabled="!ready || loading">Get started</button>
            </div>
        </form>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        error: null,
        stripe: null,
        elements: null,
        card: null,
        cardFocused: false,
        style: {
          base: {
            color: '#32325d',
            lineHeight: '18px',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#aab7c4'
            }
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
      }
    },
    props: {
      ready: {
        type: Boolean,
        default: true,
      },
      pendingSetupIntent: {
        type: String,
        required: false,
      }
    },
    computed: {
      cardElementWrapperCss() {
        return {
          'bg-white p-3 rounded border-gray-300 border': true,
          'border-transparent': !this.cardFocused,
          'border-blue-400': this.cardFocused,
        }
      }
    },
    methods: {
      attemptSubmit() {
        let vm = this;
        vm.loading = true;
        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
        }).then(({paymentMethod}) => {
          vm.$emit('submit', paymentMethod);
          vm.loading = false;
        }).finally(() => vm.loading = false)
      },
      updateErrorMessage(event) {
        this.$emit('change', event);
        this.error = event.error
      }
    },
    mounted() {
      let stripe = document.createElement('script')
      stripe.setAttribute('src', 'https://js.stripe.com/v3/')
      document.head.appendChild(stripe);

      stripe.onload = () => {
        this.stripe = Stripe(import.meta.env.VITE_STRIPE_KEY);
        this.elements = this.stripe.elements();

        this.card = this.elements.create('card', {
          hidePostalCode: true,
          style: this.style
        });
        this.card.mount(this.$refs.card)
        this.card.addEventListener('change', this.updateErrorMessage);
        this.card.addEventListener('focus', (event) => {
          this.cardFocused = true;
        });
        this.card.addEventListener('blur', (event) => {
          this.cardFocused = false;
        });
      }
    }
  }
</script>

<style>
</style>