<template>
  <DatePicker :locale="{firstDayOfWeek: 2, masks}" :masks="masks" class="shadow-none" v-model="dateRef">
    <template v-slot="{ inputValue, inputEvents }">
      <text-input class="parent cursor-pointer" :modelValue="inputValue" readonly v-on='inputEvents'>
        <template v-slot:right>
          <button class="group" @click="clear" v-if="inputValue">
            <svg class="icon-close-circle cursor-pointer w-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <circle class="fill-current text-gray-200 group-hover:text-gray-400" cx="12" cy="12" r="10"/>
              <path class="fill-current text-gray-700 group-hover:text-gray-100"
                    d="M13.41 12l2.83 2.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 1 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12z"/>
            </svg>
          </button>
        </template>
      </text-input>
    </template>
  </DatePicker>
</template>

<script>
import {computed, defineComponent, ref, watch} from 'vue';
import {DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';
import TextInput from '@/components/forms/TextInput.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Date,
      required: false,
    },
  },
  components: {
    TextInput,
    DatePicker,
  },
  setup(props, { emit }) {
    const dateRef = ref(null);

    watch(() => props.modelValue, (value) => {
      dateRef.value = value;
    }, { immediate: true });

    watch(dateRef, () => {
      emit('update:modelValue', dateRef.value);
    });

    const masks = computed(() => ({
      input: ['D MMM YYYY'],
    }));

    const clear = () => {
      dateRef.value = null;
    };

    return {
      dateRef,
      masks,
      clear,
    };
  },
});
</script>

<style>

</style>
