<template>
	<label class="block text-gray-800 text-sm font-medium"><slot></slot></label>
</template>
<script>

  export default {
    data() {
      return {};
    },

    computed: {},

    components: {},

    mounted() {

    },
  };

</script>
<style>

</style>