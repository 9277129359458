<template>
    <div class="flex flex-wrap sm:flex-nowrap items-center w-full space-x-2">
        <text-input type="number" class="" :min="0" v-model="duration.number"></text-input>
        <ui-select :options="allowedUnits" v-model="duration.unit">
            <template v-slot:option="{option}">{{ option }}</template>
        </ui-select>
    </div>
</template>

<script>
import TextInput from '@/components/forms/TextInput.vue';
import UiSelect from '@/components/forms/SelectInput.vue';

const unitMap = {'D': 'days', 'M': 'months'};

  export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: String,
        default: "P7D",
      }
    },
    data() {
      return {
        duration: {
          number: 7,
          unit: 'days',
        },
      };
    },
    watch: {
      modelValue: {
        immediate: true,
        handler(value){
          let matches = this.pattern.exec(value);

          if(!matches) {
            return;
          }

          if(matches.length) {
            this.duration.number = matches[1] || 7;
            this.duration.unit = unitMap[matches[2] || 'D']
          }
        }
      },
      durationSpec: {
        deep: true,
        handler() {
          this.$emit('update:modelValue', this.durationSpec)
        }
      }
    },
    computed: {
      pattern() {
        return /P([0-9]+)([D,M])/
      },
      allowedUnits() {
        return Object.values(unitMap)
      },
      durationSpec() {
        let unitIndex = this.allowedUnits.indexOf(this.duration.unit);
        let unitKey = Object.keys(unitMap)[unitIndex];

        return `P${this.duration.number}${unitKey}`;
      }
    },
    components: {
      TextInput,
      UiSelect,
    },
  };
</script>

<style>

</style>