import store from '@/store';

export const loggedInUser = () => store.getters['auth/user'];

export function defaultRedirect(user) {
  if (user.features.indexOf('clients') > -1) {
    return {
      name: 'clients.index',
      params: { tenant: loggedInUser().organisation.id },
    };
  }

  return {
    name: 'projections.index',
    params: { tenant: loggedInUser().organisation.id },
  };
}

export function ensure(expression, next) {
  if (!expression) {
    return next({ name: 'forbidden' });
  }
}

export function prefix(prefix, routes) {
  for (const route of routes) {
    route.path = (prefix + (route.path ? `/${route.path}` : '')).replace(/\/$/, '');
  }

  return routes;
}
