import router from '@/router';
import store from '@/store';
import multiguard from 'vue-router-multiguard';

function setTenant(tenant) {
  Ziggy.defaultParameters = {
    ...Ziggy.defaultParameters,
    tenant,
  };
}

export default function () {
  // tenant default
  router.beforeEach((to, from, next) => {
    if (to.params.tenant && Ziggy.defaultParameters.tenant
      !== to.params.tenant) {
      store.dispatch('auth/refreshUser', to.params.tenant)
        .then(() => {
          setTenant(to.params.tenant);
          next();
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            store.dispatch('auth/clearToken');
            next({ name: 'auth.login' });
          } else {
            alert('Something went wrong. Please refresh the page.');
          }
        });
    } else {
      if (to.params.tenant) {
        setTenant(to.params.tenant);
      }

      next();
    }
  });

  router.beforeEach((to, from, next) => {
    const records = to.matched.filter(record => record.meta.middleware);

    if (records && records.length > 0) {
      const middleware = records.map(record => record.meta.middleware).flat();

      multiguard(middleware)(to, from, next);
    } else {
      next();
    }
  });
}
