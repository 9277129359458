import {computed, reactive} from 'vue';
import extensions from '@/app/extensions.js';
import _first from 'lodash/first';

export const rates = [
  {id: 'standard', rate: 0.20},
  {id: 'reduced', rate: 0.05},
  {id: 'zero', rate: 0.00},
];

export const defaultVatRate = computed(() => _first(rates).id);

export function rateFromId(id) {
  return rates.find(rate => rate.id === id);
}

export function getVat(item) {
  const {fetchExtension} = extensions(item);
  return reactive(fetchExtension('vat', {rate: defaultVatRate.value}));
};