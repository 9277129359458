export default class ContainerManager {
  constructor() {
    this.containers = [];
  }

  add(container, payload) {
    this.containers.push({
      container,
      payload,
    });
  }

  payload(container) {
    const definition = this.containers[this.containers.findIndex(obj => obj.container === container)];
    return definition ? definition.payload : null;
  }

  remove(container) {
    const index = this.containers.findIndex(obj => obj.container === container);
    this.containers.splice(index, 1);
  }
}
