<template>
    <div>
        <TextInput class="mb-4" label="Name" name="name" v-model="modelValue.name" :autofocus="focus"></TextInput>
        <TextInput class="mb-4" label="Description" name="description" v-model="modelValue.description"></TextInput>
        <TextInput class="mb-4" label="Sales Price" name="price" v-model="modelValue.price">
          <template v-slot:left>
            <span class="text-grey">£</span>
          </template>
        </TextInput>
        <TextInput class="mb-4" label="Product Cost" name="cost" v-model="cost.cost">
          <template v-slot:left>
            <span class="text-grey">£</span>
          </template>
        </TextInput>
        <div class="mb-4">
          <vat-rate-dropdown v-model="vat.rate"/>
        </div>
    </div>
</template>
<script>
  import item from '../../item';
  import vat from '../vat.mixin';
  import extensions from '@/components/lineitems/extensions.mixin';
  import UiLabel from '@/components/ui/Label.vue';
  import VatRateDropdown from '@/components/VatRateDropdown.vue';
  import TextInput from '@/components/forms/TextInput.vue';
  import {getVat} from '@/app/vat.js';

  export default {
    mixins: [item, vat, extensions],

    computed: {
      cost() {
        return this.fetchExtension('cost', {
          cost: null,
        })
      },
      vat() {
        return getVat(this.modelValue);
      }
    },

    components: {
      UiLabel,
      VatRateDropdown,
      TextInput,
    }
  };

</script>
<style>

</style>