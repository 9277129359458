<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <span class="relative z-0 inline-flex shadow-sm rounded flex-grow-0">
  <button class="relative inline-flex items-center px-3 py-1 rounded-l bg-blue-700 text-sm font-medium text-white hover:bg-blue-900 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          type="button" @click="$emit('click', $event)">
    <slot :selected="selected">
      {{ selected }}
    </slot>
  </button>
  <span class="-ml-px relative block" v-click-away="close">
    <button id="option-menu" class="relative inline-flex items-center border border-blue-800 px-2 py-2 rounded-r bg-blue-700 text-sm font-medium text-white hover:bg-blue-900 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            type="button"
            @click.stop="isOpen = !isOpen">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/chevron-down -->
      <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg">
        <path clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"/>
      </svg>
    </button>
    <!--
      Dropdown panel, show/hide based on dropdown state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"

        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
    >
      <div
          v-show="isOpen"
          class="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div aria-labelledby="option-menu" aria-orientation="vertical" class="py-1" role="menu">
        <a v-for="option in options" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" href="#"
           role="menuitem"
           @click.stop="selected = option"
        >
          <slot :option="option" name="option">
            {{ option }}
          </slot>
        </a>
      </div>
    </div>
    </transition>
  </span>
</span>

</template>

<script>
import {ref, watch} from 'vue';


export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    open: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {default: null}
  },
  setup(props, {emit}) {
    const isOpen = ref(props.open);
    watch(props, () => isOpen.value = props.open, {deep: true});
    watch(isOpen, () => {
      if(isOpen.value) {
        emit('open')
      } else {
        emit('close')
      }
    });

    const close = () => isOpen.value = false;

    const selected = ref(props.modelValue);
    watch(props.modelValue, () => selected.value = props.modelValue);
    watch(selected, () => {
      isOpen.value = false;
      emit('update:modelValue', selected.value);
    });

    return {
      isOpen,
      close,
      selected,
    };
  },
  components: {},
};
</script>

<style>

</style>