<template>
  <div class="flex flex-col sm:flex-row min-h-full font-sans">
    <sidebar-navigation/>
    <div class="flex flex-col flex-1">
      <router-view></router-view>
    </div>
    <!--        <card-update-required-popup-->
    <!--                :last-invoice-id="lastInvoiceId"-->
    <!--                v-if="billingStatus == 'requires_payment_method'"-->
    <!--        ></card-update-required-popup>-->
    <!--        <card-confirmation-required-popup-->
    <!--                :last-invoice-id="lastInvoiceId"-->
    <!--                :payment-intent-secret="paymentIntentSecret"-->
    <!--                v-else-if="billingStatus == 'requires_action'"-->
    <!--        ></card-confirmation-required-popup>-->
    <router-view mode="out-in" name="slide" @notify="notify" @clear="clear"></router-view>
    <notification-stream :modelValue="notifications" @dismiss="clear"/>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import SidebarNavigation from '@/components/nav/SidebarNavigation.vue';
import CardUpdateRequiredPopup from '@/components/billing/CardUpdateRequiredPopup.vue';
import CardConfirmationRequiredPopup from '@/components/billing/CardConfirmationRequiredPopup.vue';
import SlideOver from '@/components/ui/SlideOver.vue';
import NotificationStream from '@/components/NotificationStream.vue';

export default {
  data() {
    return {
    };
  },

  components: {
    SidebarNavigation,
    CardUpdateRequiredPopup,
    CardConfirmationRequiredPopup,
    SlideOver,
    NotificationStream,
  },

  computed: {
    ...mapGetters({
      organisation: 'organisations/current',
      notifications: 'notifications',
    }),
    billingStatus() {
      return this.organisation.billing ? this.organisation.billing.status : 'active';
    },
    paymentIntentSecret() {
      return this.organisation.billing ? this.organisation.billing.payment_intent_secret : null;
    },
    lastInvoiceId() {
      if (this.paymentIntentSecret) {
        return this.organisation.billing ? this.organisation.billing.last_invoice_id : null;
      }
    },
  },

  methods: {
    ...mapActions({
      'notify': 'notify',
      'clear' : 'clearNotifications',
    }),
  },
};
</script>

<style>

</style>