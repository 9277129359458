<template>
  <div>
    <!--<text-input class="mb-4" label="Unique Reference" name="reference" v-model="item.reference"></text-input>-->
    <text-input v-model="item.name" :autofocus="focus" class="mb-4" label="Name" name="name"></text-input>
    <text-input v-model="item.description" class="mb-4" label="Description" name="description"></text-input>
    <div class="flex justify-between w-full">
      <div class="w-1/2 mb-4 mr-2">
        <text-input v-model="item.price" class="mb-4" label="Sales Price" name="price">
          <template v-slot:left>
            <span class="text-grey">£</span>
          </template>
        </text-input>
      </div>
      <div class="w-1/2 mb-4 ml-2">
        <text-input v-model="cost.cost" class="mb-4" label="Product Cost" name="cost">
          <template v-slot:left>
            <span class="text-grey">£</span>
          </template>
        </text-input>
      </div>
    </div>
    <ui-label>Contract Terms</ui-label>
    <div class="grid grid-cols-2 gap-x-4">
      <!-- Renews every X , 'months' -- the term! -->
      <select-input v-model="data.life" :options="possibleLives">
        <template v-slot:left>Renews:</template>
        <template v-slot:option="{option}">{{ option.label }}</template>
      </select-input>

      <!-- Paid in installments every X months -- must be less than the term! -->
      <select-input v-model="data.paymentTerms" :disabled="allowedPaymentTerms.length < 2 ? true : null"
                    :options="allowedPaymentTerms"
      >
        <template v-slot:left>Paid:</template>
        <template v-slot:option="{option}">{{ option.label }}</template>
      </select-input>
    </div>
    <!-- Churn rate (on renewal) -->
    <div class="mt-4 mb-4 flex justify-between">
      <div class="w-1/2 mb-4 mr-2">
        <text-input v-model.number="subscription.churn_rate"
                    css-override="text-right"
                    label="Churn Rate"
                    name="rate"
        >
          <template v-slot:right>
            <div class="text-grey">%</div>
          </template>
        </text-input>
      </div>
      <div class="w-1/2 mb-4 ml-2">
        <vat-rate-dropdown v-model="vat.rate"/>
      </div>
    </div>
  </div>
</template>
<script>
import item from '../../item';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import UiLabel from '@/components/ui/Label.vue';
import SelectInput from '@/components/forms/SelectInput.vue';

import {computed, reactive, watch, watchEffect} from 'vue';
import extensions from '@/app/extensions.js';
import {getVat} from '@/app/vat.js';

import VatRateDropdown from '@/components/VatRateDropdown.vue';
import _first from 'lodash/first';
import _last from 'lodash/last';

dayjs.extend(duration);

export default {
  mixins: [item],

  setup(props) {
    const item = props.modelValue;
    const {fetchExtension} = extensions(item);

    const subscription = reactive(fetchExtension('subscription', {
      churn_rate: 0,
      installment_interval: 1,
    }));

    const cost = reactive(fetchExtension('cost', {
      cost: null,
    }));

    const possiblePaymentTerms = [
      {
        label: 'up-front',
        interval: 'PT0S',
      },
      {
        label: 'monthly',
        interval: 'P1M',
      },
    ];

    const possibleLives = [
      {
        label: 'monthly',
        interval: 'P1M',
      },
      {
        label: 'annually',
        interval: 'P1Y',
      },
    ];

    const vat = getVat(item);

    const data = reactive({
      paymentTerms: possiblePaymentTerms.find(term => term.interval === subscription.installment_interval) ?? _first(possiblePaymentTerms),
      life: possibleLives.find(life => life.interval === item.life) ?? _last(possibleLives),
      churn: {
        rate: 0,
      },
    });

    const endOfDurationFromNow = (duration) => dayjs().add(dayjs.duration(duration))
    const lifeEnd = computed(() => endOfDurationFromNow(item.life))

    const allowedPaymentTerms = computed(() => {
      return possiblePaymentTerms.filter((term) => endOfDurationFromNow(term.interval).isBefore(lifeEnd.value, 'second'))
    });

    watchEffect(() => {
      item.life = data.life.interval;
    })

    watchEffect(() => {
      subscription.installment_interval = data.paymentTerms.interval
    })

    // if the current selection of payment terms is no longer available, set it to the first one
    watch(allowedPaymentTerms, () => {
      if(!allowedPaymentTerms.value.find(term => term.interval === data.paymentTerms.interval)) {
        data.paymentTerms = allowedPaymentTerms.value[0];
      }
    }, { deep: true })

    return {
      cost,
      vat,
      subscription,
      item,

      possibleLives,
      allowedPaymentTerms: allowedPaymentTerms,
      data,
    }

  },

  components: {
    UiLabel,
    SelectInput,
    VatRateDropdown,
  },
};

</script>
<style>

</style>