import fetchExtension from '@/components/lineitems/utils/fetch-extension';
import {computed} from 'vue';

/**
 * @param item {Ref}
 * @returns {{fetchExtension: (function(*, *): *), extensions: ComputedRef<unknown>}}
 */
export const useExtensions = (item) => {
  const extensions = computed(() => item.value?.extensions.data ?? []);
  const fetchExtensionWrapper = (type, template) => {
    return fetchExtension(item.value, type, template);
  };

  return {
    extensions,
    fetchExtension: fetchExtensionWrapper,
  };
}

export default {
  computed: {
    extensions() {
      return (this.modelValue ?? this.item).extensions.data;
    },
  },
  methods: {
    fetchExtension(type, template) {
      return fetchExtension(this.modelValue ?? this.item, type, template);
    },
  },
};
