<template>
  <div>
    <div class="px-4 md:px-16 py-4">
      <div class="flex mt-2 justify-between flex-wrap w-full">
        <div>
          <h2 class="font-normal text-gray-700 text-sm">{{ user.organisation.name }}</h2>
          <h1>Projections</h1>
        </div>
        <div class="flex flex-col justify-center w-full sm:w-auto mt-2 sm:mt-0">
          <popup-form>
            <primary-action class="w-full sm:w-auto">Create new Projection</primary-action>
            <template v-slot:form="{open, close}">
              <projection-create-form v-if="open" @cancel="close"></projection-create-form>
            </template>
          </popup-form>
        </div>
      </div>
    </div>
    <div v-cloak class="px-4 md:px-8 rounded text-left table-bspace-0 text-grey-darker mt-4 mb-4 bg-white">
      <SimpleTable :items="projections" :headings="{name: 'Name', start_date: 'Start Date', human_duration: 'Duration', updated_at: 'Last Updated', actions: ''}">
        <template v-slot:name="{item}">
          <router-link :to="{name: 'projections.build', params: {id: item.id}}"
                       class="font-semibold py-3 no-underline appearance-none hover:text-blue-700">
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:start_date="{item}">
          <span>{{ formatDate(item.start_date) }}</span>
        </template>
        <template v-slot:duration="{item}">
          <span>{{ item.human_duration }} days</span>
        </template>
        <template v-slot:updated_at="{item}">
          <span>{{ formatDate(item.updated_at) }}</span>
        </template>
        <template v-slot:actions="{item}">
          <div class="flex justify-center items-center z-10">
            <div :ref="(el) => parents[item.id] = el" >
              <ThreeDotButton @click="toggleMenu(item.id)" />
            </div>
          </div>
        </template>
      </SimpleTable>
      <Teleport to="body" v-for="(projection, index) in projections">
        <Dropdown :open="activeProjectionMenu === projection.id" :parent="parents[projection.id]" pin="bottom-start">
          <div @click.stop="closeMenu" class="z-30 mx-2">
            <div class="bg-white z-50 border rounded shadow">
              <router-link :to="{name: 'projections.edit', params: {id: projection.id}, query: queryParameters()}" class="focus:text-blue-500">
                <div @click="closeMenu" class="py-4 px-8 hover:bg-gray-100 hover:text-gray-800">Settings
                </div>
              </router-link>
              <DuplicateButton :projection="projection">
                <div @click="closeMenu" class="py-4 px-8 hover:bg-gray-100 hover:text-gray-800 cursor-default">
                  Duplicate
                </div>
              </DuplicateButton>
              <DeleteProjectionButton :projection="projection" class="" @click="closeMenu">
                <div @click="closeMenu" class="cursor-default py-4 px-8 hover:bg-gray-100 hover:text-gray-800">
                  Delete
                </div>
              </DeleteProjectionButton>
            </div>
          </div>
        </Dropdown>
      </Teleport>
      <Paginator class="mt-4 px-8" :modelValue="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {computed, onBeforeMount, ref} from 'vue';
import {useStore} from 'vuex';
import moment from 'moment-timezone';
import Paginator from '@/components/Paginator.vue';
import PopupForm from '@/components/forms/PopupForm.vue';
import ProjectionCreateForm from '@/components/projections/forms/CreateForm.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import SimpleTable from '@/components/SimpleTable.vue';
import ThreeDotButton from '@/components/ui/ThreeDotButton.vue';
import Dropdown from '@/components/Dropdown.vue';
import {onBeforeRouteUpdate, useRoute} from 'vue-router';
import DeleteProjectionButton from '@/components/projections/buttons/DeleteButton.vue';
import {queryParameters} from '../../app/routing/route.js';
import DuplicateButton from '@/components/projections/buttons/DuplicateButton.vue';

const store = useStore();
const user = computed(() => store.getters['auth/user']);
const projections = computed(() => store.getters['projections/all']);
const pagination = computed(() => store.getters['projections/pagination']);
const timezone = computed(() => store.getters['timezone']);
const showMenu = ref(false);
const parents = ref({});

const formatDate = (date) => {
  return moment(date).tz(timezone.value).format('MMM D, YYYY');
};

const route = useRoute();
const activeProjectionMenu = ref();
const closeMenu = () => {
  if (activeProjectionMenu.value) {
    activeProjectionMenu.value = undefined;
  }
};

const toggleMenu = (id) => {
  if (activeProjectionMenu.value === id) {
    activeProjectionMenu.value = undefined;
  } else {
    activeProjectionMenu.value = id;
  }
};

onBeforeMount(() => {
  store.dispatch('projections/fetchAll');
});

onBeforeRouteUpdate((to, from) => {
  store.dispatch('projections/fetchAll', to.query);
});
</script>

<style>
</style>
