<template>
	<div class="px-2 py-3 sm:px-0 mx-auto flex flex-wrap justify-center progress-bar">
		<slot></slot>
	</div>
</template>
<script>
  // import Step from './Step.vue'

  export default {
    data() {
      return {};
    },

    props: {},

    computed: {},

    components: {
      // Step,
	},

    mounted() {

    },
  };

</script>
<style>

</style>