<template>
  <div>
    <list-box class="px-3 py-4 w-128" :options="options" :search-options="searchOptions" @update:modelValue="$emit('update:modelValue', $event)">
      <template v-slot:option="{option}">
        <div class="flex items-center">
          <div v-html="option.icon" class="flex-shrink-0 h-6 w-6 rounded-full"/>
          <span class="ml-3 block font-normal truncate">
          <p class="font-semibold">
            {{ option.label }}
          </p>
          <p>
            {{ option.description }}
          </p>
        </span>
        </div>
      </template>
    </list-box>
  </div>
</template>

<script>
import ListBox from '@/components/forms/ListBox.vue';
import {computed, reactive} from 'vue';

export default {
  props: {
    types: {
      type: Object,
    },
  },
  setup(props) {
    const types = reactive(props.types);

    const searchOptions = {
      includeScore: true,
      threshold: 0.1,
      keys: ['label', 'description']
    }

    const options = computed(() => {
      if(!types) {
        return [];
      }

      return Object.values(types)
    })

    return {
      options,
      searchOptions,
    }
  },
  components: {
    ListBox,
  }
}
</script>

<style>

</style>