<template>
    <component :is="type" :key="key" :notification="notification" @dismiss="event => $emit('dismiss', event)">
      {{ notification.body }}
    </component>
</template>

<script>
import SuccessNotification from '@/components/notifications/SuccessNotification.vue';
import ErrorNotification from '@/components/notifications/ErrorNotification.vue';

import _uniqueId from 'lodash/uniqueId';
import {computed, watch, ref} from 'vue';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {
      type: Object,
    },
  },
  components: {
    'success': SuccessNotification,
    'error': ErrorNotification,
  },
  setup(props) {
    const type = computed(() => props.modelValue?.type);
    const notification = computed(() => props.modelValue);

    const genKey = () => _uniqueId('notification-');
    const key = ref(genKey());

    watch(props.modelValue, () => {
      key.value = genKey();
    });

    return {
      type,
      notification,
      key,
    };
  },
};
</script>

<style>

</style>