<template>
    <PrimaryEventInput
            event-type="investment"
            :on-replicate="replicateRight"
            :period="period"
            v-model="value"
            v-bind="$attrs"
            :summer="aggregateTransactionAmounts"
    ></PrimaryEventInput>
</template>
<script setup>
import PrimaryEventInput from '@/components/lineitems/PrimaryEventInput.vue';
import {props as inputProps, useEventInput} from './eventinput.mixin';
import {computed, ref, toRefs, watch} from 'vue';

const props = defineProps({
  ...inputProps,
});

const {period, onEvent, onReplicate} = toRefs(props);

const generateEvent = (value) => {
  return {
    value: value,
    type: 'investment',
  };
};

const eventTemplate = computed(() => {
  return generateEvent(value.value);
});

const {aggregateTransactionAmounts, replicateRight} = useEventInput('value', period, onReplicate.value, generateEvent);
const value = ref(aggregateTransactionAmounts(period.value.events));

watch(value, () => {
  onEvent.value(period.value, eventTemplate.value);
})
</script>
<style>

</style>