<template>
    <div class="flex w-full h-full items-center justify-center bg-gray-100">
        <div class="w-full md:w-2/3 lg:w-1/2 flex flex-col lg:flex-row justify-center bg-white shadow-md rounded py-16">
            <div class="w-full xl:w-1/2 flex justify-center items-center">
                <img src="/images/logo.png" class="w-48 xl:w-64" alt="">
            </div>
            <div class="w-full xl:w-1/2 max-w-xs p-4">
                <form class="mb-4 w-full" method="POST" @submit.prevent="attempt">
                    <div class="mb-4">
                        <ui-label>Email</ui-label>
                        <ui-text-input
                                name="email"
                                autocomplete="email" type="email" placeholder="Email" v-model="form.data.email" :errors="form.errors('email')"/>
                    </div>
                    <div class="mb-6">
                        <ui-label>Password</ui-label>
                        <ui-text-input
                               autocomplete="password" type="password" placeholder="*******" v-model="form.data.password" :errors="form.errors('password')"/>
                    </div>
                    <div class="flex flex-col xl:flex-row items-center justify-between">
                        <primary-action type="submit">
                            Sign In
                        </primary-action>
                        <router-link :to="{name: 'auth.password_reset'}" class="mt-2 xl:mt-0 inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker">
                            Forgot Password?
                        </router-link>
                    </div>
                </form>
                <p class="text-center text-gray text-xs">
                    <!--©2018 {{ config('app.name') }}. All rights reserved.-->
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import UiLabel from '@/components/ui/Label.vue';
import UiTextInput from '@/components/forms/TextInput.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import useForm from '@/app/form.js';

const form = useForm({
  email: '',
  password: '',
});

const store = useStore();
const router = useRouter();
const currentRoute = router.currentRoute;

const attempt = () => {
  try {
    const response = form.post(route('auth.login')).then(
      response => {
        store.dispatch('auth/setToken', response.data);
        const redirect = currentRoute.value.query.redirect;

        if (redirect) {
          router.push({ path: redirect });
        } else {
          router.push({ name: 'home', params: { tenant: store.getters['auth/user'].organisation.id } });
        }
      }
    );
  } catch (e) {
    console.error(e);
  }
};
</script>

<style>
</style>