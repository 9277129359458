import router from '@/router';
import {reactive} from 'vue';

const defaultParameters = reactive({});

export const queryParameters = (params = {}) => {
  const search = window.location.search;
  const urlParams = new URLSearchParams(search);
  return {
    ...Object.fromEntries(urlParams.entries()),
    ...params,
  }
}

export default {
  defaults: defaultParameters,
  resolve(to) {
    return router.resolve({...to, params: {...to.params, ...defaultParameters}})
  },
}