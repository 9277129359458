import Draggable from '@shopify/draggable/lib/es5/draggable';
import {
  SortableStartEvent,
  SortableSortEvent,
  SortableSortedEvent,
  SortableStopEvent,
} from '@shopify/draggable/lib/es5/sortable';

import ContainerManager from './ContainerManager';

const onDragStart = Symbol('onDragStart');
const onDragOverContainer = Symbol('onDragOverContainer');
const onDragOver = Symbol('onDragOver');
const onDragStop = Symbol('onDragStop');

/**
 * Returns announcement message when a Draggable element has been sorted with another Draggable element
 * or moved into a new container
 * @param {SortableSortedEvent} sortableEvent
 * @return {String}
 */
function onSortableSortedDefaultAnnouncement({ dragEvent }) {
  const sourceText = dragEvent.source.textContent.trim()
    || dragEvent.source.id || 'sortable element';

  if (dragEvent.over) {
    const overText = dragEvent.over.textContent.trim()
      || dragEvent.over.id || 'sortable element';
    const isFollowing = dragEvent.source.compareDocumentPosition(
      dragEvent.over,
    ) & Node.DOCUMENT_POSITION_FOLLOWING;

    if (isFollowing) {
      return `Placed ${sourceText} after ${overText}`;
    }
    return `Placed ${sourceText} before ${overText}`;
  }
  // need to figure out how to compute container name
  return `Placed ${sourceText} into a different container`;
}

/**
 * @const {Object} defaultAnnouncements
 * @const {Function} defaultAnnouncements['sortable:sorted']
 */
const defaultAnnouncements = {
  'sortable:sorted': onSortableSortedDefaultAnnouncement,
};

/**
 * Sortable is built on top of Draggable and allows sorting of draggable elements. Sortable will keep
 * track of the original index and emits the new index as you drag over draggable elements.
 * @class Sortable
 * @module Sortable
 * @extends Draggable
 */
export default class Sortable extends Draggable {
  /**
   * Sortable constructor.
   * @constructs Sortable
   * @param {HTMLElement[]|NodeList|HTMLElement} containers - Sortable containers
   * @param {Object} options - Options for Sortable
   */
  constructor(containers = [], options = {}) {
    super(containers, {
      ...options,
      announcements: {
        ...defaultAnnouncements,
        ...(options.announcements || {}),
      },
    });

    /**
     * start index of source on drag start
     * @property startIndex
     * @type {Number}
     */
    this.startIndex = null;
    this.startSibling = null;

    this.containerManager = new ContainerManager();

    /**
     * start container on drag start
     * @property startContainer
     * @type {HTMLElement}
     * @default null
     */
    this.startContainer = null;

    this[onDragStart] = this[onDragStart].bind(this);
    this[onDragOverContainer] = this[onDragOverContainer].bind(this);
    this[onDragOver] = this[onDragOver].bind(this);
    this[onDragStop] = this[onDragStop].bind(this);

    this.on('drag:start', this[onDragStart])
      .on('drag:over:container', this[onDragOverContainer])
      .on('drag:over', (e) => {
        try {
          return this[onDragOver](e);
        } catch (error) {
        // console.log('error', error); // TODO - how else to catch this safely?
        }
      })
      .on('drag:stop', this[onDragStop]);
  }

  /**
   * Destroys Sortable instance.
   */
  destroy() {
    super.destroy();

    this.off('drag:start', this[onDragStart])
      .off('drag:over:container', this[onDragOverContainer])
      .off('drag:over', this[onDragOver])
      .off('drag:stop', this[onDragStop]);
  }

  /**
   * Returns true index of element within its container during drag operation, i.e. excluding mirror and original source
   * @param {HTMLElement} element - An element
   * @return {Number}
   */
  index(element) {
    return this.getDraggableElementsForContainer(element.parentNode)
      .indexOf(element);
  }


  // TODO - have overriden base class to only look at one level deep for indexes.
  getDraggableElementsForContainer(container) {
    container.setAttribute('new-container', '');

    const allDraggableElements = container.parentNode.querySelectorAll(`[new-container] > ${this.options.draggable}`);
    container.removeAttribute('new-container');
    // const allDraggableElements = container.querySelectorAll(this.options.draggable);

    return [...allDraggableElements].filter(childElement => childElement !== this.originalSource && childElement !== this.mirror);
  }

  // todo - overriden to support metadata
  /**
   * Adds container to this draggable instance
   * @param {HTMLElement} container - Container you want to add to draggable
   * @param payload?
   * @return {Draggable}
   * @example draggable.addContainer(document.body)
   */
  addContainer(container, payload = {}) {
    this.containers.push(container);
    this.sensors.forEach(sensor => sensor.addContainer(container));
    this.containerManager.add(container, payload);
    return this;
  }

  // todo - overriden to support metadata
  /**
   * Removes container from this draggable instance
   * @param {HTMLElement} container - Container you want to remove from draggable
   * @return {Draggable}
   * @example draggable.removeContainer(document.body)
   */
  removeContainer(container) {
    this.containers = this.containers.filter(check => check !== container);
    this.sensors.forEach(sensor => sensor.removeContainer(container));
    return this;
  }


  /**
   * Drag start handler
   * @private
   * @param {DragStartEvent} event - Drag start event
   */
  [onDragStart](event) {
    this.startContainer = event.source.parentNode;
    this.startIndex = this.index(event.source);
    this.startSibling = event.source.nextElementSibling;

    const sortableStartEvent = new SortableStartEvent({
      dragEvent: event,
      startIndex: this.startIndex,
      startContainer: this.startContainer,
    });

    this.trigger(sortableStartEvent);

    if (sortableStartEvent.canceled()) {
      event.cancel();
    }
  }

  /**
   * Drag over container handler
   * @private
   * @param {DragOverContainerEvent} event - Drag over container event
   */
  [onDragOverContainer](event) {
    if (event.canceled()) {
      return;
    }

    const { source, over, overContainer } = event;
    const oldIndex = this.index(source);

    const sortableSortEvent = new SortableSortEvent({
      dragEvent: event,
      currentIndex: oldIndex,
      source,
      over,
    });

    this.trigger(sortableSortEvent);

    if (sortableSortEvent.canceled()) {
      return;
    }

    const children = this.getDraggableElementsForContainer(overContainer);
    const moves = move({
      source, over, overContainer, children,
    });

    if (!moves) {
      return;
    }

    const { oldContainer, newContainer, revert } = moves;
    const newIndex = this.index(event.source);

    const sortableSortedEvent = new SortableSortedEvent({
      dragEvent: event,
      oldIndex,
      newIndex,
      oldContainer,
      newContainer,
      revert,
    });

    this.trigger(sortableSortedEvent);
  }

  /**
   * Drag over handler
   * @private
   * @param {DragOverEvent} event - Drag over event
   */
  [onDragOver](event) {
    if (event.over === event.originalSource || event.over
      === event.source) {
      return;
    }

    const { source, over, overContainer } = event;
    const oldIndex = this.index(source);

    const sortableSortEvent = new SortableSortEvent({
      dragEvent: event,
      currentIndex: oldIndex,
      source,
      over,
    });

    this.trigger(sortableSortEvent);

    if (sortableSortEvent.canceled()) {
      return;
    }

    const children = this.getDraggableElementsForContainer(overContainer);
    const moves = move({
      source, over, overContainer, children,
    });

    if (!moves) {
      return;
    }

    const { oldContainer, newContainer, revert } = moves;
    const newIndex = this.index(source);

    const sortableSortedEvent = new SortableSortedEvent({
      dragEvent: event,
      oldIndex,
      newIndex,
      oldContainer,
      newContainer,
      revert,
    });

    this.trigger(sortableSortedEvent);
  }

  /**
   * Drag stop handler
   * @private
   * @param {DragStopEvent} event - Drag stop event
   */
  [onDragStop](event) {
    const revert = () => {
      this.startContainer.insertBefore(event.source, this.startSibling);
    };

    const sortableStopEvent = new SortableStopEvent({
      dragEvent: event,
      oldIndex: this.startIndex,
      newIndex: this.index(event.source),
      oldContainer: this.startContainer,
      newContainer: event.source.parentNode,
      revert,
      oldContainerPayload: this.containerManager.payload(this.startContainer),
      newContainerPayload: this.containerManager.payload(event.source.parentNode),
    });

    this.trigger(sortableStopEvent);

    this.startIndex = null;
    this.startContainer = null;
    this.startSibling = null;
  }
}

function index(element) {
  return Array.prototype.indexOf.call(element.parentNode.children, element);
}

function move({
  source, over, overContainer, children,
}) {
  const emptyOverContainer = !children.length;
  const differentContainer = source.parentNode !== overContainer;
  const sameContainer = over && !differentContainer;

  if (emptyOverContainer) {
    return moveInsideEmptyContainer(source, overContainer);
  } if (sameContainer) {
    return moveWithinContainer(source, over);
  } if (differentContainer) {
    return moveOutsideContainer(source, over, overContainer);
  }
  return null;
}

function moveInsideEmptyContainer(source, overContainer) {
  const oldContainer = source.parentNode;

  overContainer.appendChild(source);

  const revert = () => {
    // just inserts it back prior to previously adjacent sibling
    oldContainer.appendChild(source);
  };

  return { oldContainer, newContainer: overContainer, revert };
}

function moveWithinContainer(source, over) {
  const oldIndex = index(source);
  const newIndex = index(over);
  let revert;

  if (oldIndex < newIndex) {
    source.parentNode.insertBefore(source, over.nextElementSibling);

    revert = () => {
      // inside same container, so just move it back to oldIndex
      source.parentNode.insertBefore(source, source.parentNode.children[oldIndex]);
    };
  } else {
    source.parentNode.insertBefore(source, over);

    revert = () => {
      source.parentNode.insertBefore(source, over.nextElementSibling);
    };
  }

  return { oldContainer: source.parentNode, newContainer: source.parentNode, revert };
}

function moveOutsideContainer(source, over, overContainer) {
  const oldContainer = source.parentNode;
  const oldNextSibling = source.nextElementSibling;
  const revert = () => {
    oldContainer.insertBefore(source, oldNextSibling);
  };

  if (over) {
    over.parentNode.insertBefore(source, over);
  } else {
    // need to figure out proper position
    overContainer.appendChild(source);
  }

  return { oldContainer, newContainer: source.parentNode, revert };
}
