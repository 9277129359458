<template>
  <div class="contents" ref="root">
    <slot></slot>
  </div>
</template>

<script>
  import move from 'array-move'
  import Sortable from '@/app/sort/DataSortable';
  import {Plugins} from '@shopify/draggable';
  import {ref, toRefs, reactive, onMounted, onBeforeUnmount} from 'vue';

  export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: Array,
        default() {
          return []
        }
      },
      itemClass: {
        type: String,
        default: 'sortable-item',
      },

      handleClass: {
        type: String,
        default: 'sortable-handle'
      },
    },

    setup(props, { emit, slots }) {
      const sortable = ref(null);
      const root = ref();
      const container = ref();
      const { itemClass, handleClass, modelValue } = toRefs(props);

      const enable = () => {
        let options = {
          draggable: `.${itemClass.value}`,
          mirror: {
            constrainDimensions: false,
            appendTo: container.value,
            cursorOffsetX: 5,
            cursorOffsetY: 5,
          },
        }

        if(handleClass) {
          options['handle'] = `.${handleClass.value}`
        }

        sortable.value = new Sortable(container.value, options)
        sortable.value.containerManager.add(container.value)

        sortable.value.on('sortable:stop', (e) => {
          const data = e.data
          data.revert() // moves it back so that dom manipulations don't interfere with Vue tracking
          const result = move(modelValue.value, data.oldIndex, data.newIndex)
          emit('update:modelValue', result)
          emit('moved', data.newIndex)
        })
      }

      onMounted(() => {
        container.value = root.value.children[0];
        enable();
      });
      onBeforeUnmount(() => {
        sortable.value?.destroy();
      })

      return {
        sortable,
        root,
        container,
      }
    },

    provide() {
      return {
        sortableItemClass: this.itemClass,
        sortableHandleClass: this.handleClass,
      }
    },
  };
</script>

<style>

</style>