<template>
  <InputCell
    v-model="tempValue"
    :disabled="disabled"
    :grayedOut="grayedOut"
    :prefix="prefix"
    :style="computedStyle"
    :replicate="onReplicate"
  />
</template>

<script>
import {computed, ref, toRefs, watch} from 'vue';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import InputCell from './InputCell.vue';

export default {
  props: {
    inputClass: {},
    modelValue: {
      required: false,
      type: Number,
    },
    period: {
      type: Object,
    },
    eventType: {
      type: String,
    },
    onReplicate: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    grayedOut: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    summer: {
      type: Function,
      default: null,
    },
  },

  components: {
    InputCell,
  },

  setup(props, { emit }) {
    // Convert props to reactive references
    const { modelValue, period, eventType, summer } = toRefs(props);

    // Data
    const tempValue = ref(modelValue.value);
    const isActive = ref(false);
    const reportChanges = ref(true);
    const input = ref();

    // Computed
    const primaryEvents = computed(() => {
      if (!period.value) {
        return [];
      }

      return _filter(period.value.events, event => {
        return _includes([eventType.value], event.type);
      });
    });

    const computedStyle = computed(() => ({
      'min-width': '80px',
    }));

    // Methods
    const aggregatePrimaryEvents = () => {
      if (!summer.value) {
        return;
      }

      const newValue = summer.value(primaryEvents.value);

      if (newValue !== tempValue.value) {
        tempValue.value = newValue;
      }
    };

    // Watchers
    watch(primaryEvents, (newValue, oldValue) => {
      aggregatePrimaryEvents();
    }, { deep: true });

    watch(tempValue, (newValue) => {
      emit('update:modelValue', Number(newValue));
    });

    watch(modelValue, (newValue) => {
      tempValue.value = newValue;
    });

    return {
      tempValue,
      isActive,
      reportChanges,
      primaryEvents,
      computedStyle,
      aggregatePrimaryEvents,
    };
  },
};
</script>
