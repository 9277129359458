<template>
    <ul class="list-reset flex flex-wrap justify-end items-center content-center gap-x-2 gap-y-2">
        <li v-for="year in years">
            <button :class="{
					'py-1 px-3 inline-block border border-blue-700 bg-blue-700 text-white no-underline shadow': currentYear === year,
					'inline-block py-1 px-3 text-gray-500 cursor-pointer no-underline': currentYear !== year,
					'focus:ring focus:ring-blue-500 rounded-sm': true,
					}"
                    @click.stop="setCurrentYear(year)"
            >{{ year }}
            </button>
        </li>
        <li v-if="includeSummary">
            <button :class="{
					'py-1 px-3 inline-block border border-blue-700 bg-blue-700 text-white no-underline shadow': currentYear === null,
					'inline-block py-1 px-3 text-gray-500 cursor-pointer no-underline': currentYear !== null,
					'focus:ring focus:ring-blue-500 rounded-sm': true,
					}"
                    @click.stop="setCurrentYear(null)"
            >Summary
            </button>
        </li>
    </ul>
</template>

<script>
  import _first from 'lodash/first';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        type: String,
        default: null,
      },
      includeSummary: {
        type: Boolean,
        default: true,
      }
    },

    methods: {
      ...mapActions({
        setYear: 'projections/setYear',
      }),
      setCurrentYear(year) {
        this.setYear(year);
      },
      updateCurrentYear() {
        this.setYear(_first(this.years));
      },
    },

    computed: {
      ...mapGetters({
        rawYears: 'projections/years',
        currentYear: 'projections/year',
      }),
      years() {
        return Object.keys(this.rawYears);
      },
    },

    mounted() {
      // this.setYear(this.value);

      if(this.years) {
        this.updateCurrentYear();
      }
    },

    watch: {
      currentYear:  {
        immediate: true,
        handler() {
          this.$emit('update:modelValue', this.currentYear)
        }
      },

      years: function() {
        this.updateCurrentYear();
      },

      modelValue() {
        this.setYear(this.modelValue);
      }
    }
  };
</script>

<style>

</style>