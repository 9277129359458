<template>
    <div class="w-full flex">
        <div class="w-full md:w-1/6 flex items-center justify-between">
            <slot name="controls"></slot>
        </div>
        <div class="w-full md:w-5/6 flex items-center justify-between">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>

<style>

</style>