<template>
    <div class="flex w-full h-full items-center justify-center bg-gray-lightest">
        <div class="w-1/4 flex justify-center bg-white shadow-md rounded">
            <form @submit.prevent="attempt" class="w-full mb-4 p-8" method="POST">
                <p class="text-2xl text-gray-darkest">Choose a new password</p>
                <div class="mb-4 mt-6">
                    <ui-label>Password</ui-label>
                    <ui-text-input
                            autocomplete="off"
                            placeholder="******" type="password" v-model="password"/>
                </div>
                <div class="mb-4">
                    <ui-label>Confirm Password</ui-label>
                    <ui-text-input
                            autocomplete="off"
                            placeholder="******" type="password" v-model="passwordConfirmation"/>
                </div>
                <div class="flex items-center justify-between">
                    <button class="btn btn-blue-200" type="submit" :disabled="loading">
                        Update Password
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import UiLabel from '@/components/ui/Label.vue';
import UiTextInput from '@/components/forms/TextInput.vue';
import {mapActions} from 'vuex';

export default {
    data() {
      return {
        loading: false,
        password: '',
        passwordConfirmation: '',
      };
    },
    methods: {
      ...mapActions({
        setUserToken: 'auth/setToken',
      }),
      attempt() {
        this.loading = true;

        axios.patch(route('api.passwordresets.complete', {
          token: this.token,
        }), {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        })
          .then(success => {
            this.setUserToken(success.data);
            this.$router.push({name: 'home'});
          })
          .finally(() => {
            this.loading = false;
          })
        ;
      },
    },
    mounted() {
      if (!this.token) {
        this.$router.push({name: 'home'});
      }
    },
    computed: {
      token() {
        return this.$route.query.token;
      },
      email() {
        return this.$route.query.email;
      },
    },
    components: {
      UiLabel,
      UiTextInput,
    },
  };
</script>

<style>

</style>