<template>
  <error :messages="errors" :name="name">
    <template v-for="option in possibleOptions">
      <div class="relative flex items-start">
        <div class="absolute flex items-center h-5">
          <radio-input :checked="option === tempValue" :modelValue="option" @update:modelValue="tempValue = $event"/>
        </div>
        <div class="pl-7 text-sm leading-5">
          <slot :option="option" name="option"/>
        </div>
      </div>
    </template>
  </error>
</template>

<script>
import RadioInput from '@/components/forms/RadioInput.vue';
import {reactive, ref, toRefs, watch} from 'vue';
import Error from '@/components/forms/Error.vue';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {
      required: false,
    },
    options: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    errors: {
      type: Array,
      default() {
        return null;
      },
    },
    name: {
      type: String,
      required: false,
    }
  },
  components: {
    RadioInput,
    Error,
  },
  setup(props, {emit}) {
    const {modelValue, options} = toRefs(props);
    const tempValue = ref(modelValue.value);
    const possibleOptions = reactive(options.value);

    watch(tempValue, () => emit('update:modelValue', tempValue.value));
    watch(modelValue, () => {
      if (modelValue) {
        tempValue.value = modelValue.value;
      }
    });

    return {
      tempValue,
      possibleOptions,
    };
  },
};
</script>

<style>

</style>