<template>
</template>

<script>
  export default {
    data() {
      return  {
        stripe: null,
        loading: null,
      }
    },
    props: {
      apiKey: {
        type: String,
        required: true,
      }
    },
    mounted() {
      this.loading = new Promise((resolve, reject) => {
        if(typeof Stripe !== 'undefined') {
          resolve(Stripe(this.apiKey))
        } else {
          let stripe = document.createElement('script')
          stripe.setAttribute('async', "true")
          stripe.setAttribute('src', 'https://js.stripe.com/v3/')
          document.body.appendChild(stripe);

          stripe.onload =  () => {
            resolve(Stripe(this.apiKey))
          }

          stripe.onerror = reject
        }
      })

      this.loading.then((stripe) => {
        this.$emit('loaded', stripe)
      })
    }
  };
</script>

<style>

</style>