import Product from './income/Product.vue';
import Subscription from './income/Subscription.vue';
import LineItem from './LineItem.vue';

import Expense from './expenditure/Expense.vue';
import Employee from './expenditure/Employee.vue';

import Equity from './income/EquityInvestment.vue';

export default {
  Product,
  Subscription,
  lineitem: LineItem,
  Employee,
  Expense,
  Equity,
};
