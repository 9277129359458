<template>
    <!--    Update your payment details page! -->
    <div class="flex justify-center">
        <div class="container px-4 py-16">
            <div class="bg-red-200 text-red-800 px-2 py-4 w-full border-2 border-red-400 rounded">
                <p>Due to a failed payment, your card details need to be updated to continue.</p>
            </div>
            <div class="mt-4">
                <div class="sm:flex mt-2" >
                    <card-input class="flex-1 max-w-md" @loaded="stripe = $event" @update:modelValue="onCardInput">
                    </card-input>
                    <primary-action class="mt-2 w-full sm:w-auto sm:mt-0 sm:ml-2" @click="updateCard">Update card</primary-action>
                </div>
                <div class="text-sm text-red-600 mt-2" role="alert">
                    <p v-if="error">{{ error.message }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import CardInput from '@/components/billing/CardInput.vue';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

  export default {
    data() {
      return {
        stripe: null,
        card: null,
        error: null,
      };
    },
    components: {
      CardInput,
      PrimaryAction,
    },
    methods: {
      updateCard() {
        // create payment method
        this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card
        }).then(({paymentMethod}) => {
          // send to api!
        })
      },
      onCardInput({card, error}) {
        this.card = card
        this.error = error
      },
    }
  };
</script>

<style>

</style>