import axios from 'axios';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import * as types from './mutation-types';

export default {
  namespaced: true,

  state: {},
  mutations: {},
  actions: {
    upsert(context, comment) {
      return axios.patch(route('api.projections.comments', { projection: context.rootGetters['projections/current'].id }), {
        ...comment,
      }).then((success) => {
        context.dispatch('projections/setProjection', success.data, { root: true });
      });
    },
  },
  getters: {
    all(state, getters, rootState, rootGetters) {
      const projection = rootGetters['projections/current'];

      if (!projection) {
        return [];
      }

      return projection.comments.data;
    },
    sections(state, getters) {
      return _keyBy(_filter(getters.all, comment => comment.type === 'section'), comment => comment.section);
    },
  },
};
