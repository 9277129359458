<template>
  <slot @update:modelValue="forgetThrottled" :messages="visibleMessages" :message="visibleMessage" :forget="forget" :has-error="visibleMessages.length > 0"></slot>
  <p class="text-red-700 text-sm mt-2" v-if="visible && visibleMessage">{{ visibleMessage }}</p>
</template>
<script>
  import _first from 'lodash/first';
  import {mapGetters, mapActions} from 'vuex';
  import _debounce from 'lodash/debounce';

  export default {
    data() {
      return {
        forgetThrottled: _debounce(this.forget, 500, {leading: true}),
        visible: true,
	    };
    },

    props: {
      name: {
        type: String,
      },
      messages: {
        type: Array,
        required: false,
      }
    },

    methods: {
      forget() {
        this.visible = false;
        this.forgetError(this.name);
      },
      ...mapActions({
        forgetError: 'forgetError',
      }),
    },

    computed: {
      ...mapGetters({
        vuexErrors: 'errors',
      }),
      visibleMessages() {
        return this.messages ?? this.vuexErrors[this.name] ?? [];
      },
      visibleMessage() {
        return _first(this.visibleMessages);
      },
    },

    watch: {
      visibleMessages: {
        handler() {
          this.visible = true;
        },
        deep: true,
      }
    }
  };

</script>
<style>

</style>