<template>
	<div>
		<text-input class="mb-4" name="name" label="Name" v-model="modelValue.name" :autofocus="focus"></text-input>
		<text-input class="mb-4" name="description" label="Job Title" v-model="modelValue.description"></text-input>
		<text-input class="mb-4" name="salary" label="Annual Base Salary" v-model.number="salary.salary.initial_value.expression" css-override="text-right">
			<template v-slot:left>
				<span class="mx-3 text-grey">£</span>
			</template>
		</text-input>
		<text-input class="mb-4" label="Pension Contribution Rate"
					name="rate"
					v-model.number="pension.rate"
					css-override="text-right"
		>
			<template v-slot:left>
				<span class="mx-3 text-grey">%</span>
			</template>
		</text-input>
		<input-label>Start Date</input-label>
		<month-picker v-model="startDate"></month-picker>
		<error name="start_date"></error>

        <div class="flex items-center mt-4">
            <input-label>Has end date?</input-label>
            <input class="leading-tight ml-2" type="checkbox" v-model="hasEndDate" />
        </div>
        <div v-if="hasEndDate">
            <input-label>End Date</input-label>
            <month-picker v-model="endDate"></month-picker>
            <error name="end_date"></error>
            <p class="my-3 text-sm text-grey-dark">The end date is inclusive. An employee leaving in June is recorded as working up until the end of June, and would therefore receive a salary for that month.</p>
        </div>
	</div>
</template>
<script>
import InputLabel from '@/components/ui/Label.vue';
import MonthPicker from '@/components/forms/MonthYearPicker.vue';
import Error from '@/components/forms/Error.vue';
import item from '../../item';
import {mapGetters} from 'vuex';
import extensions from '@/components/lineitems/extensions.mixin';
import dayjs from 'dayjs';

export default {
    mixins: [item, extensions],

    data() {
      return {
        startDate: null,
        endDate: null,
        hasEndDate: false,
        initialEndDate: null,
      };
    },

	computed: {
	  ...mapGetters({
		'projection': 'projections/current',
	  }),
	  salary() {
	    return this.fetchExtension('salary', {
	      salary: {
          initial_value: {
            expression: '',
          }
        },
		})
	  },
	  pension() {
	    return this.fetchExtension('pension', {
          rate: null,
		})
	  }
	},

    components: {
      InputLabel,
      MonthPicker,
	  Error
    },

    watch: {
      startDate() {
        this.modelValue.start_date = dayjs(this.startDate).format();
      },
      endDate: {
        handler() {
          if(this.endDate) {
            this.modelValue.end_date = dayjs(this.endDate).endOf('month').format();
          } else {
            this.modelValue.end_date = null;
          }

          this.hasEndDate = !!this.endDate;
        }
      },
      hasEndDate() {
        if(!this.hasEndDate) {
          this.endDate = null;
        } else {
          this.endDate = new Date(this.modelValue.end_date || this.initialEndDate);
        }
      },
      item: {
        immediate: true,
        deep: true,
        handler() {
          this.initialEndDate = this.modelValue.end_date;
        }
      }
    },

	// Bind values in created to ensure that there is a 'change' to make them reactive
	created() {
      if(this.modelValue.start_date) {
        this.startDate = dayjs(this.modelValue.start_date)
	  } else {
        this.startDate = dayjs(this.projection.start_date)
	  }

	  if(this.modelValue.end_date) {
	    this.endDate = dayjs(this.modelValue.end_date);
    }
	}

  };

</script>
<style>

</style>