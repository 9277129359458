import _filter from 'lodash/filter';
import _first from 'lodash/first';

export default function (item, type, template) {
  const extensions = item.extensions.data;
  const filtered = _filter(extensions, extension => extension.type === type);

  while (filtered.length > 1) {
    const extension = filtered.pop();
    extensions.splice(extensions.indexOf(extension), 1);
  }

  let extension = _first(filtered);

  if (!extension) {
    extension = { ...template, type };
    extensions.push(extension);
  }

  return extension;
}
