import {createStore} from 'vuex';
import { createLogger } from 'vuex'

import {
  state, getters, actions, mutations, modules,
} from './store';


const debug = import.meta.env.DEV;

const store = createStore({
  state,
  getters,
  actions,
  mutations,
  modules,
  strict: debug,
  plugins: debug ? [
    createLogger()
  ] : [],
});

export default store;
