import _includes from 'lodash/includes';

const fieldsForTagMap = {
  // funding: [
  //   'reference',
  //   'name',
  //   'description',
  // ],
  // rebate: [
  //   'reference',
  //   'name',
  //   'description',
  // ],
  default: [
    // 'reference',
    'name',
    'description',
  ],
  product: [

  ],
};

export default {
  computed: {
    visibleFields() {
      return fieldsForTagMap[this.modelValue.tag] || fieldsForTagMap.default;
    },
  },
  methods: {
    isVisible(field) {
      return _includes(this.visibleFields, field);
    },
  },
};
