<template>
  <div class="py-6 px-4 bg-blue-700 sm:px-6">
    <div class="flex items-center justify-between">
      <h2 id="slide-over-heading" class="text-lg font-medium text-white">
        Projection Settings
      </h2>
      <div class="ml-3 h-7 flex items-center">
        <button
            type="button"
            class="bg-blue-700 rounded-md text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            @click.prevent="$emit('close')"
        >
          <span class="sr-only">Close panel</span>
          <!-- Heroicon name: x -->
          <svg aria-hidden="true" class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
        </button>
      </div>
    </div>
    <div class="mt-1" v-if="$slots.subtitle">
      <p class="text-sm text-blue-300">
        <slot name="subtitle"/>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>

</style>