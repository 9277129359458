<template>
    <div>
        <h2 class="text-blue-200">{{ label }}</h2>
        <div class="my-2">
            <div class="shadow-md px-4 py-4 bg-white">
                <div class="overflow-x-auto overflow-y-visible pb-4">
                    <table>
                        <thead class="bg-transparent text-grey focus:text-grey-darkest text-center w-full placeholder-grey-light">
                        <tr class="w-full overflow-hidden" ref="headers" v-if="Object.keys(years).length > 1">
                            <th class="sticky left-0 bg-white z-10 p-3"></th>
                            <template v-for="(months, year) in years">
                                <th class="font-semibold text-sm sticky-column sm:no-sticky-column" colspan="2">
                                    <div class="border-l-2 border-gray-400 px-2 py-1 bg-gray-100 middle text-left">{{
                                        year }}
                                    </div>
                                </th>
                                <th colspan="10">
                                    <div class="bg-grey-lightest"></div>
                                </th>
                            </template>
                        </tr>
                        <tr>
                            <th class="sticky left-0 bg-white z-10"></th>
                            <template v-for="(months, year) in years">
                                <th class="font-normal" v-for="month in months">{{ month.label }}</th>
                            </template>
                        </tr>
                        </thead>
                        <sortable-list :handle-class="'line-item-handle'"
                                       v-model="sortedItems"
                                       @moved="updateLineItemOrderForKey">
                        <tbody>
                                <line-item-row
                                        v-for="(item, key) in sortedItems"
                                        :currency="!discreteDataEntry ? '£' : ''"
                                        :disabled="item.disabled"
                                        :display-currency="!item.discrete"
                                        :duplicate="onDuplicate(item, items)"
                                        :headers="key === 0"
                                        :initial-line-item="item"
                                        :remove="onRemove(item)"
                                        v-sortable-item
                                        :key="item.id"
                                ></line-item-row>
                        </tbody>
                        </sortable-list>
                        <tbody>
                        <totals-row :currency="!discreteDataEntry ? '£' : ''"
                                    :key="contextId"
                                    :totals="totals"
                        ></totals-row>
                        </tbody>
                    </table>
                </div>
                <div :id="`item-config-${item.id}`" v-for="item in items"></div>
            </div>
        </div>
    </div>
</template>
<script>
import TotalsRow from './TotalsRow.vue';
import LineItemRow from './LineItemRow.vue';
import SortableList from '@/components/ui/SortableList.vue';
import SortableItem from '@/components/ui/SortableItem.vue';

import {mapActions, mapGetters} from 'vuex';
import _clone from 'lodash/clone';
import _cloneDeep from 'lodash/cloneDeep';
import _map from 'lodash/map';
import _each from 'lodash/each';
import Lexisort from '@/app/lexisort';
import {month as formatMonth} from '@/app/filters';
import sortableItem from '@/components/ui/sortable-item';

const lexi = Lexisort();

export default {
    directives: {
      sortableItem,
    },
    data() {
      return {
        sortedItems: null,
        sortableContainer: null,
      };
    },

    props: {
      discreteDataEntry: {
        type: Boolean,
        required: true,
      },
      label: {
        type: String,
        required: false,
      },
      items: {
        type: Array,
      },
      // totals: {
      //   type: Array,
      // },
      contextId: {
        type: String,
      },
    },

    methods: {
      formatMonth,
      onDuplicate(item, category) {
        return () => {
          // clone item with new id
          let duplicate = _cloneDeep(item);
          duplicate.id = null;
          duplicate.copied_from_id = item.id;
          duplicate.sort_key = lexi.append(this.items, duplicate);

          this.upsertLineItem(duplicate);
        };
      },
      onRemove(item) {
        return () => {
          this.deleteLineItem(item);
        };
      },
      ...mapActions({
        upsertLineItem: 'projections/lineItems/upsert',
        updateLineItems: 'projections/lineItems/updateMany',
        deleteLineItem: 'projections/lineItems/delete',
      }),
      updateLineItemOrderForKey(newKey) {
        let keys = lexi.sort(this.sortedItems, newKey, (item) => item.sort_key);

        Promise.all(_map(keys, (sortKey, index) => {
          let item = _cloneDeep(this.sortedItems[index])
          item.sort_key = sortKey;

          return this.upsertLineItem(item);
        }))
      },
    },

    computed: {
      ...mapGetters({
        projection: 'projections/current',
        lineItemTotals: 'lineItemTotals',
      }),

      years() {
        return this.projection.periods;
      },

      totals() {
        return this.lineItemTotals(this.items.map(item => item.id));
      },
    },

    watch: {
      items: {
        immediate: true,
        deep: true,
        handler(items) {
          let keys = lexi.ensure(items, (item) => item.sort_key)

          if(Object.values(keys).length > 0) {
            let toUpdate = [];
            _each(keys, ((sortKey, index) => {
              let current = _cloneDeep(items[index]);
              current.sort_key = sortKey;
              toUpdate.push(current);
            }));

            this.updateLineItems(toUpdate);
          }

          // TODO - this may be duplicated in lexisort.js
          this.sortedItems = _clone(items).sort((a, b) => {
            if(!(a.sort_key || b.sort_key)) {
              return 0;
            }

            if(a.sort_key && !b.sort_key) {
              return -1;
            }

            if(!a.sort_key && b.sort_key) {
              return 1;
            }

            return (a.sort_key).localeCompare(b.sort_key)
          });
        },
      },
    },

    components: {
      LineItemRow,
      TotalsRow,
      SortableList,
      SortableItem,
    },
  };

</script>
<style>

</style>