import jstz from 'jstimezonedetect';
import * as types from './mutation-types';
import {rates as vatRates} from '@/app/vat.js';

// Modules
import auth from './auth';
import projections from './projections';
import clients from './clients';
import organisations from './organisations';

const modules = {
  auth,
  projections,
  clients,
  organisations,
};

const state = {
  timezone: jstz.determine().name(),
  route: null,
  // vatRates: {
  //   standard: 0.20,
  //   reduced: 0.05,
  //   zero: 0.00,
  // },
  vatRates,
  lineItemPeriodTotals: {},
  errors: {},
  notifications: [],
};


const getters = {
  timezone: state => state.timezone,
  route(state) {
    return state.route;
  },
  projection(state) {
    return state.projection;
  },
  vatRates: state => state.vatRates,
  errors: state => state.errors,
  lineItemPeriodTotals: state => state.lineItemPeriodTotals,
  lineItemTotals: state => ids => ids.map(
    id => state.lineItemPeriodTotals[id],
  ),
  notifications: state => state.notifications.filter(notification => !!notification),
};
const actions = {
  setRoute(store, route) {
    store.commit(types.SET_CURRENT_ROUTE, route);
  },
  setLineItemPeriodTotals(store, totals) {
    store.commit(types.SET_LINE_ITEM_PERIOD_TOTALS, totals);
  },
  setErrors(store, errors) {
    store.commit(types.SET_ERRORS, errors);
  },
  forgetError(store, errorName) {
    store.commit(types.FORGET_ERROR_KEY, errorName);
  },
  forgetErrors(store) {
    store.commit(types.FORGET_ALL_ERRORS);
  },
  notify(store, notification) {
    setTimeout(() => {
      store.commit(types.ADD_NOTIFICATION, {id: Date.now(), notification});
    }, 200)
  },
  clearNotifications(store) {
    store.commit(types.CLEAR_NOTIFICATIONS);
  }
};


const mutations = {
  [types.SET_CURRENT_ROUTE](state, route) {
    state.route = route;
  },
  [types.SET_LINE_ITEM_PERIOD_TOTALS](state, { lineItem, totals }) {
    state.lineItemPeriodTotals[lineItem.id] = totals;
  },

  [types.FORGET_ERROR_KEY](state, key) {
    delete state.errors[key];
  },
  [types.SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [types.FORGET_ALL_ERRORS](state, errors) {
    state.errors = {};
  },
  [types.ADD_NOTIFICATION](state, {id, notification}) {
    state.notifications.push(notification);
  },
  [types.CLEAR_NOTIFICATIONS](state) {
    state.notifications = [];
  }
};

export {
  state,
  getters,
  actions,
  mutations,
  modules,
};
