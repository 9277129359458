<template>
    <teleport to="body">
        <modal :modelValue="true">
            <div class="bg-white px-8 py-8 rounded-sm w-full max-w-md">
                <div v-show="!updatingCard">
                    <h2 class="text-lg">Payment error</h2>
                    <p class="text-sm" v-if="lastInvoiceId">Your card was recently declined. Please ensure you have sufficient funds and retry, or manually update your card details to continue your subscription.</p>
                    <p class="text-sm" v-else>Your card was recently declined. Please manually update your card details to continue your subscription.</p>
                    <div class="mt-4 flex justify-between">
                        <primary-action @click="retryCard" :disabled="loading" v-if="lastInvoiceId">Retry</primary-action>
                        <primary-action @click="updatingCard = true" :disabled="loading" v-if="!lastInvoiceId">Update card</primary-action>
                        <secondary-action @click="updatingCard = true" :disabled="loading" v-else>Update card</secondary-action>
                    </div>
                </div>
                <div v-if="updatingCard">
                    <h2 class="text-lg">Update card</h2>
                    <card-input class="mt-2" @loaded="stripe = $event" @update:modelValue="onCardInput"></card-input>
                    <p class="text-sm text-red-600 mt-2" role="alert" v-if="error">{{ error.message }}</p>
                    <div class="flex justify-between mt-4">
                        <primary-action class="w-full" @click="saveCard" :disabled="(!card) || !!error || loading">Save</primary-action>
                        <secondary-action @click="updatingCard = false" :disabled="loading">Cancel</secondary-action>
                    </div>
                </div>
            </div>
        </modal>
    </teleport>
</template>

<script>
  import Modal from '@/components/Modal.vue';
  import CardInput from '@/components/billing/CardInput.vue';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
  import SecondaryAction from '@/components/buttons/SecondaryAction.vue';


  export default {
    data() {
      return {
        loading: false,
        updatingCard: false,
        stripe: null,
        card: null,
        error: null,
      };
    },

    props: {
      lastInvoiceId: {
        type: String,
        required: false,
      }
    },

    components: {
      Modal,
      CardInput,
      PrimaryAction,
      SecondaryAction,
    },

    methods: {
      onCardInput({card, error}) {
        this.card = card;
        this.error = error;
      },
      async saveCard() {
        this.loading = true;

        let {paymentMethod} = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card
        })

        axios.put(route('api.cards.store'), {
          payment_method: paymentMethod.id,
        }).then(response => {
          // get organisation back and update the store
          // if still needs to create payment method then show error message saying card was declined
        }).finally(() => {
          this.loading = false;
        })
      },
      retryCard() {
        // POST to invoices/{id}/attempts
        // if successful, update organisation billing status & carry on merrily
        axios.post(route('api.invoices.attempts.store', {id: this.lastInvoiceId}))
            .then(result => {
              console.log(result);
            })
      }
    }
  };
</script>

<style>

</style>