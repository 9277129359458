<template>
    <span ref="popper" v-show="open || !loaded" >
        <slot></slot>
    </span>
</template>
<script>
import Popper from 'popper.js';
import _last from 'lodash/last';

export default {
    data() {
      return {
        loaded: false,
        width: 0,
        height: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        position: null,

        popper: null,
      };
    },

    props: {
      parent: {
        required: true,
      },
      open: {
        type: Boolean,
        required: true,
      },
      pin: {
        type: String,
        default: "auto"
      },
      cssOverrides: {
        type: String,
        default: "",
      }
    },

    computed: {
      dropdownClass() {
        var classes = {
          'left-0': this.pin === "left",
          'right-0': this.pin === "right",
          'top-0': this.pin === "top",
          'bottom-0': this.pin === "bottom",
        }

        classes['rounded shadow-md absolute border-indigo border-t-4 bg-white z-10 ' + this.cssOverrides] = true;
        return classes;
      },
      style() {
        return {
          width: this.width + 'px',
          height: this.height + 'px',
          top: this.top + 5 + 'px',
          right: this.right + 5 + 'px',
          bottom: this.bottom + 5 + 'px',
          left: this.left + 5 + 'px',
          position: this.position,
        }
      }
    },

    methods: {
      applyStyle(args) {
        let style = args.popper;

        // if (this.width === 0) {
          this.width = style.width;
          this.height = style.height;
        // }

        this.top = style.top;
        this.right = style.right;
        this.bottom = style.bottom;
        this.left = style.left;
        this.position = style.position;
      },

      configurePopper(parent) {
        this.popper = new Popper(parent, this.$refs.popper.children[0], {
          placement: this.pin,
          modifiers: {
            flip: {
              enabled: false
            },
            preventOverflow: {
              escapeWithReference: true
            }
          }
        })
      },
    },

    mounted() {
      let slot = _last(this.$slots.default()).elm;
      if(slot) {
        this.width = slot.clientWidth;
        this.height = slot.clientHeight;
      }

      this.loaded = true;

      if(this.parent) {
        this.configurePopper(parent)
      }
    },

    watch: {
      parent(parent) {
        if(parent) {
          this.$nextTick(() => {
            this.configurePopper(parent)
          })
        }
      },
      open() {
        this.popper?.update();
      }
    }
  };

</script>
<style>

</style>