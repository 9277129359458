<template>
	<div class="flex text-grey-dark">
		<div class="m-1 border-2 border-grey-light rounded-full p-2 flex content-center justify-center bg-white">
			Owner
		</div>
		<div class="m-1 border-2 border-grey-light rounded-full p-2 flex content-center justify-center bg-white">Read /
			Write
		</div>
	</div>
</template>
<script>

  export default {
    data() {
      return {};
    },

    props: {},

    computed: {},

    components: {},

    mounted() {

    },
  };

</script>
<style>

</style>