<template>
  <div>
    <div class="mt-1 relative">
      <text-input placeholder="Type to filter..." v-model="search" class="shadow-2xl" @keypress.enter="() => select()" ref="inputRef" :autofocus="true">
        <template v-slot:right="{value}">
          <svg class="icon-close-circle cursor-pointer w-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="value && value.length > 0" @click="clear">
            <circle class="fill-current text-gray-400" cx="12" cy="12" r="10"/>
            <path class="fill-current text-gray-50"
                  d="M13.41 12l2.83 2.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 1 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12z"/>
          </svg>
        </template>
      </text-input>
      <div class="mt-1 w-full rounded-md bg-white shadow-2xl">
        <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-96 rounded-md text-base ring-1 ring-blue-700 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <li role="option" :class="{'cursor-pointer select-none relative py-3 pl-3 pr-9': true, 'text-gray-900 ': !isSelected(option), 'text-white bg-blue-600': isSelected(option)}" v-for="option in filteredResults" @click="select(option)" @mouseover="hover(option)" @mouseleave="unhover(option)">
            <slot name="option" :option="option.item" :selected="isSelected(option)"></slot>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import TextInput from '@/components/forms/TextInput.vue';
import Fuse from 'fuse.js';
import {computed, reactive, ref, watchEffect} from 'vue';

export default {
  props: {
    options: {
      type: Array,
    },
    searchOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props, {emit}) {
    let fuse = new Fuse(props.options, props.searchOptions);

    watchEffect(() => {
      fuse = new Fuse(props.options, props.searchOptions);
    })

    const search = ref(null);
    const hovered = ref(null);

    const hover = (option) => {
      hovered.value = option;
    }

    const unhover = () => {
      hovered.value = null;
    }

    const filteredResults = computed(() => {
      if(search.value) {
        unhover();
        return fuse.search(search.value)
      }

      return reactive(props.options.map((option, key) => {
        return {item: option, refIndex: key}
      }))
    })

    hovered.value = filteredResults.value[0];

    const selected = computed(() => {
      return hovered.value ?? filteredResults.value[0];
    })

    const isSelected = (option) => {
      return selected.value === option;
    }

    const select = (option = null) => {
      let type = option;

      if(!option) {
        type = selected.value
      }

      if(!type) {
        return;
      }

      emit('update:modelValue', type.item);
    }

    const clear = () => {
      search.value = null;
      inputRef.value?.$el?.focus();
    }

    const inputRef = ref(null);

    return {
      search,
      filteredResults,
      isSelected,
      hovered,
      hover,
      unhover,
      selected,
      select,
      clear,
      inputRef,
    }
  },
  components: {
    TextInput,
  }
}
</script>

<style>

</style>