<template>
  <Teleport to="body">
    <transition name="fade-shrink-in">
      <div v-show="modelValue"
           :class="{
            'fixed w-screen h-screen left-0 top-0 p-2 sm:p-4 z-40 flex flex-col items-center content-center': true,
            'justify-center': this.middle,
         }"
           style="background-color: hsla(0, 0%, 0%, .5)"
           @click.self="close">
        <!--      <div class="relative w-auto">-->
        <!--        <div class="absolute top-0 right-0 -mr-2 -mt-2 bg-gray-700 bg-opacity-75 text-white rounded-full">-->
        <!--          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">-->
        <!--            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />-->
        <!--          </svg>-->
        <!--        </div>-->
        <!--      </div>-->
        <slot @click.stop></slot>
      </div>
    </transition>
  </Teleport>
</template>
<script>
import {onBeforeUnmount} from 'vue';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {
      type: Boolean,
    },
    middle: {
      type: Boolean,
      default: true,
    }
  },

  setup(props, {emit}) {
    const close = () => {
      emit('update:modelValue', false);
    }

    const closeOnEscape = (event) => {
      if(event.key === "Escape") {
        close();
      }
    }

    document.addEventListener('keyup', closeOnEscape);

    onBeforeUnmount(() => {
      document.removeEventListener('keyup', closeOnEscape);
    })

    return {
      close,
    }
  },
};

</script>
<style>
.fade-shrink-in-enter-active,
.fade-shrink-in-leave-active {
  transition: all .3s ease;
}

.fade-shrink-in-enter-from,
.fade-shrink-in-leave-to {
  opacity: 0;
  transform: scale(1.1);
}
</style>