<template>
    <router-link :to="route" :class="css">
        <slot></slot>
    </router-link>
</template>

<script>
  export default {
    data() {
      return {};
    },
    props: {
      route: {
        type: Object,
      },
      vertical: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      css() {
        return {
          'px-3 py-3 text-white hover:bg-gray-700 rounded-sm flex items-center group' : true,
          'flex-col' : this.vertical,
        }
      },
    }
  };
</script>

<style scoped>
    .router-link-active, .router-link-active:hover {
        @apply bg-gray-400 text-gray-900;

        & >>> .primary {
            @apply text-gray-900;
        }

        & >>> .secondary {
            @apply text-blue-600;
        }
    }
</style>