<template>
    <div class="relative">
        <select class="block w-full appearance-none bg-gray-100 border border-gray-300 py-2 px-4 pr-8 rounded focus:outline-none focus:bg-white focus:border-grey"
                :value="modelValue"
                @change="$emit('update:modelValue', $event.target.value)">
            <option :value="option" v-for="option in options">
                <slot :option="option" name="option"></slot>
            </option>
        </select>
        <div class="pointer-events-none absolute top-0 mt-3 right-0 flex items-center px-2">
<!--            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>-->
<!--            </svg>-->
        </div>
    </div>
</template>

<script>
  export default {
    compatConfig: { COMPONENT_V_MODEL: false },
    props: {
      modelValue: {
        default: null,
      },
      options: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style>

</style>