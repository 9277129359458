export const SET_PROJECTION = 'SET_PROJECTION';

export const SET_LINE_ITEM_PERIOD_TOTALS = 'SET_LINE_ITEM_PERIOD_TOTALS';

export const FORGET_ERROR_KEY = 'FORGET_ERROR_KEY';
export const SET_ERRORS = 'SET_ERRORS';
export const FORGET_ALL_ERRORS = 'FORGET_ALL_ERRORS';

export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export * from './auth/mutation-types';
export * from './projections/mutation-types';
export * from './projections/line-items/mutation-types';
