<template>
  <slide-over :open="open && !destroying">
    <form @submit.prevent="save" class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div class="flex-1 h-0 overflow-y-auto">
        <slide-over-primary-header @close="navigateToProjectionHome">
          Projection Settings
          <template v-slot:subtitle>Customise your projection and the output of your report pages and downloadable exports.</template>
        </slide-over-primary-header>
        <div class="flex-1 flex flex-col justify-between">
          <!-- Divider container -->
          <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
            <!-- Project name -->
            <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <ui-label class="pt-2">Projection name</ui-label>
              </div>
              <div class="sm:col-span-2">
                <text-input class="text-sm" v-model="formSettings.name" :errors="errors.name" name="name"/>
              </div>
            </div>
            <fieldset>
              <div
                  class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-6">
                <div>
                  <legend class="text-sm leading-5 font-medium text-gray-900">
                    Reporting Frequency
                  </legend>
                </div>
                <div class="sm:col-span-2">
                  <stacked-radio-group
                      @update:modelValue="setReportingFrequency"
                      :modelValue="reportingFrequency"
                      :options="reportingFrequencyOptions"
                      class="space-y-5 sm:mt-0"
                      name="reporting_frequency"
                      :errors="errors.reporting_frequency"
                  >
                    <template v-slot:option="{option}">
                      <ui-label>{{ option.label }}</ui-label>
                      <p class="text-gray-500">
                        {{ option.description }}
                      </p>
                    </template>
                  </stacked-radio-group>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-6">
                <ui-label>Default Debtor Terms</ui-label>
                <Duration class="sm:col-span-2" v-model="formSettings.configuration.default_debtor_term"/>
              </div>
              <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-6">
                <ui-label>Default Creditor Terms</ui-label>
                <Duration class="sm:col-span-2" v-model="formSettings.configuration.default_creditor_term"/>
              </div>
            </fieldset>
            <fieldset>
              <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-6">
                <div>
                  <label for="annual_salary_increase_percent" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                    Default Annual Salary Increase
                  </label>
                </div>
                <div class="sm:col-span-2">
                  <text-input id="annual_salary_increase_percent" name="annual_salary_increase_percent" v-model="formSettings.configuration.annual_salary_increase_percent"  placeholder="" css-override="text-right flex-shrink-1">
                    <template v-slot:right>
                      <div class="text-grey">%</div>
                    </template>
                  </text-input>
                </div>
                <div class="col-span-3 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                  <div>
                    <label for="auto_generate_salary_tweaks_enabled" class="block text-sm font-medium text-gray-900">
                      Apply to New Employees
                    </label>
                  </div>
                  <div class="col-span-2 flex items-center">
                    <toggle-input name="auto_generate_salary_tweaks_enabled" id="auto_generate_salary_tweaks_enabled" v-model="formSettings.configuration.auto_generate_salary_tweaks_enabled"/>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-6">
                <ui-label>VAT Registration Date</ui-label>
                <DatePicker class="sm:col-span-2" v-model="vatRegistrationDate"></DatePicker>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0 px-4 py-4 flex justify-end">
        <button @click.prevent="cancel" type="button" class="mr-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Cancel
        </button>
        <primary-action type="submit">
          Save
        </primary-action>
      </div>
    </form>
  </slide-over>
</template>

<script>
import SlideOver from '@/components/ui/SlideOver.vue';
import SlideOverPrimaryHeader from '@/components/ui/SlideOverPrimaryHeader.vue';
import {projection} from '@/types.js';
import {computed, reactive, ref, watch} from 'vue';
import _cloneDeep from 'lodash/cloneDeep.js';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import TextInput from '@/components/forms/TextInput.vue';
import UiLabel from '@/components/ui/Label.vue';
import RadioInput from '@/components/forms/RadioInput.vue';
import StackedRadioGroup from '@/components/forms/radio-groups/StackedRadioGroup.vue';
import ToggleInput from '@/components/forms/ToggleInput.vue';
import store from '@/store';
import _find from 'lodash/find';
import useValidationErrors from '@/app/validation_errors.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useRouter} from 'vue-router';
import Duration from '../../components/ui/Duration.vue';
import DatePicker from '../../components/ui/DatePicker.vue';

dayjs.extend(utc);
// import apiCallMachine from '@/app/api_call_machine.js';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    projection: projection.isRequired,
  },
  data() {
    return {
      destroying: false,
    }
  },
  setup(props, {emit}) {
    const router = useRouter();

    const reportingFrequencyOptions = [
      {label: 'Monthly', description: 'Calculation results will be grouped by month.', value: 'P1M'},
      {label: 'Quarterly', description: 'Calculation results will be rolled up to the calendar quarter.', value: 'P3M'}
    ];

    const formSettings = reactive(_cloneDeep(props.projection));
    const vatRegistrationDate = ref(dayjs.utc(formSettings.configuration.vat_registration_date).toDate());

    watch(vatRegistrationDate, (newValue) => {
      formSettings.configuration.vat_registration_date = newValue ? dayjs(newValue).format() : null;
    });

    const setReportingFrequency = (selected) => {
      formSettings.configuration.reporting_frequency = _find(reportingFrequencyOptions, (possible) => possible.value === selected.value).value
    }

    const reportingFrequency = computed(() => _find(reportingFrequencyOptions, (possible) => possible.value === formSettings.configuration.reporting_frequency))

    const navigateToProjectionHome = () => {
      router.back();
    }

    const {errors, errorHandler} = useValidationErrors();
    // const apiMachine = apiCallMachine();

    const cancel = navigateToProjectionHome;

    const save = () => {
      // apiMachine.send("CALL");
      store.dispatch('projections/upsert', formSettings).then(() => {
        emit('clear');
        emit('notify', {
          body: 'Updated the projection',
          type: 'success',
        })
        // apiMachine.send("SUCCESS");
        navigateToProjectionHome();
      }).catch((error) => {
        emit('notify', {
          body: 'There was an error with the request',
          type: 'error',
        })
        // apiMachine.send("ERROR");
        errorHandler(error);
      })
    }

    return {
      reportingFrequencyOptions,
      formSettings,
      setReportingFrequency,
      navigateToProjectionHome,
      reportingFrequency,
      vatRegistrationDate,

      cancel,
      save,

      errors,

      // apiMachine,
    };
  },

  components: {
    DatePicker,
    Duration,
    PrimaryAction,
    SlideOver,
    SlideOverPrimaryHeader,
    TextInput,
    UiLabel,
    RadioInput,
    StackedRadioGroup,
    ToggleInput,
  },
}
</script>

<style>

</style>