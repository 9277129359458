<template>
    <div class="text-sm" style="overflow-x: auto;" v-show="loaded">
        <sales-plan-year-table :sales-plan="statement" :year="year" :years="years"
                               :reporting-period="currentReportingPeriod"
                               v-if="currentYear"></sales-plan-year-table>
        <statement-annual-summary :balances="statement.balances" :years="years" :labels="labels" v-else/>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import SalesPlanSection from './SalesPlanSection.vue';
import _mapValues from 'lodash/mapValues';
import _find from 'lodash/find';
import moment from 'moment';
import labels from '@/components/reports/labels';

import SalesPlanYearTable from './SalesPlanYearTable.vue';
import StatementAnnualSummary from './StatementAnnualSummary.vue';
import {useCache} from '../projections/cache/cache.js';
import {onBeforeUnmount} from 'vue';

export default {
    data() {
      return {
        sections: [],
        statement: {},
        yearIndex: 0,
      };
    },

    computed: {
      labels() {
        return labels.salesPlan;
      },
      year() {
        return this.projection.reporting_periods.data.find(period => period.label === this.currentYear)
      },
      years() {
        return this.projection.reporting_periods.data;
      },
      periods() {
        return _mapValues(this.projection.periods[this.year], month => {
          return moment(month);
        });
      },
      currentReportingPeriod() {
        return _find(this.projection.reporting_periods.data, period => period.label === this.year.label);
      },
      ...mapGetters({
        projection: 'projections/current',
        currentYear: 'projections/year',
        user: 'auth/user',
      }),
      loaded() {
        return Object.keys(this.statement).length > 0;
      },
    },

    methods: {
      refresh() {
        return axios.get(route('api.projections.salesplan', {
          projection: this.projection.id,
          tenant: this.user.organisation.id,
        }), {
          year: this.yearIndex,
        }).then(
            success => {
              this.statement = success.data;
            },
            error => {
              console.error(error);
            },
        );
      }
    },

    created() {
      const {subscribe, stop} = useCache(this.projection);
      const unsubscribe = subscribe((resolve, reject) => {
        this.refresh().then(resolve).catch(reject);
      });

      this.refresh();

      onBeforeUnmount(unsubscribe);
    },

    components: {
      SalesPlanYearTable,
      SalesPlanSection,
      StatementAnnualSummary,
    },
  };

</script>
<style scoped>
    table {
        border-spacing: 0;
    }

    /*tr:not(.no-bg):hover {*/
    /*background-color: rgba(249, 249, 249, 1);*/
    /*}*/
</style>