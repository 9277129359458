<template>
    <form @submit.prevent="submit">
        <h2 class="text-xl text-blue-900">Account Settings</h2>
        <p class="text-gray-800 text-sm">Default settings for your organisation</p>
        <div class="mt-4 bg-white rounded w-full md:w-2/3 lg:w-1/2">
            <div class="flex flex-col">
                <ui-label>Name</ui-label>
                <text-input name="name" v-model="form.name"></text-input>
            </div>
            <template v-if="can('list', 'projections')">
                <h2 class="mt-10 text-xl">Projection Defaults</h2>
                <div class="mt-4">
                    <ui-label>Default Debtor Terms</ui-label>
                    <div class="flex w-full">
                        <ui-duration v-model="form.default_projection_configuration.default_debtor_term"/>
                    </div>
                </div>
                <div class="mt-4">
                    <ui-label>Default Creditor Terms</ui-label>
                    <ui-duration v-model="form.default_projection_configuration.default_creditor_term"/>
                </div>
                <div class="mt-4">
                    <ui-label>VAT Registration Date</ui-label>
                    <ui-date-picker v-model="vatRegistrationDate"></ui-date-picker>
                </div>
                <div class="mt-4 w-auto">
                    <ui-label>Default Annual Salary Increase</ui-label>
                    <text-input v-model="form.default_projection_configuration.annual_salary_increase_percent" name="annual_salary_increase_percent" placeholder="" css-override="text-right flex-shrink-1">
                      <template v-slot:right>
                        <div class="text-grey">%</div>
                      </template>
                    </text-input>
                </div>
                <div class="mt-4 w-auto">
                  <div class="col-span-3 space-y-2 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                    <div>
                      <label for="auto_generate_salary_tweaks_enabled" class="block text-sm font-medium text-gray-900">
                        Apply to New Employees
                      </label>
                    </div>
                    <div class="col-span-2 flex items-center">
                      <toggle-input name="auto_generate_salary_tweaks_enabled" id="auto_generate_salary_tweaks_enabled" v-model="form.default_projection_configuration.auto_generate_salary_tweaks_enabled"/>
                    </div>
                  </div>
                </div>
            </template>
            <div class="mt-8 flex w-full">
                <primary-action :disabled="loading">Save</primary-action>
            </div>
        </div>
    </form>
</template>

<script>
import TextInput from '@/components/forms/TextInput.vue';
import UiLabel from '@/components/ui/Label.vue';
import UiDuration from '@/components/ui/Duration.vue';
import UiMonthPicker from '@/components/forms/MonthYearPicker.vue';
import UiDatePicker from '@/components/ui/DatePicker.vue';
import UiButton from '@/components/ui/Button.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import ToggleInput from '@/components/forms/ToggleInput.vue';
import Error from '@/components/forms/Error.vue';

import {useStore} from 'vuex';
import {loggedInUser} from '../../routes/utils';
import {computed, ref, watch} from 'vue';
import {onBeforeRouteUpdate} from 'vue-router';
import _cloneDeep from 'lodash/cloneDeep.js';
import dayjs from 'dayjs';

export default {
    // data() {
    //   return {
    //     organisation: null,
    //     vatRegistrationDate: null,
    //     loading: false,
    //   };
    // },
    // created() {
    //   this.fetch(this.user.organisation.id);
    // },
    // beforeRouteUpdate(to, from, next) {
    //   this.fetch(this.user.organisation.id);
    //   next();
    // },
    // computed: {
    //   ...mapGetters({
    //     user: 'auth/user',
    //     initialOrganisation: 'organisations/current',
    //   }),
    // },
    // methods: {
    //   ...mapActions({
    //     fetch: 'organisations/fetch',
    //     update: 'organisations/update',
    //   }),
    //   can(action, resource) {
    //     return loggedInUser() ? loggedInUser().can(action, resource) : false;
    //   },
    //   submit() {
    //     this.loading = true;
    //     this.update(this.organisation).finally(() => {
    //       this.loading = false;
    //     })
    //   },
    //   setVatDate() {
    //     if(!this.vatRegistrationDate) {
    //       this.organisation.vat_registration_date = null;
    //       return;
    //     }
    //
    //     this.organisation.vat_registration_date = moment(this.vatRegistrationDate).format()
    //   }
    // },
    // watch: {
    //   initialOrganisation: {
    //     immediate: true,
    //     handler() {
    //       this.organisation = _cloneDeep(this.initialOrganisation)
    //       let vatDate = this.organisation.vat_registration_date;
    //       this.vatRegistrationDate = vatDate ? moment(vatDate).toDate() : null;
    //     },
    //   },
    // },
    components: {
      TextInput,
      UiLabel,
      UiDuration,
      UiMonthPicker,
      UiDatePicker,
      UiButton,
      PrimaryAction,
      Error,
      ToggleInput,
    },
    setup(props) {
      const store = useStore();

      const user = computed(() => store.getters['auth/user']);
      const organisation = computed(() => store.getters['organisations/current']);

      const form = ref(_cloneDeep(organisation.value));
      watch(organisation, () => {
        form.value = _cloneDeep(organisation.value);
      });

      const vatRegistrationDate = ref(dayjs.utc(form.value.default_projection_configuration.vat_registration_date).toDate());

      watch(vatRegistrationDate, (newValue) => {
        form.value.default_projection_configuration.vat_registration_date = newValue ? dayjs(newValue).format() : null;
      });
      const loading = ref(false);

      const fetch = (organisation) => store.dispatch('organisations/fetch', organisation);
      const update = (organisation) => store.dispatch('organisations/update', organisation);

      // fetch current org into store
      fetch(user.value.organisation.id);

      onBeforeRouteUpdate((to, from, next) => {
        fetch(user.value.organisation.id);
        next();
      });

      const can = (action, resource) => {
        return loggedInUser() ? loggedInUser().can(action, resource) : false;
      };

      const submit = () => {
        loading.value = true;
        update(form.value).finally(() => {
          loading.value = false;
        })
      };

      return {
        form,
        user,
        organisation,
        vatRegistrationDate,
        loading,
        fetch,
        update,
        can,
        submit,
      };
    },
  };
</script>

<style>

</style>