import axios from 'axios';
import store from '@/store';
import router from '@/router';

export default function () {
  store.dispatch('auth/load');

  axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${store.getters['auth/token']}`;

    return config;
  });

  axios.interceptors.response.use(response => response, (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 419)) {
      console.error(error);
      let { path, query } = router.currentRoute;

      // attempt to get new token
      store.dispatch('auth/clearToken');

      if (store.getters['auth/user']) {
        return;
      }


      let newQuery = {
        redirect: query?.redirect,
      };

      if (!query?.redirect) {
        if (router.currentRoute.name === 'auth.login') {
          path = null;
        }

        newQuery = {
          redirect: encodeURI(path),
        };

        if (!path) {
          delete newQuery.redirect;
        }
      }

      router.push({ name: 'auth.login', query: newQuery });
    }

    throw error;
  });

  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.protected)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters['auth/user']) {
        next({ name: 'auth.login', query: { redirect: encodeURI(to.path) } });
      } else {
        next();
      }
    } else {
      next(); // make sure to always call next()!
    }
  });
}
