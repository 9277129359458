import store from '@/store';

export default function (to, from, next) {
  const organisation = store.getters['organisations/current'];
  // const params = {tenant: organisation.id}
  const status = organisation.billing?.status;
  switch (status) {
    case 'requires_payment_details':
      next();
      break;
    case 'requires_action':
      next();
      break;
    default:
      next();
      break;
  }
}
