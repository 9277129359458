import _findIndex from 'lodash/findIndex';
import _each from 'lodash/each';
import * as types from './mutation-types';
import analytics from '@/app/analytics.js';

export default {
  namespaced: true,

  state: {
    onRefresh: [],
  },
  mutations: {
    [types.ADD_ON_REFRESH](state, callback) {
      state.onRefresh.push(callback);
    },
    [types.ADD_LINE_ITEM](state, { projection, lineItem }) {
      projection.lineItems.data.push(lineItem);
    },
    [types.UPDATE_LINE_ITEM](state, { projection, lineItem }) {
      const lineItems = projection.lineItems.data;

      const index = _findIndex(lineItems, item => item.id === lineItem.id);

      lineItems[index] = lineItem;
    },
    [types.DELETE_LINE_ITEM](state, { projection, lineItem }) {
      const lineItems = projection.lineItems.data;

      const index = _findIndex(lineItems, item => item.id === lineItem.id);

      lineItems.splice(index, 1);
    },
    [types.UPDATE_MANY_LINE_ITEMS](state, { projection, lineItems }) {
      const existing = projection.lineItems.data;

      _each(lineItems, (lineItem) => {
        const index = _findIndex(existing, item => item.id === lineItem.id);

        existing[index] = lineItem;
      });
    },
    [types.UPDATE_LINE_ITEM_PERIOD](state, {projection, lineItem, index, period }) {
      const lineItems = projection.lineItems.data;
      const lineItemIndex = _findIndex(lineItems, item => item.id === lineItem.id);

      lineItems[lineItemIndex].periods.data[index] = period;
    },
  },
  actions: {
    onRefresh(context, callback) {
      context.commit(types.ADD_ON_REFRESH, callback);
    },
    refresh(context, lineItem) {
      const projection = context.rootGetters['projections/all'][lineItem.projection_id];

      if (!projection) {
        return;
      }

      context.commit(types.UPDATE_LINE_ITEM, { projection, lineItem });
    },
    upsert(context, lineItem) {
      const isCreating = lineItem.id == null;
      const isUpdating = !isCreating;

      const projection = context.rootGetters['projections/all'][lineItem.projection_id];

      if (isUpdating) {
        return axios.patch(route('api.lineitems.update', { lineitem: lineItem.id }), lineItem).then(
          (success) => {
            context.commit(types.UPDATE_LINE_ITEM, { projection, lineItem: success.data });
          },
        );
      }

      return axios.post(route('api.lineitems.store'), lineItem).then(
        (success) => {
          context.commit(types.ADD_LINE_ITEM, { projection, lineItem: success.data });
        },
      ).then(() => analytics.track('Add Line Item', lineItem));
    },
    upsertPeriod(context, {lineItem, period}) {
      const index = lineItem.periods.data.findIndex(possible => possible.startDate === period.startDate);

      return axios.patch(route('api.lineitems.periods.update', { lineitem: lineItem.id, period: index }), period).then(
        (success) => {
          const projection = context.rootGetters['projections/all'][lineItem.projection_id];
          context.commit(types.UPDATE_LINE_ITEM_PERIOD, {projection ,lineItem, index, period: success.data });
          context.state.onRefresh.forEach(callback => callback(lineItem));
        },
      );
    },
    updateMany(context, lineItems) {
      const projection = context.rootGetters['projections/all'][lineItems[0].projection_id];

      return axios.patch(route('api.lineitems.updateMany'), lineItems).then(
        (success) => {
          context.commit(types.UPDATE_MANY_LINE_ITEMS, { projection, lineItems: success.data.data });
        },
      );
    },
    delete(context, lineItem) {
      const projection = context.rootGetters['projections/all'][lineItem.projection_id];

      return axios.delete(route('api.lineitems.destroy', { lineitem: lineItem.id })).then(
        (success) => {
          context.commit(types.DELETE_LINE_ITEM, { projection, lineItem });
        },
      );
    },
  },
  getters: {},
};
