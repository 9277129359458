<template>
  <div class="px-2 md:px-0">
    <line-item-form :allowed-categories="allowedCategories"
                    :context-id="step.label"
                    :line-items="lineItems"
                    :squash="true"
                    header="Other Income"
                    label="income"
    >
      <template v-slot:empty>
        <div class="flex justify-center items-center">
          <div class="flex justify-end"><img alt="Savings, investments or other rebates and income" class="h-64"
                                             src="/images/savings.svg"></div>
          <h2 class="text-grey-dark w-2/5">Other income, savings, investments and rebates.</h2>
        </div>
      </template>
    </line-item-form>
    <step-links :next="linkToNextStep" :current="step" :previous="linkToPreviousStep" class="pt-4 pb-4"></step-links>
  </div>
</template>
<script>
import LineItemForm from '@/components/lineitems/LineItemForm.vue';
import step from './step.mixin';
import _includes from 'lodash/includes';

export default {
  mixins: [step],

  data() {
    return {
      allowedCategories: {
        equity: {
          id: 'equity',
          label: 'Equity Investment',
          description: 'Investments',
          template: {
            name: '',
            tag: 'equity',
            type: 'lineitem',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-pie-chart"><path class="primary" d="M14 13h6.78a1 1 0 0 1 .97 1.22A10 10 0 1 1 9.78 2.25a1 1 0 0 1 1.22.97V10a3 3 0 0 0 3 3z"/><path class="secondary" d="M20.78 11H14a1 1 0 0 1-1-1V3.22a1 1 0 0 1 1.22-.97c3.74.85 6.68 3.79 7.53 7.53a1 1 0 0 1-.97 1.22z"/></svg>`,
        },
        funding: {
          id: 'grants',
          label: 'Grant Funding',
          description: 'Grant funding from the Government',
          template: {
            name: '',
            tag: 'funding',
            type: 'lineitem',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-money"><path class="secondary" d="M18 14.74a4 4 0 0 0-8 .26H3a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v8a2 2 0 0 1-1 1.74zM10 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/><path class="primary" d="M7 9h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm7 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>`
        },
        rebate: {
          id: 'rebates',
          label: 'Rebates',
          description: 'Tax rebates and cashback',
          template: {
            name: '',
            tag: 'rebate',
            type: 'lineitem',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-receipt"><path class="primary" d="M9 18.41l-2.3 2.3a1 1 0 0 1-1.4 0l-2-2A1 1 0 0 1 3 18V5c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v13a1 1 0 0 1-.3.7l-2 2a1 1 0 0 1-1.4 0L15 18.42l-2.3 2.3a1 1 0 0 1-1.4 0L9 18.4z"/><path class="secondary" d="M7 7h10a1 1 0 0 1 0 2H7a1 1 0 1 1 0-2zm0 4h10a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z"/></svg>`
        },
        loan: {
          id: 'loan',
          label: 'Loans',
          description: 'Money loaned to the company to be repaid',
          template: {
            name: '',
            tag: 'loan',
            type: 'lineitem',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-edit"><path class="primary" d="M4 14a1 1 0 0 1 .3-.7l11-11a1 1 0 0 1 1.4 0l3 3a1 1 0 0 1 0 1.4l-11 11a1 1 0 0 1-.7.3H5a1 1 0 0 1-1-1v-3z"/><rect width="20" height="2" x="2" y="20" class="secondary" rx="1"/></svg>`
        },
        other: {
          id: 'other',
          label: 'Other',
          description: 'Any other income received by the business',
          template: {
            name: '',
            tag: 'other',
            type: 'lineitem',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-folder"><path class="secondary" d="M4 4h7l2 2h7a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2z"/><rect width="20" height="12" x="2" y="8" class="primary" rx="2"/></svg>`
        },
      },
    };
  },

  props: {},

  computed: {
    lineItems() {
      if (this.allowedCategories) {
        return this.lineItemsWith((item) => {
          return _includes(Object.keys(this.allowedCategories), item.tag) && !item.outgoing;
        });
      }

      if (this.allowedCategory) {
        return this.lineItemsWith((item) => {
          return this.allowedCategory.template.tag === item.tag && !item.outgoing;
        });
      }
    },
  },

  methods: {},

  components: {
    LineItemForm,
  },

  mounted() {

  },
};

</script>
<style>

</style>