<template>
  <div class="relative overflow-visible">
    <div
        :class="{
          'flex items-center text-center placeholder-gray-400 ring-offset-0 ring-blue-500 focus:ring-b leading-tight border-l border-b border-t border-opacity-0 focus-within:border-opacity-100 border-blue-500': true,
          'border-b border-gray-200': true || !disabled,
          'text-gray-700': !grayedOut,
          'text-gray-300': grayedOut,
          'min-w-[80px]': true,
        }"
        :style="style"
    >
      <span class="ml-4">{{ prefix }}</span>
      <input
          ref="input"
          v-model.lazy.number="value"
          class="border-0 w-full text-center pl-0 focus:ring-0"
          :disabled="disabled"
          placeholder="0"
          type="text"
          @blur="onInactive"
          @focus="onActive"
          @hover="onActive"
          @keyup.shift.right="replicate($refs.input.value)"
      >
    </div>
    <button
        v-if="!disabled"
        v-show="isActive"
        class="absolute right-0 top-0 px-1 h-full text-xs font-bold text-blue-500 hover:text-white z-10 bg-white border border-blue-500 hover:bg-blue-700 hover:text-white rounded-r hover:border-blue-700"
        style="right: -18px;"
        tabindex="-1"
        title="Replicate value into remaining cells to the right"
        @mousedown="replicate($refs.input.value)"
    >
      <svg class="w-2 h-2 fill-current" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"/></svg>

      <!--      <icon icon="angle-right" style="width:8px;"></icon>-->
    </button>
  </div>
</template>

<script setup>
import {ref, toRefs, watch} from 'vue';

const props = defineProps({
  inputClass: {},
  modelValue: {
    required: false,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  grayedOut: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: null,
  },
  style: {
    type: Object,
    default: () => ({}),
  },
  replicate: {
    type: Function,
  }
});

const { modelValue, disabled, grayedOut, prefix, style, replicate } = toRefs(props);
const emit = defineEmits(['update:modelValue', 'focus', 'blur']);

const value = ref(modelValue.value);
const input = ref();
const isActive = ref(false);

watch(modelValue, (newValue) => {
  if (newValue !== value.value) {
    value.value = newValue;
  }
});

watch(value, (newValue) => {
  emit('update:modelValue', Number(newValue));
});

const onActive = () => {
  emit('focus', input);
  isActive.value = true;
};

const onInactive = () => {
  emit('blur', input);
  isActive.value = false;
};
</script>

<style scoped>

</style>