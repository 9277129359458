import _reduce from 'lodash/reduce';
import _map from 'lodash/map';

import periodEvents from '@/components/lineitems/utils/period-events';
import yearTotaler from './year_totaler';

export default function (item) {
  return yearTotaler(item, periods => _map(periods, (period) => {
    const transactions = periodEvents(period,
      item.discrete ? ['discretetransaction'] : ['transaction']);

    return _reduce(transactions, (carry, transaction) => {
      if (item.discrete) {
        return carry + (transaction.quantity);
      }
      return carry + transaction.value;
    }, 0);
  }));
}
