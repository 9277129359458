<template>
    <div class="">
        <div class="flex flex-col mb-4">
            <h2 class="text-indigo text-xl mb-4">Users</h2>
            <div class="flex gap-x-2">
              <text-input class="flex-grow" placeholder="Invite by email" v-model="inviteEmail"></text-input>
                <div class="absolute invisible block bg-grey-lighter border border-grey-light text-grey-darker rounded flex justify-between items-center mr-2">
                    <label class="px-2 text-xs uppercase">Role:</label>
                    <select :class="{'block appearance-none w-full bg-grey-lighter text-grey-darker py-3 px-2 pr-8' : true}"
                            name="installment_interval"
                            v-model="selectedCircle"
                    >
                        <option :value="circle" v-for="circle in circles">{{ circle.name }}</option>
                    </select>
                    <div class="pointer-events-none absolute pin-y right-0 flex items-center px-2 text-grey-darker">
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>
                    </div>
                </div>
                <primary-action :disabled="loading" @click="submitInvite">Invite</primary-action>
            </div>
            <error name="email"></error>
        </div>
        <div class="bg-white pb-4">
            <table class="w-full text-left table-bspace-0 text-grey-darker">
                <thead class="text-sm uppercase font-semibold text-grey">
                <tr>
                    <th class="py-4">Email</th>
                    <th class="py-4"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in users" :class="{'bg-grey-lighter': hoveredUser === user}" @mouseover="hoveredUser = user" @mouseleave="hoveredUser = null">
                    <td class="py-2">{{ user.email }}</td>
                    <td class="text-right px-4">
                        <user-revoke-button :circle-id="circles[0].id" :user-id="user.id" :user-name="user.name || user.email"
                                            v-if="circles.length > 0 && user.id !== loggedInUser.id"></user-revoke-button>
                    </td>
                </tr>
                <tr v-if="users.length === 0">
                    <td colspan="3 text-grey-light">You haven't invited anyone to join your organisation yet.</td>
                </tr>
                </tbody>
            </table>
            <template v-if="pendingInvites.length > 0">
                <p class=" mt-8 text-grey text-sm uppercase font-semibold">Pending Invites</p>
                <table class="w-full text-left table-bspace-0 text-grey-darker">
                    <tbody>
                    <tr v-for="invite in pendingInvites" :class="{'bg-grey-lighter': hoveredInvite === invite}" @mouseover="hoveredInvite = invite" @mouseleave="hoveredInvite = null">
                        <td class="py-2">{{ invite.email }}</td>
                        <td class="text-right px-4">
                            <template v-if="hoveredInvite == invite">
                                <button class="text-grey hover:text-blue-700 mx-4" :disabled="loading" @click="resend(invite)">Resend</button>
                                <button class="text-grey hover:text-blue-700" :disabled="loading" @click="revoke(invite)">Revoke</button>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
        </div>
    </div>
</template>

<script>
import UserInviteForm from '@/pages/settings/UserInviteForm.vue';
import UserRevokeButton from '@/pages/settings/UserRevokeButton.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {first} from 'lodash';

export default {
    data() {
      return {
        loading: false,
        users: [],
        pendingInvites: [],
        inviteEmail: '',
        circles: [],
        selectedCircle: null,
        hoveredUser: null,
        hoveredInvite: null,
      };
    },
    components: {
      UserInviteForm,
      UserRevokeButton,
      PrimaryAction,
    },
    created() {
      axios.get(route('api.users.index')).then(success => {
        this.users = success.data.data;
      });

      axios.get(route('api.circleinvites.index')).then(success => {
        this.pendingInvites = success.data.data;
      });

      axios.get(route('api.circles.index')).then(success => {
        this.circles = success.data.data;
        this.selectedCircle = first(this.circles);
      });
    },

    computed: {
      ...mapGetters({
        loggedInUser: 'auth/user',
      }),
    },

    methods: {
      ...mapActions({
        setErrors: 'setErrors',
        forgetErrors: 'forgetErrors',
      }),
      submitInvite() {
        this.loading = true;
        axios.post(route('api.circleinvites.store'), {
          email: this.inviteEmail,
          circle_id: this.selectedCircle.id,
        }).then(success => {
          this.pendingInvites.push(success.data);
          this.forgetErrors();
        }).then(() => this.$track('Invite User'))
          .catch(error => {
            if (error.response && error.response.status === 422) {
              this.setErrors(error.response.data.errors);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },

      resend(invite) {
        this.loading = true;

        axios.post(route('api.circleinvites.sends.store', {
          id: invite.id,
        })).then(success => {
          alert('Sent');
          this.forgetErrors();
        }).then(() => this.$track('Resend User Invite')).catch(error => {
          alert('Something went wrong. Please try again.')
        }).finally(() => {
          this.loading = false
        })
      },

      revoke(invite) {
        this.loading = true;

        axios.delete(route('api.circleinvites.destroy', {
          id: invite.id,
        })).then(success => {
          // alert('Revoked');
          let index = this.pendingInvites.indexOf(invite)
          this.pendingInvites.splice(index, 1)
          this.forgetErrors();
        }).then(() => this.$track('Revoke User Invite')).catch(error => {
          alert('Something went wrong. Please try again.')
        }).finally(() => {
          this.loading = false
        })
      },
    },
  };
</script>

<style>

</style>