import employeeCalc from './employee';
import subscriptionCalc from './subscription';
import defaultItemCalc from './default';
import equityCalc from './investment';

export const employee = employeeCalc;
export const subscription = subscriptionCalc;
export const defaultCalc = defaultItemCalc;
export const equity = equityCalc;


export default {
  employee,
  subscription,
  default: defaultCalc,
  equity: equityCalc,
};
