import axios from 'axios';
import * as types from './mutation-types';

export default {
  namespaced: true,

  state: {
    clients: [],
    pagination: {},
  },
  mutations: {
    [types.SET_CLIENTS](state, clients) {
      state.clients = clients;
    },
    [types.SET_CLIENTS_PAGINATION](state, paginator) {
      state.pagination = paginator;
    },
  },
  actions: {
    setClients(context, { data, meta }) {
      context.commit(types.SET_CLIENTS, data);
      context.commit(types.SET_CLIENTS_PAGINATION, meta.pagination);
    },
    fetchAll(context, params) {
      return axios.get(route('api.clients.index', params)).then((response) => {
        this.dispatch('clients/setClients', response.data);
      });
    },
  },
  getters: {
    all(state) {
      return state.clients;
    },
    pagination: state => state.pagination,
  },
};
