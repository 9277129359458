import {
  arrayOf, bool, func, number, oneOf, shape, string, objectOf, object
} from 'vue-types';

export const projection = shape({
  name: string(),
  // reporting_period: string(),
  configuration: object(),
}).loose

export const error = arrayOf(string())
export const errors = objectOf(error)