/*
Need to register to echo and not do it again if already registered
Need to unregister from echo only if no one else is listening

Need to store status state
Need to be able to subscribe for ready event
Notify ready event listeners when "Up to date" status is shown
 */

import {echo} from '../../../bootstrap.js';
import {ref, watch} from 'vue';

const contexts = [];
export const useCache = (projection) => {
  // use existing context if available
  if(contexts[projection.id]) {
    return contexts[projection.id];
  }

  const status = ref(projection.status);
  const jobIndex = ref(projection.job_index);

  echo
    .private(`projections.${projection.id}`)
    .listen('ProjectionCacheStatusChanged', (e) => {
      console.log(e);
      // if timestamp is before the last one, ignore
      if(jobIndex.value > e.job_index) {
        console.log('out of order - ignoring');
        return;
      }

      console.log('updating status', e.status);
      status.value = e.status;
      jobIndex.value = e.job_index;
    })
  ;

  const subscribers = ref([]);
  const subscribe = (callback) => {
    subscribers.value.push(callback);
    return () => {
      subscribers.value = subscribers.value.filter(subscriber => subscriber !== callback);
    }
  }

  const stop = () => {
    echo.leave(`projections.${projection.id}`);
    delete contexts[projection.id]; // forget about this context
  };

  const ready = ref(true);

  watch(status, (newValue, oldValue) => {
    if(newValue === 'Up to date') {
      ready.value = false;
      const promises = [];
      subscribers.value.forEach(callback => {
        promises.push(
          new Promise((resolve, reject) => {
            callback(resolve, reject);
          }),
        );
      });

      Promise.all(promises).then(() => {
        ready.value = true;
      });
    }
  });

  contexts[projection.id] = {
    stop,
    subscribe,
    status,
    ready,
  };

  return contexts[projection.id];
};