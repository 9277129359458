<template>
    <div class="bg-white md:w-4/5 shadow-lg px-4 md:px-8 py-10 rounded">
        <form>
            <h2 class="mb-6 text-gray-darker text-xl">Choose a password to complete your account setup</h2>
            <div class="mb-4">
                <error name="password">
                    <div class="relative">
                        <input class="bg-white border-gray-300 appearance-none border border-transparent focus:border rounded w-full py-3 px-3 text-gray-800 focus:border-blue-400"
                               :type="passwordHidden ? 'password' : 'text'" placeholder="Password" v-model="password">
                        <div class="cursor-pointer absolute right-0 pin-y flex flex-shrink items-center px-2 hover:text-blue-200">
                            <icon icon="eye" v-if="passwordHidden" @click="passwordHidden = false"></icon>
                            <icon icon="eye-slash" v-else @click="passwordHidden = true"></icon>
                        </div>
                    </div>
                </error>
            </div>
            <div class="mb-4">
                <error name="user"></error>
                <primary-action class="w-full" @click.prevent="submit">Get started</primary-action>
            </div>
        </form>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

  export default {
    props: {
      action: {
        type: String,
      },
    },
    data() {
      return {
        passwordHidden: true,
        password: null,
      };
    },
    methods: {
      ...mapActions({
        'setErrors': 'setErrors',
      }),
      submit() {
        axios.post(this.action, {
          password: this.password,
        }).then(success => {
          window.location.href = route('home');
        }).catch(error => {
          // if not a validation error, show an alert to the user
          if (error.response.status === 422) {
            this.setErrors(error.response.data.errors);
            return;
          }

          alert(
              'Something went wrong. Please check you have entered your information correctly and try again. If the problem persists, please contact support.');
        });
      },
    },
    components: {
      PrimaryAction,
    }
  }
  ;
</script>

<style>

</style>