<template>
  <span class="relative z-0 inline-flex shadow-sm rounded-md space-x-0.5">
    <button v-for="(option, key) in options"
            type="button"
            @click="select(option)"
            :disabled="option.disabled"
            @mouseover="maxHovered = option"
            @mouseleave="maxHovered === option ? maxHovered = null : null"
            :class="[
                  'relative inline-flex items-center px-2 py-1 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 ring-blue-700 focus:border-blue-700',
                  isFirst(option) ? 'rounded-l-md' : null,
                  isLast(option) ? 'rounded-r-md' : null,
                  !isFirst(option) ? '-ml-px' : null,
                  (!maxHovered && isChecked(option) || isInMaxHovered(option)) ? 'bg-blue-700 text-white border-blue-700 z-10' : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 ',
                  option.disabled ? 'opacity-50 pointer-events-auto cursor-not-allowed' : null,
                ]"

    >
      <slot :option="option">
        {{ option }}
      </slot>
    </button>
  </span>
</template>

<script>
import Checkbox from '@/components/Checkbox.vue';
import {toRefs, ref, computed, watch} from 'vue';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    // The 'max' option selected
    modelValue: {},
    options: {
      type: Array,
      default() {
        return [
            'One',
            'Two',
            'Three',
        ]
      }
    }
  },
  setup(props, {emit}) {
    const {modelValue, options} = toRefs(props);
    const maxSelected = ref(modelValue.value);
    const maxSelectedIndex = computed(() => {
      if(!options.value) {
        return -1;
      }

      return options.value?.indexOf(maxSelected.value);
    })

    const maxHovered = ref(null);
    const maxHoveredIndex = computed(() => {
      if(!options.value) {
        return -1;
      }

      return options.value?.indexOf(maxHovered.value);
    })

    const isChecked = (option) => {
      if(!options.value) {
        return false;
      }

      return options.value?.indexOf(option) <= maxSelectedIndex.value;
    }

    const select = (option) => {
      maxSelected.value = option;
    }

    const isFirst = (option) => Object.values(options.value).shift() === option;
    const isLast = (option) => Object.values(options.value).pop() === option;

    const isInMaxHovered = (option) => {
      if(!maxHovered.value) {
        return false;
      }

      if(!options.value) {
        return false;
      }

      return options.value?.indexOf(option) <= maxHoveredIndex.value;
    }

    watch(maxSelected, () => emit('update:modelValue', maxSelected.value));

    return {
      isChecked,
      isInMaxHovered,
      select,

      isFirst,
      isLast,
      maxSelected,
      maxSelectedIndex,
      maxHovered,
    };
  },
  components: {Checkbox},
};
</script>

<style>

</style>