<template>
	<div>
		<div class="contents" @click="toggle">
			<slot></slot>
		</div>
		<modal v-model="open">
			<form @submit.stop="$emit('submit', $event)" :class="css" @click.stop v-if="open">
				<slot name="form" :open="open" :close="() => open = false"></slot>
			</form>
		</modal>
	</div>
</template>
<script>
import Modal from '@/components/Modal.vue';

export default {
    compatConfig: {
      COMPONENT_V_MODEL: false,
    },
    data() {
      return {
        open: null,
      };
    },

    props: {
      modelValue: {
        default: false,
	  },
	  css: {
        required: false,
        default: 'shadow bg-white p-4 rounded min-w-72'
	  }
	},

    computed: {},

    components: {
      Modal,
    },

    methods: {
      close() {
        this.open = false
        this.$emit('update:modelValue', this.open)
      },
      toggle() {
        this.open = !this.open
        this.$emit('update:modelValue', this.open)
      }
    },

	watch: {
      modelValue: {
        immediate: true,
        handler() {
          this.open = this.modelValue
        }
      }
	},

    mounted() {

    },
  };

</script>
<style>

</style>