<template>
  <div class="max-w-full flex pl-3 pr-1 text-base border border-gray-300 focus-within:outline-none focus-within:ring-blue-500 focus-within:border-blue-500 sm:text-sm rounded-md focus-within:ring-0">
    <div class="flex items-center flex-shrink-0">
      <slot name="left"></slot>
    </div>
    <select class="border-none focus:ring-0 flex-1"
            v-model="selected"
    >
      <option :value="option" v-for="(option, key) in $props.options">
        <slot name="option" :option="option"/>
      </option>
    </select>
    <div class="flex items-center flex-shrink-0">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import {ref, watch} from 'vue';
import _clone from 'lodash/clone';
import _isEqual from 'lodash/isEqual';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {
      required: false,
    },
    options: {
      type: [Object, Array],
      required: true,
    }
  },
  setup(props, {emit}) {
    const selected = ref(_clone(props.modelValue))

    watch(props, () => {
      if(!_isEqual(selected.value, props.modelValue)) {
        selected.value = _clone(props.modelValue);
      }
    }, { deep: true })

    watch(selected,() => {
      emit('update:modelValue', selected.value)
    })

    return {
      selected,
    }
  },
}
</script>

<style>

</style>