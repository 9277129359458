export default {
  props: {
    token: {
      type: String,
    },
    invite: {
      type: Object,
    },
  },
};
