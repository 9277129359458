<template>
	<div>
		<form @submit.prevent v-click-away="cancel">
			<component :is="formType" v-model="item" :focus="active"></component>
			<div class="flex justify-between mt-4">
				<button class="text-semibold text-gray-600 hover:underline text-sm hover:text-gray-900 focus:ring-1 px-2 py-1 rounded-sm ring-blue-500"
						type="button"
						@click="cancel">Cancel
				</button>
        <primary-action @click="save" :disabled="loading">
            Save
        </primary-action>
			</div>
		</form>
	</div>
</template>
<script>
import itemTypes from './index';
import _cloneDeep from 'lodash/cloneDeep';
import {directive as ClickAway} from 'vue3-click-away';
import {mapActions} from 'vuex';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

export default {
    directives: {
      ClickAway,
    },

    data() {
      return {
        focus: false,
        item: _cloneDeep(this.initialLineItem),
        errors: {},
        loading: false,
      };
    },

    props: {
      onCancel: {
        type: Function,
        default() {
          return () => {
          };
        },
      },
      onSaveSuccess: {
        type: Function,
        default() {
          return () => {
          };
        },
      },
      initialLineItem: {
        type: Object,
        required: true,
      },
	  active: {
        type: Boolean,
        required: true,
	  }
    },

    methods: {
      resetToInitialLineItem() {
        this.item = _cloneDeep(this.initialLineItem);
	  },
      cancel() {
        if(this.item.id) {
          this.resetToInitialLineItem();
		}

        this.onCancel();
        this.forgetErrors();
      },
      save() {
        this.loading = true;
        // need to update using store!
        this.upsertLineItem(this.item).then(
          success => {
            this.forgetErrors();
            this.onSaveSuccess();
          },
          ({response}) => {
            if (response.status === 422) {
              this.setErrors(response.data.errors);
            }
          },
        ).finally(() => this.loading = false);
      },
      ...mapActions({
        upsertLineItem: 'projections/lineItems/upsert',
        setErrors: 'setErrors',
        forgetErrors: 'forgetErrors',
      }),
    },

    watch: {
      active: {
        immediate:true,
        handler(){
          this.$nextTick(() => {
            this.focus = this.active;
          })
        }
      }
    },

    computed: {
      definedComponents() {
        return _map(this.$options.components, (value, key) => key.toLowerCase());
      },
      formType() {
		return _includes(this.definedComponents, this.item.tag) ? this.item.tag : this.item.type;
      },
    },

    components: {
      ...itemTypes,
      PrimaryAction,
    },
  };

</script>
<style>

</style>