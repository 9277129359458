import NProgress from 'nprogress';
import axios from 'axios';
import router from '@/router';
import 'nprogress/nprogress.css';

export default function () {
  // axios.interceptors.request.use(function(config) {
  //   NProgress.start();
  //   return config;
  // });
  //
  // axios.interceptors.response.use(function(response) {
  //   NProgress.done();
  //   return response;
  // });

  router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
    setTimeout(() => {
      NProgress.done();
    }, 80);
  });

  router.onError((error) => {
    NProgress.done();
  });

  router.afterEach((to, from) => {
    NProgress.done();
  });
}
