import LineItemConfigurationForm from '@/components/lineitems/forms/LineItemConfigurationForm.vue';
import Modal from '@/components/Modal.vue';

export default {

  data() {
    return {
      configuring: false,
    };
  },

  methods: {
    configure() {
      this.configuring = true;
    },
    stopConfiguring() {
      this.configuring = false;
    },
  },

  components: {
    Modal,
    LineItemConfigurationForm,
  },

};
