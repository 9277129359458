<template>
  <span class="relative z-0 inline-flex shadow-sm rounded-md">
    <button v-for="(option, key) in options" type="button"
            @click="select(key)"
            :disabled="option.disabled"
            :class="[
                  'relative inline-flex items-center px-2 py-1 border text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 ring-blue-700 focus:border-blue-700',
                  isFirst(option) ? 'rounded-l-md' : null,
                  isLast(option) ? 'rounded-r-md' : null,
                  !isFirst(option) ? '-ml-px' : null,
                  isSelected(option).value ? 'bg-blue-300 text-white border-blue-300 ring-1 z-10' : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-50 ',
                  option.disabled ? 'opacity-50 pointer-events-auto cursor-not-allowed' : null,
                ]"

    >
      <slot :option="option">
        {{ option }}
      </slot>
    </button>
  </span>
</template>

<script>
import {toRefs, watch, ref, computed} from 'vue';
import _isEqual from 'lodash/isEqual';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {},
    options: {},
  },
  setup(props, {emit}) {
    const {options, modelValue} = toRefs(props);
    const selected = ref(modelValue?.value ?? Object.values(options.value)[0]);

    watch(selected, () => emit('update:modelValue', selected.value), { deep: true });
    watch(modelValue, () => selected.value = modelValue?.value);

    const isFirst = (option) => Object.values(options.value).shift() === option;
    const isLast = (option) => Object.values(options.value).pop() === option;
    const isSelected = (option) => computed(() => {
      return _isEqual(selected.value, option);
    });

    const select = (key) => selected.value = options.value[key];

    return {
      selected,

      isFirst,
      isLast,
      isSelected,

      select,
    };
  },
  components: {},
};
</script>

<style>
  button:disabled {
    @apply cursor-not-allowed pointer-events-auto;
  }
</style>