<template>
  <div class="flex justify-between">
    <div class="flex space-x-4 w-full">
      <div class="bg-white border border-gray-300 rounded p-1" v-if="hasPages">
        <span class="px-2 text-sm">Page</span>
        <select v-model="currentPage" class="rounded border-gray-300 text-sm border py-0.5">
          <option v-for="page in allPages" :value="page">{{ page }}</option>
        </select>
        <span class="px-2 text-sm">of {{ totalPages }} ({{ totalItems }} total items)</span>
      </div>
      <div class="bg-white border border-gray-300 rounded p-1" v-if="totalItems > 0">
        <span class="px-2  text-sm">Showing</span>
        <select v-model="perPage" class="rounded border-gray-300 border text-sm py-0.5">
          <option v-for="scope in perPageOptions" :value="scope">{{ scope }}</option>
        </select>
        <span class="px-2 text-sm">items per page</span>
      </div>
    </div>
    <ul class="flex list-reset pl-0 rounded items-center" v-if="hasPages">
      <!--Previous Page Link-->
      <li class="bg-white border-t border-b border-l border-gray-300 py-1 px-2 rounded-l text-sm hover:bg-gray-50" v-if="showPrevAndFirst"><router-link :to="url(1)">Start</router-link></li>
      <li class="bg-white border-t border-b border-l border-gray-300 py-1 px-2 text-sm hover:bg-gray-50" v-if="showPrevAndFirst"><router-link :to="url(currentPage - 1)">Previous</router-link></li>
      <!--Array Of Links-->
      <li
          v-for="(page, index) in pages"
          :key="index"
          :class="{'bg-white border-t border-l border-b border-gray-300 py-1 px-2 text-sm': true, 'rounded-l': !showPrevAndFirst && isFirstPage(page), 'border-r rounded-r': !showNextAndLast && isLastPage(page), 'active text-blue-700 font-semibold bg-gray-100': currentPage === page, 'hover:bg-gray-50': currentPage !== page }"
      >
        <span v-if="currentPage === page">{{ page }}</span>
        <router-link v-else :to="url(page)">{{ page }}</router-link>
      </li>
      <!--    Next Page Link-->
      <li class="bg-white border-t border-l border-b border-r border-gray-300 py-1 px-2 text-sm hover:bg-gray-50" v-if="showNextAndLast"><router-link :to="url(currentPage + 1)">Next</router-link></li>
      <li class="bg-white border-t border-b border-r border-gray-300 py-1 px-2 rounded-r text-sm hover:bg-gray-50" v-if="showNextAndLast"><router-link :to="url(totalPages)">End</router-link></li>
    </ul>
  </div>
</template>

<script>
import {computed, ref, toRefs, watch} from 'vue';
import _range from 'lodash/range';
import _max from 'lodash/max';
import _min from 'lodash/min';
import _first from 'lodash/first';
import _last from 'lodash/last';
import router from '../router.js';

export default {
  props: {
    modelValue: {
      type: Object,
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  setup(props) {
    const { modelValue, params } = toRefs(props);
    const totalPages = computed(() => modelValue.value.total_pages);
    const totalItems = computed(() => modelValue.value.total);
    const perPageOptions = [5, 15, 25, 50, 100];

    const currentPage = ref(modelValue.value.current_page);
    const perPage = ref(modelValue.value.per_page);

    watch(modelValue, (value) => {
      currentPage.value = value.current_page;
      perPage.value = value.per_page;
    });

    const onFirstPage = computed(() => currentPage.value === 1);
    const hasPages = computed(() => totalPages.value > 1);
    const hasMorePages = computed(() => currentPage.value < totalPages.value);

    const showPrevAndFirst = computed(() => !onFirstPage.value);
    const showNextAndLast = computed(() => hasMorePages.value);

    const allPages = computed(() => _range(1, totalPages.value + 1));

    const pages = computed(() => {
      const cutOff = 5;
      const plusMinus = 2;

      const min = _max([currentPage.value - plusMinus, 1]);
      const max = _min([currentPage.value + plusMinus, totalPages.value]) + 1;

      if (totalPages.value >= cutOff) {
        return _range(
            min,
            max,
        );
      }

      return _range(1, totalPages.value + 1);
    });

    const isFirstPage = (page) => page === _first(pages.value ?? []);
    const isLastPage = (page) => page === _last(pages.value ?? []);

    const url = (page) => {
      const paginationParams = {
        page,
        per_page: perPage.value,
      };

      return {query: {...paginationParams, ...params.value}};
    };

    watch(currentPage, (page) => {
      router.push(url(page));
    });

    watch(perPage, () => {
      router.push(url(1));
    });

    return {
      currentPage,
      totalPages,
      totalItems,
      perPage,
      perPageOptions,
      onFirstPage,
      hasPages,
      hasMorePages,
      pages,
      url,
      showPrevAndFirst,
      showNextAndLast,
      isFirstPage,
      isLastPage,
      allPages,
    };
  },
};
</script>

<style>
</style>
