<template>
  <div>
    <popup-form v-model="popup">
      <!--			<icon icon="times"></icon>-->
      <div class="w-6 h-auto">
        <svg class="icon-close fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path class="" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                fill-rule="evenodd"/>
        </svg>
      </div>
      <template v-slot:form>
        <p class="text-lg">Are you sure you want to delete <span class="font-semibold">{{ client.name }}</span>?</p>
        <div class="flex justify-between mt-6">
          <button @click="close" class="btn border border-gray text-gray-900 uppercase">Cancel</button>
          <button @click="destroy" class="btn bg-red-600 text-white uppercase">Delete</button>
        </div>
      </template>
    </popup-form>
  </div>
</template>
<script>
import PopupForm from '@/components/forms/PopupForm.vue';

export default {
  data() {
    return {
      name: this.client.name,
      originalTitle: this.client.name,
      popup: false,
    };
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  computed: {
    title() {
      if (this.originalTitle) {
        return 'Duplicate \'' + this.originalTitle + '\'';
      }

      return null;
    },
  },

  methods: {
    destroy() {
      axios.delete(route('api.clients.destroy', {id: this.client.id})).then(
          success => {
            this.popup = false;
            window.location.reload();
          },
          error => {
            console.error(error);
            alert('Something went wrong. Please refresh the page and try again.');
          },
      );
    },
    close() {
      this.popup = false;
    },
  },

  components: {
    PopupForm,
  },

  mounted() {

  },
};
</script>
<style>

</style>