import m from 'mudder';
import _clone from 'lodash/clone';
import _findLastIndex from 'lodash/findLastIndex';

const defaultMudder = m.alphabet;

export default function (algorithm = defaultMudder) {
  /*
   * Ensure that all items have a sort key, and generate one if not.
   * Returns a map of the original index to the generated sort key but does not mutate the original items.
   */
  function ensure(items, key = item => item) {
    if (items.filter(item => key(item)).length === items.length) {
      return {};
    }

    const sorted = _clone(items).sort(function(a, b){
      const keyA = key(a) ?? '';
      const keyB = key(b) ?? '';

      return keyA.localeCompare(keyB);
    });

    const lastKeyDefined = _findLastIndex(sorted, item => !!key(item));
    const lastDefined = sorted[lastKeyDefined];
    const lastSortKeyDefined = lastDefined ? key(lastDefined) : undefined;

    const nulls = sorted.filter(item => !key(item));
    const generated = algorithm.mudder(lastSortKeyDefined, undefined, nulls.length);

    const result = {};
    nulls.forEach((item, generatedKey) => {
      const originalIndex = items.indexOf(item);
      result[originalIndex] = generated[generatedKey];
    });

    return result;
  }

  function sort(items, index, key = item => item) {
    return shuffle(items, [index], key);
  }

  function shuffle(items, indices, key = item => item) {
    const sorted = indices.sort();

    // get before/after of the start & end of the indices. This is the outer bounds of the collection to generate between.
    const before = sorted[0] - 1;
    const after = sorted[sorted.length - 1] + 1;

    // get the items corresponding to the indices, or yield nothing
    let start = items[before] || undefined;
    const end = items[after] || undefined;

    // if they are equal, nullify one so that it's possible to generate a midpoint
    if (start === end) {
      start = null;
    }

    // get the start/end keys to use in the mudder
    const startSortKey = start ? key(start) : undefined;
    const endSortKey = end ? key(end) : undefined;

    // generate a mudder - for 1 element, remainder is 1
    let generated;
    try {
      generated = algorithm.mudder(startSortKey, endSortKey, indices.length);
    } catch (e) {
      if (!e.message.includes('lexicographically inseparable')) {
        throw e;
      }

      // if can't find a sensible default, recurse with expanded indices
      return shuffle(items, [before, ...indices, after], key);
    }
    const result = {};

    indices.forEach((index, position) => {
      result[index] = generated[position];
    });

    return result;
  }

  /*
   * Generate a new sort key that sorts the new item at the end of the list
   */
  function append(items, newItem) {
    const toSort = [...items, newItem];
    const keys = ensure(toSort, (item) => item.sort_key);
    return keys[toSort.length - 1] ?? newItem.sort_key;
  }

  return {
    sort,
    ensure,
    append,
  };
}
