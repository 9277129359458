<template>
  <div class="px-2 md:px-0">
    <line-item-form :allowed-categories="allowedCategories" :context-id="step.label"
                    :discrete-data-entry="true"
                    :line-items="lineItems"
                    :squash="true"
                    header="Sales Plan"
                    label="sales"
    >
      <template v-slot:empty>
        <div class="flex justify-center items-center">
          <div class="flex justify-end"><img alt="Recurring or one-off sales of physical or digital products"
                                             class="h-64"
                                             src="/images/sales.svg"></div>
          <h2 class="text-grey-dark w-2/5 ml-4">Recurring or one-off sales of physical or digital products</h2>
        </div>
      </template>
    </line-item-form>
    <div v-if="lineItems.length > 0 && salesPlan.balances" class="container mx-auto py-4">
      <sales-plan-graphs :sales-plan="salesPlan"></sales-plan-graphs>
    </div>
    <step-links :next="linkToNextStep" :current="step" :previous="linkToPreviousStep" class="pt-4 pb-4"></step-links>
  </div>
</template>
<script>
import LineItemForm from '@/components/lineitems/LineItemForm.vue';
import step from './step.mixin';
import SalesPlanGraphs from '@/components/ui/sales-plan/SalesPlanGraphs.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
  mixins: [step],

  data() {
    return {
      allowedCategories: {
        product: {
          label: 'Unit',
          description: 'Products and Services that you sell as a one-off',
          discrete: true,
          template: {
            tag: 'product',
            type: 'product',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-shopping-basket"><path class="primary" d="M21.78 10l-2.81 11.24A1 1 0 0 1 18 22H6a1 1 0 0 1-.97-.76L2.22 10h19.56zM6.03 15.24l1 4a1 1 0 0 0 1.94-.48l-1-4a1 1 0 0 0-1.94.48zm10-.48l-1 4a1 1 0 0 0 1.94.48l1-4a1 1 0 0 0-1.94-.48zM11 15v4a1 1 0 0 0 2 0v-4a1 1 0 0 0-2 0z"/><rect width="20" height="4" x="2" y="8" class="secondary" rx="1"/><path class="primary" d="M9.7 9.7a1 1 0 1 1-1.4-1.4l6-6a1 1 0 0 1 1.4 1.4l-6 6z"/></svg>`,
        },
        subscription: {
          label: 'Subscription',
          description: 'Products and services that you sell on a recurring basis',
          // disabled: true,
          template: {
            tag: 'subscription',
            type: 'product',
          },
          icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon-credit-card"><path class="primary" d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm13 12a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-6 0a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2z"/><rect width="20" height="4" x="2" y="7" class="secondary"/></svg>`,
        },
        // product_time: {
        //   label: "Time Service",
        //   description: "Man-hour services that you sell at a billable rate",
        //   disabled: true,
        //   template: {
        //     tag: "product_time",
        //     type: "product_time",
        //   }
        // },
      },
    };
  },

  filters: {
    extensions(items) {
      return items.map(item => {
        return item.extensions.data[0];
      });
    },
  },

  methods: {
    ...mapActions({
      refreshSalesPlan: 'projections/statements/refreshSalesPlan',
      onRefresh: 'projections/lineItems/onRefresh',
    }),
    ensureSalesPlanInsightsFresh(force = false) {
      if (this.lineItems.length > 0 || force) {
        this.refreshSalesPlan(this.projection.id);
      }
    },
  },

  props: {},

  computed: {
    ...mapGetters({
      salesPlan: 'projections/statements/salesPlan',
    }),
    lineItems() {
      return this.lineItemsWithAnyTags(Object.keys(this.allowedCategories));
    },
  },

  components: {
    LineItemForm,
    SalesPlanGraphs,
  },

  created() {
    const self = this;
    // this.onRefresh(() => self.ensureSalesPlanInsightsFresh(true));
    this.ensureSalesPlanInsightsFresh();
  },

  /*
  The sales plan graphs should recalculate any time the line items change or cache changes to 'up to date'
   */
  watch: {
    lineItems: {
      deep: true,
      handler() {
        this.ensureSalesPlanInsightsFresh();
      }
    },
  },
};

</script>
<style>

</style>