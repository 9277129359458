<template>
    <teleport to="body" v-if="!error && !confirmed">
        <stripe @loaded="stripe = $event" :api-key="apiKey"></stripe>
        <modal :modelValue="!!paymentIntentSecret">
            <div class="bg-white px-8 py-8 rounded-sm w-full max-w-md">
                <div v-show="!updatingCard">
                    <h2 class="text-lg">Authorise card</h2>
                    <p class="text-sm">We need to authorise charges on your card. Please follow the instructions from your bank below.</p>
                    <div class="flex justify-center items-center mt-8">
                      <svg v-if="!error" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                </div>
            </div>
        </modal>
    </teleport>
    <card-update-required-popup v-else-if="error"></card-update-required-popup>
</template>

<script>
  import Modal from '@/components/Modal.vue';
  import CardInput from '@/components/billing/CardInput.vue';
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
  import SecondaryAction from '@/components/buttons/SecondaryAction.vue';
  import Stripe from '@/components/billing/Stripe.vue';
  import CardUpdateRequiredPopup from '@/components/billing/CardUpdateRequiredPopup.vue';

  export default {
    compatConfig: {
      WATCH_ARRAY: false,
    },

    data() {
      return {
        updatingCard: false,
        stripe: null,
        card: null,
        error: null,
        confirmed: false,
        apiKey: import.meta.env.VITE_STRIPE_KEY,
      };
    },

    props: {
      paymentIntentSecret: {
        type: String,
      },
      lastInvoiceId: {
        type: String,
        required: false,
      }
    },

    components: {
      Modal,
      CardInput,
      PrimaryAction,
      SecondaryAction,
      Stripe,
      CardUpdateRequiredPopup,
    },

    methods: {
      onCardInput({card, error}) {
        this.card = card;
        this.error = error;
      },
    },

    watch: {
      stripe() {
        this.stripe.confirmCardPayment(this.paymentIntentSecret).then(result => {
          if(result.error) {
            // we need a new card
            this.error = result.error
            return
          }

          axios.post(route('api.cards.confirm')).then(() => this.confirmed = true)
            .catch(() => window.reload());
        })
      }
    }
  };
</script>

<style>

</style>