<template>
  <div
      class="fixed bottom-0 flex flex-1 justify-center content-center items-center py-4 z-50 w-full pointer-events-none">
    <transition-group
        class="flex flex-col-reverse gap-y-2 pointer-events-auto items-start items-center"
        enter-active-class="transform ease-in-out duration-300 transition"
        enter-class="opacity-0 translate-y-full"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transform transition ease-in-out duration-200"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-full"
        tag="div"
    >
      <div v-for="(notification, key) in notifications" :key="genKey()" >
        <notification :modelValue="notification" class="shadow-2xl ring-1 ring-gray-500 ring-opacity-5"
                      @dismiss="dismiss(key)"/>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Notification from '@/components/Notification.vue';
import {computed} from 'vue';
import _clone from 'lodash/clone';
import _uniqueId from 'lodash/uniqueId';

export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    modelValue: {
      type: [Object, Array],
      default() {
        return [
          {
            title: 'Success',
            body: 'Replace this text with a real notification.',
            type: 'success',
          },
          {
            title: 'Error',
            body: 'Replace this text with a real notification.',
            type: 'error',
          },
        ];
      },
    },
  },
  components: {
    Notification,
  },
  setup(props, {emit}) {
    const notifications = computed(() => props.modelValue);

    const genKey = () => _uniqueId('notification-');

    const except = (elements, key) => {
      let copy = _clone(elements);
      delete copy[key];

      return copy;
    };

    const dismiss = (key) => {
      emit('dismiss', notifications.value[key]);
      emit('update:modelValue', except(notifications.value, key));
    };

    return {
      notifications,
      dismiss,
      genKey,
    };
  },
};
</script>

<style>

</style>