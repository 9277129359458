<template>
  <div class="bg-white shadow-lg px-4 md:px-8 py-10 rounded">
    <h2 class="mb-6 text-grey-darker text-xl">Let's get you set up</h2>
    <div class="mb-4">
      <error name="email">
        <label class="block text-grey-darker text-sm mb-2">Email*</label>
        <input autofocus
               class="bg-white border-gray-300 appearance-none border border-transparent focus:border rounded w-full py-3 px-3 text-grey-darker focus:border-blue-400"
               name="email" placeholder="Your email address" type="text" v-model="email">
      </error>
    </div>
    <div>
      <label class="block text-grey-darker text-sm  mb-2">Choose a password*</label>
      <div class="mb-4">
        <error name="password">
          <div class="relative flex items-center">
            <input :type="passwordHidden ? 'password' : 'text'"
                   class="bg-white border-gray-300 appearance-none border border-transparent focus:border rounded w-full py-3 px-3 text-grey-darker focus:border-blue-400"
                   name="password" placeholder="Password"
                   v-model="password">
            <div class="cursor-pointer absolute right-0 flex flex-shrink items-center px-2 hover:text-blue-200 text-gray-600">
              <svg @click="passwordHidden = false" v-if="passwordHidden" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
              </svg>
              <svg @click="passwordHidden = true" v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
              </svg>
            </div>
          </div>
        </error>
      </div>
    </div>
    <div class="mb-4">
      <error name="organisation_name">
        <label class="block text-grey-darker text-sm  mb-2">Company Name*</label>
        <input
            class="bg-white border-gray-300 appearance-none border border-transparent focus:border rounded w-full py-3 px-3 text-grey-darker focus:border-blue-400"
            name="company" placeholder="Acme Ltd" type="text" v-model="organisation_name">
      </error>
    </div>

    <div class="mb-4">
      <error name="coupon">
        <label class="block text-grey-darker text-sm  mb-2">Coupon</label>
        <input
            class="bg-white border-gray-300 appearance-none border border-transparent focus:border rounded w-full py-3 px-3 text-grey-darker focus:border-blue-400"
            name="coupon" placeholder="Your exclusive discount code" type="text" v-model="coupon">
      </error>
    </div>

    <div>
      <label class="block text-gray-800 text-sm mb-2">
        Credit or debit card*
      </label>
      <card-input
          @update:modelValue="onCardInput"
          @loaded="stripe = $event"
          class="mt-2 mb-2"
      ></card-input>
      <error name="card"></error>
      <p class="text-xs text-gray mt-2">Your card number is sent securely to our payment provider and charged
        based on your account usage each year. We do not hold your details on file and they are never sent to
        our servers.</p>
      <div class="flex justify-center">
        <primary-action class="mt-4" :disabled="!ready" @click="submit">Get started</primary-action>
      </div>
    </div>

    <error name="plan">
      <div class="mb-4"></div>
    </error>
  </div>
</template>

<script>
import CardInput from '@/components/billing/CardInput.vue';
import PrimaryAction from '@/components/buttons/PrimaryAction.vue';
import {mapActions} from 'vuex';
import store from '@/store';
import Error from '@/components/forms/Error.vue';

export default {
  props: {
    initialCoupon: {
      type: String,
      required: false,
    },
    initialPlan: {
      type: String,
      required: true,
    },
    productDescription: {
      type: String,
      required: false,
    },
    priceDescription: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      passwordHidden: true,
      organisation_name: null,
      email: null,
      password: null,
      coupon: this.initialCoupon,
      stripe: null,
      card: null,
    };
  },

  components: {
    CardInput,
    PrimaryAction,
    Error,
  },

  methods: {
    ...mapActions({
      'setErrors': 'setErrors',
    }),
    onCardInput({card, error}) {
      this.card = card;
      this.error = error;
    },
    submit() {
      this.loading = true;
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
      }).then(({paymentMethod}) => {
        this.loading = false;
        this.signup(paymentMethod);
      }).finally(() => this.loading = false);
    },
    signup(paymentMethod) {
      this.loading = true;
      axios.post(route('api.signup'), {
        email: this.email,
        password: this.password,
        organisation_name: this.organisation_name,
        coupon: this.coupon,
        plan: this.initialPlan,
        payment_method: paymentMethod.id,
      }).then(success => {
        // log user in and redirect to page where they can use the app with jwt token injected into page
        let token = success.data.data;
        store.dispatch('auth/setToken', token).then(() => {
          window.location.href = route('home');
        });
      }).catch(error => {
        if (!error.response) {
          throw error;
        }

        // if not a validation error, show an alert to the user
        if (error.response.status === 422) {
          this.setErrors(error.response.data.errors);
          return;
        }

        alert(
            `Error code ${error.response.status}: Something went wrong. Please check you have entered your information correctly and try again. If the problem persists, please contact support.`);
      }).finally(() => {
        this.loading = false;
      });
    },
  },

  computed: {
    canSubmit() {
      return !!this.organisation_name && !!this.email && !!this.password && this.card.complete;
    },
    ready() {
      return !(this.canSubmit || this.loading);
    },
  },
};
</script>

<style>

</style>