<template>
  <div @focus="focusInput">
    <error :name="name" :messages="errors">
      <input-label v-if="label">{{ label }}</input-label>
      <div
          class="relative px-2 flex appearance-none border border-gray-300 rounded py-2 bg-white items-center focus-within:ring-1 focus-within:ring-blue-500">
        <div>
          <slot name="left" :value="input"></slot>
        </div>
        <input v-bind="{name, ...attributes}" ref="input" v-model="input" :autocomplete="autocomplete"
               :class="[
                    'px-2 flex-1 focus:border-blue-200 border-0 focus:ring-0 py-0',
                    cssOverride,
                   ]"
               :placeholder="placeholder"
               :readonly="readonly"
               :type="inputType"
               size="1"
               @blur="blur"
               @change.stop="$emit('change', $event)"
               @focus="onFocus"
               @input.stop="emit"
               @keypress="$emit('keypress', $event)"
               @select="onSelectionChange"
               @keydown="onSelectionChange"
        >
        <slot name="bottom" class="absolute -bottom-2 left-auto -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"></slot>
        <div class="flex-no-shrink">
          <slot name="right" :value="input"></slot>
        </div>
      </div>
    </error>
    <slot></slot>
  </div>
</template>
<script>
import InputLabel from '@/components/ui/Label.vue';
import Error from '@/components/forms/Error.vue';
import {nextTick} from 'vue';

export default {
  data() {
    return {
      input: null,
    };
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },

    autocomplete: {
      type: [String, Boolean],
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      required: false,
    },
    modelValue: {},
    label: {
      type: String,
    },
    protected: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    cssOverride: {
      type: String,
      default: '',
    },
    type: {
      default: null,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },

    errors: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  computed: {
    inputType() {
      if (this.protected) {
        return 'password';
      }

      return this.type;
    },
    cssClass() {
      let override = {};
      override[this.cssOverride] = this.cssOverride || false;

      return {
        'px-2 flex-1 focus:border-blue-200 border-0 focus:ring-0': true,
        ...override,
      };
    },
    attributes() {
      if (this.type === 'number') {
        return {
          min: this.min,
          max: this.max,
        };
      }

      return {};
    },
  },

  components: {
    InputLabel,
    Error,
  },

  methods: {
    emit() {
      this.$emit('update:modelValue', this.input);
      this.onSelectionChange();
    },
    blur() {
      this.$el.dispatchEvent(new FocusEvent('blur'));
    },
    focusInput() {
      this.$refs.input.focus();
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onSelectionChange() {
      this.$emit('caret', this.$refs.input.selectionStart);
    },
  },

  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.input = this.modelValue;
      },
    },
  },

  created() {
    this.input = this.modelValue;
  },

  mounted() {
    if (this.autofocus) {
      nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },
};

</script>
<style scoped>
input {
  min-width: 0;
}
</style>