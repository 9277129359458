<template>
  <div class="flex items-center">
    <input :id="name" :name="name" type="checkbox"
           class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
           v-model="selected"
    >
    <label :for="name" class="ml-2 block text-sm text-gray-900">
      <slot></slot>
    </label>
  </div>
</template>

<script>
import {toRefs, ref, watch} from 'vue';

export default {
  props: {
    name: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, {emit}) {
    const {modelValue} = toRefs(props);
    const selected = ref(modelValue.value);

    watch(selected, () => emit('update:modelValue', selected.value), { deep: true });

    return {
      selected,
    };
  },
  components: {},
};
</script>

<style>

</style>