import axios from 'axios';
import { findIndex, find } from 'lodash';
import * as types from './mutation-types';

export default {
  namespaced: true,

  state: {
    all: [],
  },
  mutations: {
    [types.LOAD_ORGANISATION](state, organisation) {
      const index = findIndex(state.all, possible => possible.id === organisation.id);

      if (index > -1) {
        state.all[index] = organisation;
      }

      return state.all.push(organisation);
    },
  },
  actions: {
    fetch({ getters, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(route('api.organisations.show', { id }))
          .then((success) => {
            commit(types.LOAD_ORGANISATION, success.data);
            resolve();
          }).catch(reject);
      });
    },
    update({ commit }, organisation) {
      return new Promise((resolve, reject) => {
        axios
          .patch(route('api.organisations.update', { id: organisation.id }), organisation)
          .then((success) => {
            commit(types.LOAD_ORGANISATION, success.data);
            resolve();
          }).catch(reject);
      });
    },
  },
  getters: {
    get: state => id => find(state.all, possible => possible.id === id) || null,

    current(state, getters, rootState, rootGetters) {
      const user = rootGetters['auth/user'];

      if (user) {
        return getters.get(user.organisation.id);
      }

      return null;
    },
  },
};
