<template>
  <div class="overflow-auto flex flex-col">
    <div class="px-10 mt-7">
      <h1>Export</h1>
<!--      <p>Generate a tailored export of this projection</p>-->
    </div>
    <div class="px-10 mt-6">
      <h2 class="mb-2">Years</h2>
      <ContiguousRangeInput
          :options="reportingPeriods"
          v-model="selectedEndPeriod"
      >
        <template v-slot="{option}">
          {{ option.label }}
        </template>
      </ContiguousRangeInput>
    </div>
    <div class="px-10 mt-8">
      <h2 class="mb-2">Statements</h2>
      <div class="grid grid-cols-1 lg:grid-cols-2 grid-flow-row gap-1">
        <Checkbox v-for="statement in statements" :name="statement.id" v-model="config.statements[statement.id]">{{ statement.label }}</Checkbox>
      </div>
    </div>
    <div class="px-10 mt-8">
      <h2 class="mb-2">Additional Information</h2>
      <div class="grid grid-cols-1 grid-flow-row gap-1">
        <Checkbox name="commentary" v-model="config.commentary">Assumptions</Checkbox>
        <Checkbox name="visualizations" v-model="config.visualizations">Charts</Checkbox>
      </div>
    </div>
    <div class="flex-1 flex items-end content-end px-10 py-5 mt-5 border-t">
      <div class="flex w-full justify-between space-x-2 items-center">
        <CancelButton @click="$emit('close')"></CancelButton>
        <div class="grid grid-cols-2 gap-2">
          <button
              v-for="option in exportOptions"
              type="button"
              @click.stop="select(option)"
              class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <div class="text-gray-700 font-medium">{{ option.label }}</div>
            <div class="ml-3 -mr-1 h-5 w-5" v-html="option.icon"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiButton from '@/components/MultiButton.vue';
import {ref, computed, watch, reactive} from 'vue';
import exportOptions from './export-options.js';
import Checkbox from '@/components/Checkbox.vue';
import _keyBy from 'lodash/keyBy';
import _mapValues from 'lodash/mapValues';
import ContiguousRangeInput from '@/components/ContiguousRangeInput.vue';
import _last from 'lodash/last';
import HorizontalOptions from '@/components/HorizontalOptions.vue';
import _range from 'lodash/range';
import _pickBy from 'lodash/pickBy';
import CancelButton from '@/components/CancelButton.vue';
import analytics from '@/app/analytics.js';

const statements = [
  {id: 'salesplan', label: 'Sales Plan'},
  {id: 'cashflow', label: 'Cash Flow'},
  {id: 'profitloss', label: 'Profit & Loss'},
  {id: 'balancesheet', label: 'Balance Sheet'},
];

export default {
  props: {
    projection: {type: Object},
  },
  setup(props, {emit}) {
    const restoreConfig = () => {
      const defaultConfig = {
        statements: {
          ..._mapValues(_keyBy(statements, (statement) => statement.id), () => true),
        },
        commentary: true,
        visualizations: true,
        periods: _range(0, props.projection.reporting_periods.data.length),
      };

      if (localStorage) {
        return reactive({...defaultConfig, ...JSON.parse(localStorage.getItem('export:config'))} ?? {});
      }

      return reactive(defaultConfig);
    };

    const config = restoreConfig();
    const isOpen = ref(true);

    const exportUrl = computed(() => route('projections.export.generate', {projection: props.projection.id, ...payload.value}));
    const format = computed(() => selected.value?.value);

    const rememberConfig = () => {
      if (localStorage) {
        localStorage.setItem('export:config', JSON.stringify(config));
      }
    };

    const selected = ref();
    const selectedPeriodIndices = computed(() => _range(0, selectedEndPeriodIndex.value + 1));

    const select = (option) => {
      selected.value = option;
      config.periods = selectedPeriodIndices.value;
      rememberConfig();
      isOpen.value = false;
      analytics.track('Export Projection', {
        format: selected.value?.value,
        ...(payload.value ?? {}),
      })
      window.open(generateUrl(selected.value?.value), '_blank');
    }

    const reportingPeriods = computed(() => props.projection.reporting_periods.data);
    const selectedEndPeriod = ref(config.periods.length ? reportingPeriods.value[_last(config.periods)] : _last(reportingPeriods.value));
    const selectedEndPeriodIndex = computed(() => reportingPeriods.value.indexOf(selectedEndPeriod.value));


    const payload = computed(() => {
      return {
        periods: _range(0, selectedEndPeriodIndex.value + 1),
        ...config,
        statements: Object.keys(_pickBy(config.statements)),
      }
    })

    const generateUrl = (format) => {
      const url = new URL(exportUrl.value);
      url.pathname = url.pathname.concat('.', format);

      return url.toString();
    };

    const generatedUrl = computed(() => {
      return generateUrl(format.value);
    });

    // watch(selected, () => {
    //   rememberConfig();
    //   isOpen.value = false;
    //   window.open(generateUrl(selected.value?.value), '_blank');
    // });

    watch(isOpen, () => {
      if(isOpen.value) {
        return emit('open');
      }

      return emit('close');
    })

    return {
      exportOptions,
      isOpen,
      select,
      payload,

      statements,
      config,
      reportingPeriods,
      selectedEndPeriod,
      selectedEndPeriodIndex,
    };
  },
  components: {
    HorizontalOptions,
    ContiguousRangeInput,
    Checkbox,
    MultiButton,
    CancelButton,
  },
};
</script>

<style>

</style>