import axios from 'axios';
import * as types from './mutation-types';

const generateDefaultData = () => ({
  salesPlan: {},
});

export default {
  namespaced: true,

  state: generateDefaultData(),
  mutations: {
    [types.SET_SALES_PLAN](state, salesPlan) {
      state.salesPlan = salesPlan;
    },
    [types.CLEAR_STATEMENTS](state) {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });

      const defaults = generateDefaultData();
      Object.keys(defaults).forEach((key) => {
        state[key] = defaults[key];
      });
    },
  },
  actions: {
    clear(context) {
      context.commit(types.CLEAR_STATEMENTS);
    },
    refreshSalesPlan(context, projection) {
      return axios.get(route('api.projections.salesplan', { projection }))
        .then((success) => {
          context.commit(types.SET_SALES_PLAN, success.data);
        });
    },
  },
  getters: {
    salesPlan(state) {
      return state.salesPlan;
    },
  },
};
