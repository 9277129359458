<template>
    <div class="bg-grey-lighter h-screen text-grey-darker h-full">
        <div v-if="invite && !invite.redeemed">
            <div class="py-4 pt-16 flex justify-center">
                <img class="w-64" src="/logo.png" alt="">
            </div>
            <div class="container mx-auto py-8 px-6 md:pt-16 flex flex-col items-center">
                <h1 class="font-semibold text-4xl text-center text-grey-darker">You've been invited</h1>
                <p class="font-semibold text-xl text-center text-grey-dark mt-4 md:mt-0 md:w-1/2 px-6 mx-auto">You have been invited to set up a company account with Contingens</p>
                <p class="text-center md:w-1/3 mt-8 text-grey-dark">Give your clients the financial projections they
                    deserve. Get started below.</p>
                <div class="w-full sm:w-1/2 md:w-1/3 rounded-lg mt-2">
                    <div class="p-4">
                        <div class="mb-4" v-if="nameRequired">
                            <error name="name">
                                <ui-label>Full name*</ui-label>
                                <input class="bg-white shadow appearance-none border border-transparent focus:border rounded w-full py-2 px-3 text-grey-darker focus:border-blue-400"
                                       type="text" placeholder="Full name" v-model="name">
                            </error>
                        </div>


                        <div v-if="nameRequired">
                            <ui-label>Choose a password*</ui-label>
                            <div class="mb-4">
                                <error name="password">
                                    <div class="relative">
                                        <input class="bg-white shadow appearance-none border border-transparent focus:border rounded w-full py-2 px-3 text-grey-darker focus:border-blue-400"
                                               :type="passwordHidden ? 'password' : 'text'" placeholder="Password" v-model="password">
                                        <div class="cursor-pointer absolute right-0 pin-y flex flex-shrink items-center px-2 hover:text-blue-200">
                                            <!--<svg class="fill-current h-4 w-4" v-if="passwordHidden">﻿<path fill="#000000" fill-opacity="1" stroke-width="0.2"-->
                                            <!--stroke-linejoin="round"-->
                                            <!--d="M 38,33.1538C 40.6765,33.1538 42.8462,35.3235 42.8462,38C 42.8462,40.6765 40.6765,42.8461 38,42.8461C 35.3235,42.8461 33.1539,40.6765 33.1539,38C 33.1539,35.3235 35.3236,33.1538 38,33.1538 Z M 38,25.0769C 49.3077,25.0769 59,33.1538 59,38C 59,42.8461 49.3077,50.9231 38,50.9231C 26.6923,50.9231 17,42.8461 17,38C 17,33.1538 26.6923,25.0769 38,25.0769 Z M 38,29.1154C 33.0932,29.1154 29.1154,33.0932 29.1154,38C 29.1154,42.9068 33.0932,46.8846 38,46.8846C 42.9068,46.8846 46.8846,42.9068 46.8846,38C 46.8846,33.0932 42.9068,29.1154 38,29.1154 Z "></path></svg>-->
                                            <icon icon="eye" v-if="passwordHidden" @click="passwordHidden = false"></icon>
                                            <icon icon="eye-slash" v-else @click="passwordHidden = true"></icon>
                                        </div>
                                    </div>
                                </error>
                            </div>
                        </div>


                        <div class="mb-4">
                            <error name="organisation_name">
                                <ui-label>Company Name*</ui-label>
                                <input class="bg-white shadow appearance-none border border-transparent focus:border rounded w-full py-2 px-3 text-grey-darker focus:border-blue-400"
                                       type="text" placeholder="Acme Ltd" v-model="organisation_name">
                            </error>
                        </div>

                        <payment-form class="mt-2 mb-2" @submit="submitWithCard" v-if="cardRequired"></payment-form>
                        <div class="flex justify-center" v-else>
                            <button class="btn btn-blue-200">Get started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="invite && invite.redeemed">
            <div class="py-4 pt-16 flex justify-center">
                <img class="w-64" src="/logo.png" alt="">
            </div>
            <div class="container mx-auto py-8 px-6 md:pt-16 flex flex-col items-center">
                <h1 class="font-semibold text-4xl text-center text-grey-darker md:w-1/2">Oops, looks like you've already used that invite</h1>
                <p class="font-semibold text-xl text-center text-grey-dark mt-4 md:mt-0 md:w-1/2 px-6 mx-auto">Contact support if you need help.</p>
            </div>
        </div>
    </div>
</template>

<script>
  import PaymentForm from '@/components/billing/PaymentForm.vue';
  import {mapActions} from 'vuex';
  import invite from './invite.mixin';
  import UiLabel from '@/components/ui/Label.vue';

  export default {
    mixins: [invite],
    data() {
      return {
        passwordHidden: true,
        organisation_name: null,
        name: null,
        password: null,
      }
    },
    components: {
      PaymentForm,
      UiLabel,
    },
    methods: {
      ...mapActions({
        setErrors: 'setErrors',
        setUserToken: 'auth/setToken'
      }),
      submitWithCard({token}) {
        this.submit(token);
      },
      submit(token = null) {
        axios.post(route('api.advisors.store'), {
          'invite_id': this.invite.id,
          'token': this.token,
          'name': this.name,
          'password': this.password,
          'organisation_name': this.organisation_name,
          'card_token': token ? token.id : null,
        }).then(success => {
          this.setUserToken(success.data.data);
          this.$router.push({
            name: 'clients.index',
          })
        })
          .catch(error => {
            if(error.response.status === 422) {
              this.setErrors(error.response.data.errors)
              return;
            }

            throw error
          })
      }
    },
    computed: {
      nameRequired() {
        return !this.invite.existing_user;
      },
      cardRequired() {
        return this.invite.card_required;
      }
    },
  }
</script>

<style>

</style>