<template>
  <div v-if="projection" class="h-full flex flex-col">
    <div class="container mx-auto flex items-center">
      <div class="flex-1"></div>
      <builder-steps :steps="steps"></builder-steps>
      <div class="flex-1">
        <CacheStatusIndicator class="float-right mt-2" :projection="projection"/>
      </div>
    </div>
    <div class="bg-gray-100 border-gray-300 flex-1 flex">
      <div class="flex-shrink-0 flex-1">
        <router-view :next-step="nextStep"
                     :previous-step="previousStep"
                     :step="currentStep"
                     class="pt-4 mx-auto"
        ></router-view>
        <teleport to="body">
          <router-view name="slide" :open="configuring" v-on:closed="stopConfiguring" :settings="projection"></router-view>
        </teleport>
      </div>
    </div>
  </div>
</template>
<script>
import steps from '@/pages/projections/steps';
import BuilderSteps from './BuilderSteps.vue';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import {mapActions, mapGetters} from 'vuex';
import store from '@/store';
import CacheStatusIndicator from './CacheStatusIndicator.vue';

export default {
  data() {
    return {
      steps,
    };
  },

  props: {
    configuring: {
      type: Boolean,
    }
  },

  components: {
    CacheStatusIndicator,
    BuilderSteps,
  },

  computed: {
    currentStepIndex() {
      // Current step is either the parent or if viewing a child, it's parent. Since steps might not have a base component
      // we also allow for the first child to be the 'named' route.
      return _findIndex(this.steps, function(step) {
        if (this.$route.name === step.route.name) {
          return true;
        }

        let childRouteNames = _map(step.route.children, child => {
          return child.name;
        }, []);

        return _includes(childRouteNames, this.$route.name);
      }.bind(this));
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    previousStep() {
      return this.steps[this.currentStepIndex - 1];
    },
    nextStep() {
      return this.steps[this.currentStepIndex + 1];
    },
    ...mapGetters({
      projection: 'projections/current',
      projections: 'projections/all',
    }),
  },

  watch: {
    currentStep(step) {
      let index = _findIndex(this.steps, step);
      this.steps[index].active = true;

      for (var i = 0; i < index; i++) {
        this.steps[i].active = false;
      }

      for (var i = index + 1; i < this.steps.length; i++) {
        this.steps[i].active = false;
      }
    },
  },

  methods: {
    ...mapActions({
      load: 'projections/fetchOne',
      setProjectionStatus: 'projections/setCurrentStatus',
    }),
    stopConfiguring() {
      let withoutConfiguring = Object.assign({}, this.$route.query);
      delete withoutConfiguring['configuring']

      this.$router.replace({...this.$route, query: {...withoutConfiguring}})
    }
  },

  beforeRouteUpdate(to, from, next) {
    const isDemo = this.projection && this.projection.name.toLowerCase().includes('demo');

    if (isDemo && !to.query.demo) {
      this.$router.replace({...to, query: {...to.query, demo: 'true'}});
    } else {
      next();
    }
  },

  beforeRouteEnter(to, from, next) {
    // get the projection from the api and set it in the store
    let projectionId = to.params.id;

    store.dispatch('projections/fetchOne', projectionId).then((projection) => {
      const isDemo = projection && projection.name.toLowerCase().includes('demo');

      next(vm => {
        if (isDemo) {
          vm.$router.replace({...to, query: {...to.query, demo: 'true'}});
        }
      });
    });
  },
};

</script>
<style>

</style>