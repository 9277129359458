<template>
    <div @mouseleave="showMenu = false" class="relative print:hidden" v-if="user">
        <div ref="parent">
            <div @click="showMenu = !showMenu" class="cursor-pointer flex justify-between px-4 py-2 rounded"
                 v-if="user.organisation.name">
                <slot :active="showMenu"></slot>
            </div>
        </div>
        <div class="sm:pt-2 z-50 absolute w-screen md:w-64 text-sm" v-show="showMenu">
            <div class="bg-grey-lightest rounded-sm shadow bg-white">
                <a @click.prevent="clickLogout" class="w-full no-underline cursor-pointer"
                   href="#">
                    <div class="px-4 py-4 hover:bg-gray-300 hover:text-gray-900 w-full flex items-center">
                        <div class="w-5">
                            <svg class="icon-door-exit" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        class="fill-current"
                                        d="M11 4h3a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V6h-2v12h2v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1h-3v1a1 1 0 0 1-1.27.96l-6.98-2A1 1 0 0 1 2 19V5a1 1 0 0 1 .75-.97l6.98-2A1 1 0 0 1 11 3v1z"/>
                                <path
                                        class="fill-current"
                                        d="M18.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4l1.3-1.3H14a1 1 0 0 1 0-2h4.59z"/>
                            </svg>
                        </div>
                        <div class="ml-2">
                            Log out
                        </div>
                    </div>
                </a>
                <div class="px-4 pt-4 pb-1 text-xs uppercase text-gray-600">
                    <p>Accounts</p>
                </div>
                <ul class="list-reset">
                    <router-link :key="organisation.id"
                                 :to="{name: 'tenant.home', params: {tenant: organisation.id}}"
                                 class="no-underline text-grey"
                                 v-for="organisation in user.organisations.data"
                    >
                        <li class="hover:bg-blue-700 px-4 py-4 hover:text-white cursor-pointer flex items-center justify-between">
                            {{ organisation.name }}
                        </li>
                    </router-link>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
  import Dropdown from '@/components/Dropdown.vue';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    data() {
      return {
        showMenu: false,
      };
    },

    props: {},

    computed: {
      ...mapGetters({
        user: 'auth/user',
        organisation: 'organisations/current',
      }),
    },

    methods: {
      ...mapActions({
        logout: 'auth/logout',
      }),
      clickLogout() {
        this.logout().then(() => {
          this.$router.push({name: 'auth.login'});
        });
      },
    },

    components: {
      Dropdown,
    },

    mounted() {

    },
  };
</script>

<style>

</style>