import _each from 'lodash/each';

export default {
  methods: {
    merge(balances) {
      const totals = [];

      _each(balances, (periods) => {
        _each(periods, (period, index) => {
          if (!totals[index]) {
            totals[index] = 0;
          }

          totals[index] = totals[index] + period;
        });
      });

      return totals;
    },
  },
};
