import policies from './policies';

export default class Gate {
  constructor(user) {
    this.user = user;
    this.policies = policies;
  }

  before() {
  }

  allow(action, type, model = null) {
    if (this.before()) {
      return true;
    }

    if (!this.policies[type]) {
      return false;
    }

    return this.policies[type][action](this.user, model);
  }

  deny(action, type, model = null) {
    return !this.allow(action, type, model);
  }
}
