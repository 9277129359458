<template>
    <div class="container mx-auto h-full flex justify-center items-center">
        <h1>403 Forbidden.</h1>
    </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
  };
</script>

<style>

</style>