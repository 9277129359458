import routes from '@/routes/index';
import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.afterEach(({ name, params, path, meta }) => {
  if(meta && document) {
    document.title = meta.title ? `Contingens | ${meta.title}` : 'Contingens';
  }

  store.dispatch('setRoute', { name, params, path });
});

export default router;
