<template>
    <progress-bar class="print:hidden">
      <ul class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 w-full md:w-auto">
        <template v-for="(step, key) in steps">
          <step
              :active-icon="step.activeIcon"
              :complete="completeSteps.includes(step)"
              :complete-icon="step.completeIcon"
              :inactive-icon="step.inactiveIcon"
              :label="step.label"
              :route="step.route"
              :previous="previous(key)"
              :next="next(key)"
              :position="key + 1"
          ></step>
        </template>
      </ul>
    </progress-bar>
</template>
<script>

import ProgressBar from '@/components/progress-bar/Bar.vue';
import Step from '@/components/progress-bar/Step.vue';
import _first from 'lodash/first';

export default {
    data() {
      return {};
    },

    props: {
      steps: {
        type: Array,
      },
    },

    computed: {
      keys() {
        return Object.keys(this.steps);
      },
      currentStep() {
        const matchedRouteNames = this.$route.matched.map(route => route.name);
        return _first(this.steps.filter(step => matchedRouteNames.includes(step.route.name)));
      },
      currentStepKey() {
        return this.steps.indexOf(this.currentStep);
      },
      completeSteps() {
        return this.steps.filter((step, key) => this.keys.indexOf(key.toString()) < this.currentStepKey);
      }
    },

    components: {
      ProgressBar,
      Step,
    },

    methods: {
      isLast(step) {
        return this.steps.indexOf(step) === this.steps.length -1;
      },
      previous(fromKey) {
        if(this.keys.indexOf(fromKey.toString()) === 0) {
          return null;
        }

        return this.steps[this.keys.indexOf(fromKey.toString()) - 1] || null;
      },
      next(fromKey) {
        if(this.keys.indexOf(fromKey.toString()) === -1) {
          return null;
        }

        return this.steps[this.keys.indexOf(fromKey.toString()) + 1] || null;
      },
    },

    mounted() {
      console.log('current step', this.currentStep);

    },
  };

</script>
<style>
</style>