<template>
	<div>
		<popup-form :popup="popup">
			<button
					class="max-w-sm cursor-pointer ml-4 btn btn-sm btn-blue-200 border-b-2 border-blue-700 shadow hover:text-blue-700">
				<icon icon="plus"></icon>
			</button>
			<template v-slot:form>
				<div class="p-4">
					<div class="mb-4">
						<text-input name="name" label="Name" v-model="name"></text-input>
					</div>
					<div class="mb-4">
						<text-input name="email" label="Email" v-model="email"></text-input>
					</div>
					<div class="mt-8 flex justify-end">
						<button class="btn btn-blue-200 no-underline" @click.prevent="store">
							Save
						</button>
					</div>
				</div>
			</template>
		</popup-form>
	</div>
</template>
<script>
  import PopupForm from '@/components/forms/PopupForm.vue'
  import {mapActions} from 'vuex';

  export default {
    data() {
      return {
        popup: false,
		name: null,
		email: null,
      };
    },

	computed: {
      payload() {
        return {
          name: this.name,
		  email: this.email
		}
	  }
	},

    methods: {
      ...mapActions({
        'setErrors': 'setErrors',
      }),
      store() {
        axios.post(route('settings.users.store'), this.payload).then(
          success => {
            this.popup = false
            window.location.reload();
          },
          error => {
            if(!error.response.data && !error.response.data.errors) {
              alert("Something went wrong. Please refresh the page and try again.")
			  return
			}

            this.setErrors(error.response.data.errors);
          }
        )
      }
    },

    components: {
      PopupForm,
    },
  };
</script>
<style>

</style>