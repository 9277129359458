<template>
    <div class="w-full h-full bg-gray-lightest">
        <div class="container px-4 lg:px-0 lg:w-1/2 mx-auto py-8">
            <h2 class="text-gray uppercase text-lg">New Client</h2>
            <div class="bg-white shadow px-4 md:px-4 py-4 mt-2 rounded">
                <div class="mb-4">
                    <error name="organisation">
                        <label class="block text-gray-darker text-sm mb-2">Organisation Name*</label>
                        <input class="bg-white border-gray-light appearance-none border border-transparent focus:border rounded w-full py-2 px-2 text-gray-darker focus:border-blue-400"
                               type="text" placeholder="Client Company Ltd" v-model="organisationName" autofocus>
                    </error>
                </div>
                <div class="mb-4">
                    <error name="email">
                        <label class="block text-gray-darker text-sm mb-2">Client Email* <span class="text-sm text-gray-dark">(This will be the owner of the account)</span></label>
                        <input class="bg-white border-gray-light appearance-none border border-transparent focus:border rounded w-full py-2 px-2 text-gray-darker focus:border-blue-400"
                               type="text" placeholder="Client email address" v-model="email" autofocus>
                    </error>
                </div>
                <div>
                    <primary-action :disabled="loading" @click="submit">Save</primary-action>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import PrimaryAction from '@/components/buttons/PrimaryAction.vue';

  export default {
    data() {
      return {
        organisationName: null,
        email: null,
        loading: false,
      };
    },
    components: {
      PrimaryAction,
    },
    methods: {
      submit() {
        this.loading = true;
        axios.post(route('api.clients.store'), {
          organisation_name: this.organisationName,
          email: this.email,
        }).then(success => {
          this.$router.push({name: 'clients.index'});
        }).catch(error => {
          alert('Something went wrong. Please try again.');
        }).finally(() => {
          this.loading = false;
        });
      },
    },
  };
</script>

<style>

</style>