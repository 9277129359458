<template>
	<div class="flex hover:bg-gray-lighter rounded-lg flex p-1">
		<permission-circles></permission-circles>
		<div class="ml-4 cursor-pointer text-gray flex content-center items-center justify-center hover:text-blue-200 right-0">
			<icon icon="edit" @click="showMenu = true"></icon>
		</div>
		<modal v-model="showMenu">
			<div class="mx-auto my-auto p-8 bg-white w-full sm:w-3/4 md:w-2/3 lg:w-1/4 rounded-sm shadow-lg relative" @click.stop>
				<h2 class="text-gray-dark mb-8">Assign / Revoke Permissions</h2>
				<text-input placeholder="Start typing to search for circles that can be assigned"
							@focus="showResults = true"
							@blur="showResults = false">
					<div class="shadow-md absolute bg-white w-full" v-show="showResults">
						<ul class="list-reset font-semibold text-gray-darker">
							<li class="hover:bg-gray-lighter px-4 py-6 cursor-pointer" @click="assignToCircle('owner')">Owner</li>
							<li class="hover:bg-gray-lighter px-4 py-6 cursor-pointer" @click="assignToCircle('read_write')">Read / Write</li>
							<li class="hover:bg-gray-lighter px-4 py-6 cursor-pointer" @click="assignToCircle('read')">Read Only</li>
						</ul>
					</div>
				</text-input>
				<div class="mt-8">
					<label class="block text-gray-darker text-sm font-bold mt-2">Member of:</label>
					<permission-circles></permission-circles>
				</div>
				<div class="flex justify-end">
					<button class="btn btn-blue-200">Save</button>
				</div>
			</div>
		</modal>
	</div>
</template>
<script>
  import Dropdown from '@/components/Dropdown.vue';
  import Modal from '@/components/Modal.vue';
  import PermissionCircles from '@/components/PermissionCircles.vue';

  export default {
    data() {
      return {
        editing: false,
        showMenu: false,
		showResults: false,
      };
    },

    props: {},

    computed: {},

    components: {
      Dropdown,
      Modal,
	  PermissionCircles,
    },

	methods: {
      assignToCircle(circle) {

	  }
	},

    mounted() {

    },
  };

</script>
<style>

</style>