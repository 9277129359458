<template>
  <div
       class="px-2 bg-white z-10 cursor-pointer text-grey hover:text-blue-200" >
    <svg class="w-5 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path class="secondary text-gray-500 group-hover:text-gray-900"
            d="M12 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill-rule="evenodd"/>
    </svg>
  </div>
</template>

<script setup>

</script>