<template>
    <button v-bind="{...$attrs, ...$props}" :class="css"><slot></slot></button>
</template>

<script>
  export default {
    compatConfig: {
      INSTANCE_LISTENERS: false,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {};
    },
    computed: {
      css() {
        return {
          'btn' : true,
          'opacity-50 cursor-not-allowed': this.disabled,
        }
      }
    }
  };
</script>

<style>

</style>