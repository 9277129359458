<template>
  <router-link :to="route" class="relative md:flex-1 md:flex group focus-within:ring ring-blue-500 focus-within:text-blue-600 rounded-sm flex items-center w-full flex-1">
      <div @click="trackStepClick(route)" :class="['px-3 py-1 flex items-center text-sm leading-5 font-medium space-x-2', next ? 'pr-6' : null]">
        <div
            :class="[
                'flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full transition ease-in-out duration-150',
                complete ? 'bg-blue-600 group-hover:bg-blue-800' : null,
                active ? 'border-2 border-blue-600' : null,
                !(complete || active) ? 'text-gray-500 group-hover:text-gray-900 border-2 border-gray-300 group-hover:border-gray-400' : null,
            ]">
          <!-- Heroicon name: check -->
          <svg class="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" v-if="complete">
            <path clip-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  fill-rule="evenodd"/>
          </svg>
          <p class="text-blue-600" v-else-if="active">{{position}}</p>
          <span class="text-gray-500 group-hover:text-gray-900 transition ease-in-out duration-150" v-else>{{position}}</span>
        </div>
        <p class="text-sm leading-5 font-medium text-gray-900 whitespace-nowrap">{{ label }}</p>
      </div>

    <div class="hidden md:block absolute top-0 right-0 h-full w-5 group-focus:invisible" v-if="next">
      <svg class="h-full w-full text-gray-300" fill="none" preserveAspectRatio="none" viewBox="0 0 22 80">
        <path d="M0 -2L20 40L0 82" stroke="currentcolor" stroke-linejoin="round" vector-effect="non-scaling-stroke"/>
      </svg>
    </div>
  </router-link>
</template>
<script>
export default {
  data() {
    return {};
  },

  props: {
    label: {
      type: String,
    },
    activeIcon: {
      type: String,
      default: 'hourglass-half',
    },
    // active: {
    //   type: Boolean,
    // },
    completeIcon: {
      type: String,
      default: 'check',
    },
    complete: {
      type: Boolean,
    },
    inactiveIcon: {
      type: String,
      default: 'hourglass-start',
    },
    route: {
      type: Object,
      default: null,
    },

    previous: {
      default: null,
    },
    next: {
      defalt: null,
    },
    position: {
      default: null,
    }
  },

  computed: {
    icon() {
      return this.complete ? this.completeIcon : (this.active ? this.activeIcon : this.inactiveIcon);
    },
    active() {
      return this.$route.matched.map(route => route.name).includes(this.route.name);
    },
  },

  components: {
  },

  methods: {
    trackStepClick(route) {
      this.$track('Projection: Click Progress Bar Step', {route: route.name});
    },
  },

  mounted() {

  },
};

</script>
<style>

</style>