<template>
	<div style="overflow-x: auto;" class="text-sm" v-show="loaded">
        <cash-flow-year-table :cash-flow="keyedBalances" :year="year" :years="years" v-if="year"></cash-flow-year-table>
        <statement-annual-summary :years="years" :balances="keyedBalances.balances" :labels="labels" v-else/>
    </div>
</template>
<script>
import CashFlowYearTable from './CashFlowYearTable.vue';
import {mapGetters} from 'vuex';
import report from './nestedBalances.mixin';
import StatementAnnualSummary from './StatementAnnualSummary.vue';
import labels from '@/components/reports/labels';
import _find from 'lodash/find';
import {useCache} from '../projections/cache/cache.js';
import {onBeforeUnmount} from 'vue';

export default {
    mixins: [report],

    data() {
      return {
        keyedBalances: {},
      };
    },

    props: {},

    components: {
      CashFlowYearTable,
      StatementAnnualSummary,
    },

    computed: {
      ...mapGetters({
        projection: 'projections/current',
		    currentYear: 'projections/year',
      }),
      loaded() {
        return Object.keys(this.keyedBalances).length > 0;
      },
      labels() {
        return labels.cashFlow;
      },
      currentReportingPeriod() {
        return _find(this.projection.reporting_periods.data, period => period.label === this.year.label);
      },
      year() {
        return this.projection.reporting_periods.data.find(period => period.label === this.currentYear)
      },
      years() {
        return this.projection.reporting_periods.data;
      },
    },

    methods: {
      refresh() {
        return axios.get(route('api.projections.cashflow', {projection: this.projection.id})).then(
          success => {
            this.keyedBalances = success.data;
          },
          error => {
            console.error(error)
          }
        );
      },
    },

    created() {
      this.refresh();

      const {subscribe, stop} = useCache(this.projection);
      const unsubscribe = subscribe((resolve, reject) => {
        this.refresh().then(resolve).catch(reject);
      });

      onBeforeUnmount(unsubscribe);
    },
  };

</script>
<style scoped>
	tr:not(.no-bg):hover {
		background-color: rgba(249, 249, 249, 1);
	}

	td.underline {
		text-underline-position: under;
	}
</style>