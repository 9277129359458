<template>
    <ui-button v-bind="{...$attrs, ...$props}" :class="{'bg-transparent hover:underline': true}"><slot/></ui-button>
</template>

<script>
  import UiButton from '@/components/ui/Button.vue';

  export default {
    components: {
      UiButton,
    }
  };
</script>

<style>

</style>