<template>
  <input
      v-bind="$attrs"
      ref="input"
      :modelValue="modelValue"
      class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
      type="radio"
      :checked="checked"
      @input="fireChecked"
  >
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'changed',
  },
  compatConfig: { COMPONENT_V_MODEL: false },
  props: {
    checked: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      required: false,
    },
  },
  setup(props, {emit}) {
    const fireChecked = () => {
      emit('update:modelValue', props.modelValue);
    };

    return {
      // checked: props.checked,
      fireChecked,
    };
  },
};
</script>

<style>

</style>