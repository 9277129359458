import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { mapGetters } from 'vuex';


export default {
  computed: {
    ...mapGetters({
      projection: 'projections/current',
    }),
  },
  methods: {
    lineItemsWith(filter) {
      if (!this.projection) {
        return [];
      }

      if (this.projection && !this.projection.lineItems) {
        return [];
      }

      return _filter(this.projection.lineItems.data, filter);
    },
    lineItemsWithAnyTags(tags) {
      if (!this.projection) {
        return [];
      }

      if (this.projection && !this.projection.lineItems) {
        return [];
      }

      return _filter(this.projection.lineItems.data, item => _includes(tags, item.tag));
    },
  },
};
