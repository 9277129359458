<template>
    <div class="container mx-auto">
        <div>
            <div class="my-4 flex items-center content-center flex-wrap">
                <div class="flex content-center flex-grow items-center">
                    <h1 class="text-grey-darker text-2xl">{{ header }}</h1>
                    <div class="flex items-center">
                        <button @click="addButtonClick"
                                class="cursor-pointer ml-4 btn btn-sm rounded-sm bg-blue-700 text-white shadow hover:bg-blue-700"
                                ref="addButton">
                            <!--                            <icon icon="plus"></icon>-->
                            <svg class="w-6 h-auto" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path class="fill-current text-blue-100" d="M17 11a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4h4z"
                                      fill-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="flex justify-end">
                    <div>
                        <commentary-input class="w-full sm:w-auto" :on-commit="saveCommentary" v-model="commentary"></commentary-input>
                    </div>
                </div>
            </div>
                <teleport to="body">
                  <modal :middle="false" v-model="showAddMenu">
                      <div class="mx-2 mt-16 z-50" v-if="showAddMenu" @click.stop>
                        <line-item-type-selector class="w-100" @update:modelValue="addLineItem" :types="allowedCategories"/>
                      </div>
                  </modal>
                </teleport>
        </div>
        <div class="py-8 px-8 bg-white shadow" v-if="lineItems.length === 0">
            <slot name="empty">
                <h3 class="text-grey-dark">You haven't added anything on this page yet. Click the plus sign to get
                    started.</h3>
            </slot>
        </div>
        <!-- Income Items -->
        <template v-if="!squash">
          <div class="my-4" v-for="(category, id) in categories">
            <line-item-form-section
                :context-id="contextId"
                :discrete-data-entry="discreteDataEntry"
                :items="category"
                :label="allowed[id].label"
            ></line-item-form-section>
            <!--            :totals="totals[id]"-->
          </div>
        </template>
        <div class="my-4" v-if="squash && lineItems.length > 0">
            <line-item-form-section
                    :context-id="contextId"
                    :discrete-data-entry="discreteDataEntry"
                    :items="lineItems"
            ></line-item-form-section>
            <!--                                :totals="totalsSquashed"
            -->
        </div>
        <!-- Modal Configuration Form for Creating new Line Items -->
      <teleport to="body">
        <modal v-cloak v-model="configuring">
            <div @click.stop
                 @submit.stop
                 class="mx-auto my-auto p-8 bg-white w-full sm:w-3/4 md:w-2/3 lg:w-128 rounded-sm shadow-lg"
                 v-if="configuring">
                <line-item-configuration-form
                        :active="configuring"
                        :initial-line-item="item"
                        :on-cancel="stopConfiguring"
                        :on-save-success="stopConfiguring"
                ></line-item-configuration-form>
            </div>
        </modal>
      </teleport>
    </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue';
import LineItemFormSection from '@/components/lineitems/LineItemFormSection.vue';
import CommentaryInput from '@/components/forms/CommentaryInput.vue';
import Modal from '@/components/Modal.vue';
import LineItemTypeSelector from '@/components/lineitems/LineItemTypeSelector.vue';

import {mapActions, mapGetters} from 'vuex';
import _groupBy from 'lodash/groupBy';
import _each from 'lodash/each';
import _reduce from 'lodash/reduce';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _chunk from 'lodash/chunk';

import configFormMixin from '@/components/lineitems/forms/usesLineItemConfigForm.mixin';

import Lexisort from '@/app/lexisort';

const lexi = Lexisort();

export default {
    mixins: [configFormMixin],

    data() {
      return {
        item: {},
        showAddMenu: false,
        commentary: this.initialCommentary,
      };
    },

    props: {
      discreteDataEntry: {
        type: Boolean,
        default: false,
      },
      squash: {
        type: Boolean,
        default: false,
      },
      contextId: {
        type: String,
        default: 'reusable',
      },
      label: {
        type: String,
      },
      header: {
        type: String,
      },
      allowedCategories: {
        type: Object,
        default() {
          return {};
        },
      },
      initialData: {
        type: Object,
        default() {
          return {};
        },
      },
      lineItems: {
        type: Array,
        required: true,
      },
      allowedCategory: {
        type: Object,
        required: false,
      },
    },

    methods: {
      addLineItem(category) {
        this.showAddMenu = false; // close the menu

        if (category.disabled) {
          return; // do nothing
        }
        this.item = {projection_id: this.projection.id, extensions: {data: []}, life: null, ...category.template};
        this.item.sort_key = lexi.append(this.lineItems, this.item);
        this.configure();
      },
      addLineItemOfType(type) {
        return this.addLineItem(this.allowedCategories[type])
      },
      addButtonClick() {
        if (this.allowedCategory) {
          this.addLineItem(this.allowedCategory);
          return;
        }

        this.showAddMenu = !this.showAddMenu;
      },
      saveCommentary() {
        return this.upsertComment({
          section: this.label,
          body: this.commentary,
          type: 'section',
        });
      },
      ...mapActions({
        upsertComment: 'projections/comments/upsert',
      }),
    },

    computed: {
      initialCommentary() {
        if (this.comments[this.label]) {
          return this.comments[this.label].body;
        }

        return '';
      },
      // Returns a key => value map of allowed categories (or category!)
      allowed() {
        let singleCategory = {};
        if (this.allowedCategory) {
          singleCategory[this.allowedCategory.id] = this.allowedCategory;
        }

        return _merge(this.allowedCategories, singleCategory);
      },
      // lineItems grouped by tag
      categories() {
        return _groupBy(this.lineItems, (item) => {
          return item.tag;
        });
      },
      categoryColumns() {
        let numberOfItemsPerColumn = 5;
        let names = _map(this.allowedCategories, (category, name) => {
          return name;
        });

        return _chunk(names, numberOfItemsPerColumn);
      },
      // Get the totals grouped by tag / category (so that each section is shown separately)
      totals() {
        let categories = {};

        _each(this.categories, (items, category) => {
          let lineItemTotals = {};
          _each(items, item => {
            lineItemTotals[item.id] = this.lineItemPeriodTotals[item.id];
          });

          categories[category] = _reduce(lineItemTotals, (carryTotals, totals) => {
            // Get the combined totals for each particular line item (we are doing one at a time)
            let combinedTotals = [];
            _each(totals, (total, index) => {
              let existingTotal = 0;

              if (combinedTotals[index]) {
                existingTotal = combinedTotals[index];
              }

              combinedTotals[index] = existingTotal + total;
            });

            // Merge this with the 'carried' totals
            _each(combinedTotals, (combinedTotal, index) => {
              carryTotals[index] = (carryTotals[index] || 0) + combinedTotal;
            });

            return carryTotals;
          }, []);
        });

        return categories;
      },
      totalsSquashed() {
        let totals = [];

        _each(this.totals, categoryTotals => {
          _each(categoryTotals, (periodTotalForCategory, index) => {
            if (!totals[index]) {
              totals[index] = 0;
            }

            totals[index] = totals[index] + periodTotalForCategory;
          });
        });

        return totals;
      },
      ...mapGetters({
        projection: 'projections/current',
        lineItemPeriodTotals: 'lineItemPeriodTotals',
        comments: 'projections/comments/sections',
      }),
    },

    components: {
      LineItemFormSection,
      Dropdown,
      CommentaryInput,
      Modal,
      LineItemTypeSelector,
    },

    watch: {
      categories: {
        handler() {
          this.$emit('update:modelValue', this.categories);
        },
        deep: true,
      },
      initialCommentary() {
        this.commentary = this.initialCommentary;
      },
    },

    mounted() {
      this.commentary = this.initialCommentary;
    },
  };

</script>
<style>
    .writing-vertical-lr {
        writing-mode: vertical-lr;
    }
</style>