import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'
import {watch} from 'vue';

const writeKey = import.meta.env.VITE_SEGMENT_WRITE_KEY;
const plugins = [];

if(writeKey) {
  plugins.push(segmentPlugin({
    writeKey,
  }))
}

const analytics = Analytics({
  app: 'forecast',
  plugins,
  debug: import.meta.env.DEV,
})

export default analytics;

export const trackPageViews = (router) => {
  router.afterEach(() => {
    analytics.page();
  });
}

export const identifyUser = (store) => {
  watch(() => store.getters['auth/user']?.id, (userId) => {
    const user = store.getters['auth/user'];

    if(user) {
      analytics.identify(user.email, {
        name: user.name,
        email: user.email,
      })
    }
  }, {immediate: true})
}